import { Toggle } from '@components/toggle';

interface Props {
  title: string;
  description: string;
  checked: boolean;
  onToggle: () => void;
}

export function Preference({ title, description, checked, onToggle }: Props) {
  return (
    <div class="flex">
      <Toggle class="mt-1" checked={checked} onClick={onToggle} />
      <span class="flex flex-col ml-4">
        <span class="text-zinc-900 dark:text-gray-200">{title}</span>
        <span class="text-zinc-500 dark:text-gray-400 mt-1">{description}</span>
      </span>
    </div>
  );
}
