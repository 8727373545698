import { Dispatch, StateUpdater, useMemo, useState } from 'preact/hooks';
import { IcoFilter, IcoGrid, IcoList, IcoX } from '@components/icons';
import { AutoPager } from '../../components/auto-pager';
import { Button } from '@components/buttons';
import { Course, Filters, Show } from './types';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { Dropdown } from '@components/dropdown';
import { SearchBox } from '@components/search-box';
import { EmptyScreen } from './empty-screen';
import { intl, useIntl } from 'shared/intl/use-intl';
import { useLocalStorageState } from 'client/lib/hooks';
import { filterCourses } from './utils';

export type ListType = 'grid' | 'list';

interface Props {
  courses: Course[];
  renderItem: (props: {
    course: Course;
    listType: ListType;
    searchTerm: string;
    displayDate: 'createdAt' | 'lastOpenedAt';
  }) => JSX.Element;
  show: Show;
}

const PAGE_SIZE = 20;

function RuzukuPromoLink() {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const user = useCurrentUser();

  if (user?.level !== 'student' || !tenant.isCore) {
    return null;
  }

  return (
    <div>
      <a
        class="flex flex-col p-6 rounded-lg border hover:border-indigo-600"
        href="https://www.ruzuku.com/?utm_source=student&utm_content=mycourses&utm_medium=link"
      >
        <span class="text-gray-600 mb-2">{intl('Curious about creating your own course?')}</span>
        <span>{intl('Learn more about how Ruzuku can help ⤑')}</span>
      </a>
    </div>
  );
}

export function CourseList(props: Props) {
  const intl = useIntl();
  const { show, courses } = props;
  const [filters, setFilters] = useState(defaultFilters);
  const [searchTerm, setRawSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const setSearchTerm = (term: string) => {
    setRawSearchTerm(term);
    setPageSize(PAGE_SIZE);
  };
  const [listType, setListType] = useLocalStorageState<ListType>('mycourses.listType', 'grid');
  const currentUser = useCurrentUser()!;
  const isStudentOnly = currentUser.level === 'student';

  const filtered = useMemo(() => {
    return filterCourses({
      courses,
      filters,
      show,
    }).sort((a, b) => {
      const v1 = a[filters.sort];
      const v2 = b[filters.sort];

      return v1 === v2 ? 0 : v1 > v2 ? -filters.sortDirection : filters.sortDirection;
    });
  }, [courses, filters, show, searchTerm]);

  const searched =
    searchTerm.length === 0 ? filtered : filtered.filter((c) => c.search.includes(searchTerm));

  const hasFilters = !areDefaultFilters(filters) || searchTerm.length > 0;
  const visibleCourses = searched.slice(0, pageSize);
  const hasMorePages = visibleCourses.length !== searched.length;

  return (
    <>
      <header class="flex flex-col space-y-4 lg:space-y-0 lg:flex-row justify-between mb-8">
        <div class="flex w-full md:w-auto">
          <Dropdown
            triggerClass="bg-gray-50 border border-r-0 border-gray-300 rounded rounded-r-none h-full px-2 whitespace-nowrap hidden md:inline-flex"
            position="left-0 top-full mt-2"
            class="text-gray-500"
            renderMenu={() => (
              <div class="flex space-x-16 p-4" onClick={(e) => e.stopPropagation()}>
                {isStudentOnly && (
                  <FilterOptions
                    field="sort"
                    currentValue={filters.sort}
                    setFilters={setFilters}
                    title={intl('Sort by')}
                    options={[
                      { title: intl('Recently opened by me'), value: 'lastOpenedAt' },
                      { title: intl('Title'), value: 'search', sortDirection: -1 },
                    ]}
                  />
                )}
                {!isStudentOnly && (
                  <>
                    <FilterOptions
                      field="sort"
                      currentValue={filters.sort}
                      setFilters={setFilters}
                      {...filterDefinitions.sort}
                    />
                    <div>
                      <FilterOptions
                        field="status"
                        currentValue={filters.status}
                        setFilters={setFilters}
                        {...filterDefinitions.status}
                      />
                    </div>
                    {show !== 'bundles' && (
                      <FilterOptions
                        field="type"
                        currentValue={filters.type}
                        setFilters={setFilters}
                        {...filterDefinitions.type}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          >
            <IcoFilter class="w-4 h-4 mr-1 opacity-75" />
            {intl('Filter')}
          </Dropdown>
          <SearchBox
            class="ruz-input pl-9 text-sm  md:rounded-l-none"
            onTermChange={(term) => setSearchTerm(term.toLowerCase())}
            placeholder={intl('Find a {model:string}', {
              model: show === 'bundles' ? 'bundle' : 'course',
            })}
            value={searchTerm}
          />
        </div>

        <nav class="hidden lg:inline-flex gap-2">
          <Button
            data-tooltip="Grid View"
            class={`${
              listType === 'grid' ? 'bg-gray-100' : ''
            } rounded inline-flex h-8 w-8 items-center justify-center relative hover:bg-gray-200`}
            onClick={() => setListType('grid')}
          >
            <span class="flex gap-3 items-center">
              <IcoGrid />
            </span>
          </Button>
          <Button
            data-tooltip="List View"
            class={`${
              listType === 'list' ? 'bg-gray-100' : ''
            } rounded inline-flex h-8 w-8 items-center justify-center relative hover:bg-gray-200`}
            onClick={() => setListType('list')}
          >
            <span class="flex gap-3 items-center">
              <IcoList />
            </span>
          </Button>
        </nav>
      </header>
      {hasFilters && (
        <div class="mb-6">
          <ClearFilters
            filters={filters}
            searchTerm={searchTerm}
            onClear={() => {
              setFilters(defaultFilters);
              setSearchTerm('');
            }}
          />
        </div>
      )}
      {!filtered.length && (
        <EmptyScreen
          title={show === 'bundles' ? intl('No Bundles') : intl('No Courses')}
          subtext={
            show === 'bundles'
              ? `${
                  isStudentOnly
                    ? intl('When you register for a course, it will show up here.')
                    : intl("When you create courses, they'll show up here.")
                } `
              : intl('No courses found with the current filters.')
          }
        />
      )}
      <div
        class={
          listType === 'grid'
            ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8'
            : 'flex flex-col gap-4'
        }
      >
        {visibleCourses.map((course) =>
          props.renderItem({
            course,
            searchTerm,
            listType,
            displayDate: filters.sort === 'createdAt' ? 'createdAt' : 'lastOpenedAt',
          }),
        )}
        {!hasFilters && show !== 'bundles' && <RuzukuPromoLink />}
      </div>
      {hasMorePages && <AutoPager onLoadMore={() => setPageSize((p) => p + PAGE_SIZE)} />}
    </>
  );
}

const filterDefinitions = {
  sort: {
    title: 'Sort by',
    options: [
      { title: intl('Recently opened by me'), value: 'lastOpenedAt' },
      { title: intl('Created at'), value: 'createdAt' },
      { title: intl('Title'), value: 'search', sortDirection: -1 },
      { title: intl('Number of students'), value: 'numStudents' },
    ],
  },
  status: {
    title: 'Status',
    options: [
      { title: intl('All'), value: 'all' },
      { title: intl('Draft'), value: 'draft' },
      { title: intl('Signups open'), value: 'published' },
      { title: intl('Closed'), value: 'closed' },
    ],
  },
  type: {
    title: 'Type',
    options: [
      { title: intl('All'), value: 'all' },
      { title: intl('Drip (Individual)'), value: 'ondemand' },
      { title: intl('Full Access'), value: 'openaccess' },
      { title: intl('Drip (Calendar)'), value: 'scheduled' },
    ],
  },
};

const defaultFilters: Filters = {
  sort: 'lastOpenedAt',
  sortDirection: 1,
  status: 'all',
  type: 'all',
};

function FilterOptions<K extends keyof Filters>({
  title,
  field,
  options,
  currentValue,
  setFilters,
}: {
  title: string;
  field: K;
  currentValue: Filters[K];
  options: Array<{ title: string; value: any; sortDirection?: number }>;
  setFilters: Dispatch<StateUpdater<Filters>>;
}) {
  return (
    <div class="flex flex-col space-y-2 text-sm min-w-40">
      <h3 class="uppercase text-xs font-bold tracking-wider text-gray-500 border-b pb-2">
        {title}
      </h3>
      {options.map((o) => (
        <Button
          key={o.value}
          class={`${o.value === currentValue ? 'font-bold' : ''} text-left`}
          onClick={() =>
            setFilters((s) => ({
              ...s,
              [field]: o.value,
              sortDirection: o.sortDirection || s.sortDirection,
            }))
          }
        >
          {o.title}
        </Button>
      ))}
    </div>
  );
}

function areDefaultFilters(filters: Filters) {
  return (
    filters.sort === defaultFilters.sort &&
    filters.status === defaultFilters.status &&
    filters.type === defaultFilters.type
  );
}

function ClearFilters({
  onClear,
  filters,
  searchTerm,
}: {
  filters: Filters;
  searchTerm: string;
  onClear(): void;
}) {
  const intl = useIntl();
  const filterDescription: string[] = [];
  const addFilter = (key: keyof typeof filterDefinitions, defaultValue: any) => {
    if (filters[key] !== defaultValue) {
      filterDescription.push(
        `${filterDefinitions[key].title} ${
          filterDefinitions[key].options.find((d) => d.value === filters[key])?.title
        }`.toLowerCase(),
      );
    }
  };
  if (searchTerm) {
    filterDescription.push('Search term');
  }
  addFilter('sort', 'lastOpenedAt');
  addFilter('status', 'all');
  addFilter('type', 'all');

  return (
    <Button class="inline-flex items-center" onClick={onClear}>
      <span class="border rounded bg-gray-50 mr-2 p-0.5 inline-block">
        <IcoX class="w-3 h-3 opacity-75" />
      </span>
      {intl('Clear current filters')} {filterDescription.join(', ')}
    </Button>
  );
}
