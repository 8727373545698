import { Button } from '@components/buttons';
import { Case } from '@components/conditional';
import { Draggable, DraggableProvider, reorderItems } from '@components/draggable';
import { IcoPlus } from '@components/icons';
import { Toggle } from '@components/toggle';
import { UndoNotice } from '@components/undo-notice';
import { substateUpdater, useUndoRedo } from 'client/lib/hooks';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useMemo, useState } from 'preact/hooks';
import { AssignmentQuestion } from 'server/types';
import { generateUUID } from 'shared/utils';
import { ReadOnlyQuestion } from '../assessment-question';
import { AssignmentItem } from './assignment-item';
import { rpx } from 'client/lib/rpx-client';
import { useBasicAutosaver } from '@components/autosaver';
import { showError } from '@components/app-error';
import { Lesson } from '../types';
import { LessonPane } from '../lesson-pane';
import { SaveStatus } from '@components/save-status';
import { AssignmentSubmissions } from './assignment-submissions';
import { showToast } from '@components/toaster';
import { useCurrentTenant } from 'client/lib/auth';
import { showConfirmModal } from '@components/modal-form';

interface Assignment {
  questions: AssignmentQuestion[];
  settings: {
    notifyAssignmentSubmissions: boolean;
    requireAssignmentApproval: boolean;
  };
}

interface Props {
  lesson: Pick<
    Lesson,
    | 'id'
    | 'title'
    | 'notifyAssignmentSubmissions'
    | 'requireAssignmentApproval'
    | 'hasAssessmentSubmissions'
  >;
  hide: () => void;
}

export function AssignmentEditor(props: Props) {
  const { terminology } = useCurrentTenant();
  const {
    id: lessonId,
    notifyAssignmentSubmissions = true,
    requireAssignmentApproval = true,
    hasAssessmentSubmissions,
  } = props.lesson;
  const [assignment, setAssignment] = useState<Assignment>({
    questions: [],
    settings: {
      notifyAssignmentSubmissions,
      requireAssignmentApproval,
    },
  });
  const [openedQuestion, setOpenedQuestion] = useState<UUID | undefined>(undefined);
  const [showUndoNotice, setShowUndoNotice] = useState(false);
  const [showResults, setShowResults] = useState(false);

  const { questions } = assignment;
  const activeQuestions = questions.filter((q) => !q.deleted);
  const undoRedo = useUndoRedo(assignment, setAssignment);
  const setQuestions = useMemo(
    () =>
      substateUpdater(
        setAssignment,
        (s) => s.questions,
        (s, questions) => ({ ...s, questions }),
      ),
    [setAssignment],
  );

  function updateQuestion(id: UUID, updater: (q: AssignmentQuestion) => AssignmentQuestion) {
    setQuestions((s) => s.map((x) => (x.id === id ? updater(x) : x)));
  }

  function createNewQuestion() {
    if (hasAssessmentSubmissions) {
      return showHasSubmissionsWarning();
    }

    const newQuestion = {
      id: generateUUID(),
      content: '',
      isRequired: true,
    };
    setQuestions((s) => [...s, newQuestion]);
    setOpenedQuestion(newQuestion.id);
  }

  function showHasSubmissionsWarning() {
    showToast({
      type: 'warn',
      title: `Action is not available`,
      message: `This action is not available because this assignment already has student submissions.`,
    });
  }

  useAsyncEffect(async () => {
    const questions = await rpx.assessments.getAssignmentQuestions({ lessonId });
    if (questions.length > 0) {
      setQuestions(questions);
    } else {
      // Create an empty question if there are none
      createNewQuestion();
    }
  }, [lessonId]);

  useBasicAutosaver(assignment.settings, async (val) => {
    try {
      await rpx.assessments.saveAssignmentSettings({
        lessonId,
        notifyAssignmentSubmissions: val.notifyAssignmentSubmissions,
        requireAssignmentApproval: val.requireAssignmentApproval,
      });
    } catch (err) {
      showError(err);
    }
  });

  const autosaver = useBasicAutosaver(questions, async (val) => {
    try {
      /*
       * We don't need to save the assignment if there are no questions.
       * This is also true for the cases where the user deletes all
       * of the assignment questions. Because we're still sending
       * questions with `deleted: true` flag in that case.
       */
      if (val.length === 0) {
        return [];
      }

      /*
       * This is sending all the questions to the backend
       * for inserts, updates and deletes.
       * This is sub-optimal but t it works well with the undo/redo system.
       */
      const savedQuestions = val.map((question, index) => ({
        id: question.id,
        deleted: question.deleted,
        content: question.content || '',
        file: question.file
          ? {
              id: question.file.id,
              path: question.file.url,
            }
          : undefined,
        isRequired: question.isRequired,
        seq: index,
      }));
      await rpx.assessments.upsertAssignmentQuestions({
        lessonId,
        questions: savedQuestions,
      });
    } catch (err) {
      showError(err);
    }
  });

  return (
    <LessonPane
      isVisible
      hide={props.hide}
      header={
        <div class="flex grow justify-between">
          <div class="flex space-x-2">
            <span class="block font-bold text-zinc-500 capitalize">Assignments</span>
            <Case when={questions.length > 0}>
              <span> | </span>
              <Button class="text-indigo-600" onClick={() => setShowResults(!showResults)}>
                {showResults ? 'Hide Submissions' : 'View Submissions'}
              </Button>
            </Case>
          </div>
          <SaveStatus isDirty={autosaver.isDirty} padding="pr-2" />
        </div>
      }
    >
      <Case when={!showResults} fallback={<AssignmentSubmissions lesson={props.lesson} />}>
        <label class="flex items-center py-4 cursor-pointer">
          <Toggle
            checked={assignment.settings.requireAssignmentApproval}
            onClick={() =>
              setAssignment((s) => ({
                ...s,
                settings: {
                  ...s.settings,
                  requireAssignmentApproval: !s.settings.requireAssignmentApproval,
                },
              }))
            }
          />
          <p class="ml-2">
            Require {terminology.guide} approval for the {terminology.lesson} completion
          </p>
        </label>
        <label class="flex items-center py-4 cursor-pointer">
          <Toggle
            checked={assignment.settings.notifyAssignmentSubmissions}
            onClick={() =>
              setAssignment((s) => ({
                ...s,
                settings: {
                  ...s.settings,
                  notifyAssignmentSubmissions: !s.settings.notifyAssignmentSubmissions,
                },
              }))
            }
          />
          <p class="ml-2">Receive an email when a student submits an assignment</p>
        </label>
        <Case when={questions.length > 0}>
          <div class="flex flex-col py-6">
            <DraggableProvider
              canHandleDrop={(_, table) => table === 'assignment-questions'}
              onDragComplete={() => {}}
              onTargetChange={(dragState) => setQuestions((s) => reorderItems(s, dragState))}
            >
              {activeQuestions.map((question) => (
                <Draggable key={question.id} id={question.id} table="assignment-questions">
                  <Case
                    when={openedQuestion === question.id}
                    fallback={
                      <ReadOnlyQuestion
                        question={question}
                        onClick={() => setOpenedQuestion(question.id)}
                      />
                    }
                  >
                    <AssignmentItem
                      question={question}
                      canDelete={activeQuestions.length > 1}
                      onChange={async (question) => {
                        const isAllOptional = activeQuestions
                          .filter((q) => q.id !== question.id)
                          .every((q) => !q.isRequired);
                        if (
                          isAllOptional &&
                          !question.isRequired &&
                          assignment.settings.requireAssignmentApproval
                        ) {
                          const isConfirmed = await showConfirmModal({
                            title: 'Disable the approval requirement for this assignment?',
                            body: `This is the last mandatory item for ${props.lesson.title}. If you make this item optional, there will be no mandatory items left. Students will no longer be required to complete the assignment before proceeding in the course. Is that OK?`,
                            confirmButtonText: 'OK',
                          });
                          if (!isConfirmed) {
                            return;
                          }

                          setAssignment((s) => ({
                            ...s,
                            settings: {
                              ...s.settings,
                              requireAssignmentApproval: false,
                            },
                          }));
                        }
                        updateQuestion(question.id, () => question);
                      }}
                      onDelete={() => {
                        if (hasAssessmentSubmissions) {
                          return showHasSubmissionsWarning();
                        }
                        updateQuestion(question.id, (q) => ({ ...q, deleted: true }));
                        setShowUndoNotice(true);
                      }}
                      onDuplicate={() => {
                        if (hasAssessmentSubmissions) {
                          return showHasSubmissionsWarning();
                        }
                        const newQuestion = {
                          ...question,
                          content: `Copy of ${question.content}`,
                          id: generateUUID(),
                        };
                        setQuestions((q) => [...q, newQuestion]);
                        setOpenedQuestion(newQuestion.id);
                      }}
                    />
                  </Case>
                </Draggable>
              ))}
            </DraggableProvider>
          </div>
        </Case>
        <footer class="mb-4">
          <Button
            class="inline-flex items-center text-indigo-600 outline-none focus:ring-2 focus:ring-indigo-400 rounded"
            onClick={createNewQuestion}
          >
            <IcoPlus class="h-6 w-6 mr-1 opacity-75" />
            Create a new assignment question
          </Button>
        </footer>
        <UndoNotice
          displayed={showUndoNotice}
          text="Question deleted."
          onClick={undoRedo.undo}
          hide={() => setShowUndoNotice(false)}
        />
      </Case>
    </LessonPane>
  );
}
