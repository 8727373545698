/**
 * A page which presents as a modal.
 */

import { ComponentChildren } from 'preact';
import { useEsc } from 'client/utils/use-esc';

export function ModalPage(props: { children: ComponentChildren; onClose(): void }) {
  useEsc(props.onClose, { escapeOnly: true });

  return (
    <div
      class="fixed inset-0 backdrop-blur bg-gray-600/50 flex items-start pt-10 justify-center z-50"
      onMouseDown={(e) => {
        if (e.target === e.currentTarget) {
          e.preventDefault();
          e.stopPropagation();
          props.onClose();
        }
      }}
    >
      <section class="rounded-t-xl sm:rounded-3xl flex flex-col bg-white shadow-2xl w-full absolute sm:relative bottom-0 sm:w-2xl h-(screen-16) sm:h-auto sm:max-w-(screen-16) overflow-hidden">
        {props.children}
      </section>
    </div>
  );
}
