import { Modal, ModalContent, ModalFooter, ModalTitle } from '@components/modal';
import { AsyncForm, FormGroup } from '@components/async-form';
import { Button } from '@components/buttons';
import { useState } from 'preact/hooks';
import { useCurrentTenant } from '@components/router/session-context';
import { Toggle } from '@components/toggle';
import { rpx } from 'client/lib/rpx-client';
import { DatePicker, TimezoneDiff, BtnSchedule } from '@components/date-picker';

interface Props {
  scheduledAt?: Date;
  courseId: UUID;
  meetingId: UUID;
  hasEnrolledStudents: boolean;
  onSuccess: (newDate: Date) => void;
  hide(): void;
}

interface FormData {
  sendNotification: boolean;
}

export function RescheduleModal(props: Props) {
  const { terminology } = useCurrentTenant();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(() => props.scheduledAt || new Date());
  const isDraft = !props.scheduledAt;

  async function reschedule(formData: FormData) {
    setIsLoading(true);
    try {
      await rpx.meetings.rescheduleMeeting({
        courseId: props.courseId,
        meetingId: props.meetingId,
        scheduledAt: selectedDate.toISOString(),
        sendNotification: !isDraft && props.hasEnrolledStudents ? formData.sendNotification : false,
      });
      props.onSuccess(selectedDate);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal isOpen onCancel={props.hide}>
      <AsyncForm class="md:min-w-96 p-4" onSubmit={reschedule}>
        <ModalTitle centered>
          {isDraft ? 'Schedule' : 'Reschedule'} {terminology.meeting}
        </ModalTitle>
        <ModalContent mode="none">
          <FormGroup prop="scheduledAt">
            <DatePicker value={selectedDate} onChange={(dt) => dt && setSelectedDate(dt)} />
          </FormGroup>
        </ModalContent>
        <TimezoneDiff date={selectedDate} class="my-4" />
        {!isDraft && props.hasEnrolledStudents && (
          <label class="flex my-4 cursor-pointer border rounded-full p-3 gap-2 hover:bg-gray-50">
            <Toggle name="sendNotification" defaultChecked />
            <span class="block">Notify enrolled students that you're rescheduling</span>
          </label>
        )}
        <ModalFooter>
          <Button class="p-2 px-4 rounded-md hover:bg-gray-100" onClick={props.hide}>
            Cancel
          </Button>
          <BtnSchedule
            prefix={`${isDraft ? 'Schedule' : 'Reschedule'} for `}
            isLoading={isLoading}
            date={selectedDate}
          />
        </ModalFooter>
      </AsyncForm>
    </Modal>
  );
}
