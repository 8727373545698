import { showError } from '@components/app-error';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { Case } from '@components/conditional';
import { IcoGripperHorizontal, IcoTrash, IcoX } from '@components/icons';
import { LoadingIndicator } from '@components/loading-indicator';
import { showConfirmModal, showModalForm } from '@components/modal-form';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useImageUrl } from 'client/utils/cdn';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useState } from 'preact/hooks';
import { FullCourse } from 'server/types';
import { URLS } from 'shared/urls';
import CourseItem from '../user-my-courses/course-item';
import { CourseList } from '../user-my-courses/course-list';
import { EmptyScreen } from '../user-my-courses/empty-screen';
import { normalizedStatus } from '../user-my-courses/utils';
import { useCurrentUser } from 'client/lib/auth';
import { hasLevel } from 'shared/auth';
import { Modal, ModalFooter, ModalTitle } from '@components/modal';
import { TenantCoursesList } from 'client/pages/admin-courses/course-list';
import { UserProfileIcon } from '@components/avatars';
import { showToast } from '@components/toaster';
import { Draggable, DraggableProvider, reorderItems } from '@components/draggable';

type Props = {
  bundle: FullCourse;
  hasStudents: boolean;
};

const store = rpx.guideBundles;

type Subcourse = RpxResponse<typeof store.getSubcourses>[0];

export function Subcourses({ bundle, hasStudents }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [subcourses, setSubcourses] = useState<Subcourse[]>([]);
  const [showAddCourse, setShowAddCourse] = useState(false);

  useAsyncEffect(async () => {
    const subcourses = await store.getSubcourses({
      id: bundle.id,
    });
    setIsLoading(false);
    setSubcourses(subcourses);
  });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (subcourses.length === 0 && !showAddCourse) {
    return (
      <div class="flex grow items-center">
        <EmptyScreen
          title="No courses in this bundle"
          subtext="When you add courses to this bundle, they will show up here."
          cta={
            <BtnPrimary class="px-4" onClick={() => setShowAddCourse(true)}>
              Add a course to the bundle
            </BtnPrimary>
          }
        />
      </div>
    );
  }

  return (
    <div class="pt-4">
      <AddCourseRibbon
        existingCourses={subcourses.map((c) => c.id)}
        isOpen={showAddCourse}
        hide={() => setShowAddCourse(false)}
        onAdd={async (newCourse) => {
          if (subcourses.some((c) => c.id === newCourse.id)) {
            showToast({
              type: 'warn',
              title: 'This course is already in the bundle',
              message: 'You can only add a course once to a bundle.',
            });
            return;
          }

          if (hasStudents) {
            const confirmed = await showConfirmModal({
              mode: 'none',
              title: `Add "${newCourse.title}" to the bundle?`,
              body: 'Are you sure you want to add this course to the bundle? All students signed up for this bundle will be automatically signed up for the new course.',
              confirmButtonText: 'Add course',
            });
            if (!confirmed) {
              return;
            }
          }

          try {
            await store.addCourseToBundle({
              courseId: newCourse.id,
              bundleId: bundle.id,
              seq: subcourses.length,
            });

            setShowAddCourse(false);
            setSubcourses([...subcourses, newCourse]);
          } catch (err) {
            showError(err);
          }
        }}
      />
      <Case when={!showAddCourse}>
        <div class="grow space-y-8">
          <div class="flex items-center justify-between gap-8">
            <h2 class="text-2xl font-bold text-zinc-900 dark:text-zinc-50">
              Courses in this bundle
            </h2>
            <BtnPrimary class="px-4" onClick={() => setShowAddCourse(true)}>
              Add a new course
            </BtnPrimary>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
            <DraggableProvider
              canHandleDrop={(_, table) => table === 'subcourses'}
              onDragComplete={async () => {
                await store.reorderSubcourses({
                  bundleId: bundle.id,
                  courseIds: subcourses.map((c) => c.id),
                });
              }}
              onTargetChange={(dragState) => setSubcourses((s) => reorderItems(s, dragState))}
            >
              {subcourses.map((subcourse) => (
                <Draggable
                  key={subcourse.id}
                  id={subcourse.id}
                  class="relative inline-block rounded-lg border border-gray-300 hover:border-indigo-600 group"
                  table="subcourses"
                >
                  <SubcourseItem
                    course={subcourse}
                    onDelete={async () => {
                      const confirmed = await showConfirmModal({
                        mode: 'warn',
                        title: 'Remove course from the bundle?',
                        body: `When you remove this course from your bundle, existing students will still be able to see the course in their accounts. New students won't get this course when they sign up for the bundle.`,
                        confirmButtonText: 'Remove',
                      });
                      if (!confirmed) {
                        return;
                      }

                      await store.deleteCourseFromBundle({
                        courseId: subcourse.id,
                        bundleId: bundle.id,
                      });
                      setSubcourses(subcourses.filter((c) => c.id !== subcourse.id));
                    }}
                  />
                </Draggable>
              ))}
            </DraggableProvider>
          </div>
        </div>
      </Case>
    </div>
  );
}

function SubcourseItem({ course, onDelete }: { course: Subcourse; onDelete: () => void }) {
  const src = useImageUrl(course.imagePath);

  return (
    <>
      <div class="flex justify-end">
        <div
          class="hidden group-hover:block absolute top-2 left-1/2 text-gray-500 cursor-move"
          draggable
        >
          <IcoGripperHorizontal class="w-5 h-5" />
        </div>
        <Button class="p-2 hover:opacity-70" title="Remove from bundle" onClick={onDelete}>
          <IcoTrash class="w-4 h-4" />
        </Button>
      </div>
      <a
        class="flex flex-col overflow-hidden text-inherit"
        href={URLS.guide.course({
          courseId: course.id,
        })}
        // We need this to avoid the `router` from trying to handle the link
        // because it thinks it's a subroute of the current page.
        data-noroute
      >
        <span class="flex items-center justify-center h-52 bg-gray-100 w-full border-b overflow-hidden">
          {src && <img src={`${src}?width=600`} class="object-cover" />}
          {!src && <span class="opacity-50">No image</span>}
        </span>
        <span class="flex items-center px-4 my-4 text-sm border-b border-dashed pb-4 relative">
          <UserProfileIcon user={course.guide} size="w-8 h-8" />
          <span class="ml-3 inline-flex flex-col">
            <span class="opacity-50 text-sm leading-4">Guide</span>
            <span class="line-clamp-1">{course.guide.name}</span>
          </span>
        </span>
        <span class="flex items-center text-sm min-h-10 whitespace-normal w-full text-left p-4 pt-0 h-20">
          <strong class="line-clamp-2">{course.title}</strong>
        </span>
      </a>
    </>
  );
}

type GuidesCourse = RpxResponse<typeof rpx.courses.getMyCourses>[0] & {
  search: string;
  normalizedStatus: 'draft' | 'published' | 'closed';
  numStudents: number;
};

function AddCourseRibbon({
  existingCourses,
  isOpen,
  hide,
  onAdd,
}: {
  existingCourses: UUID[];
  isOpen: boolean;
  hide: () => void;
  onAdd: (course: Subcourse) => void;
}) {
  const user = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState<GuidesCourse[]>([]);

  useAsyncEffect(async () => {
    if (!isOpen || courses.length > 0) {
      return;
    }

    setIsLoading(true);
    try {
      const courses = await rpx.courses.getMyCourses({ asGuide: true });
      setCourses(
        courses.map((c) => ({
          ...c,
          search: `${c.title.toLocaleLowerCase()} ${c.id}`,
          normalizedStatus: normalizedStatus(c),
          numStudents: c.numStudents || 0,
        })),
      );
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }, [isOpen]);

  function addCourseFromOtherGuides() {
    showModalForm(({ resolve }) => {
      return (
        <Modal size="max-w-5xl" isOpen onCancel={() => resolve(undefined)}>
          <ModalTitle>Choose a course</ModalTitle>
          <TenantCoursesList
            type="allWithNoData"
            dontRewriteUrl
            onItemSelect={(course) => {
              resolve();
              onAdd({
                id: course.id,
                title: course.title,
                accessFormat: course.accessFormat,
                status: course.status,
                imagePath: course.imagePath,
                guide: course.guide,
              });
            }}
          />
          <ModalFooter>
            <BtnSecondary onClick={resolve}>Cancel</BtnSecondary>
          </ModalFooter>
        </Modal>
      );
    });
  }

  if (!isOpen) {
    return null;
  }

  return (
    <div class="mb-8 relative bg-gray-50 rounded">
      {isLoading && <LoadingIndicator />}
      <div class="space-y-6 p-8">
        <Button class="absolute p-4 bg-gray-200 rounded-full -right-4 -top-6" onClick={hide}>
          <IcoX class="w-6 h-6" />
        </Button>
        <div class="flex justify-between">
          <h2 class="text-lg font-semibold text-zinc-900 dark:text-zinc-50">
            Add a course into bundle
          </h2>
          <div class="flex gap-4">
            {hasLevel(user, 'admin') && (
              <BtnSecondary onClick={addCourseFromOtherGuides}>
                Choose a course from other guides
              </BtnSecondary>
            )}
            <BtnPrimary href="/courses?newRibbon">Create a new course</BtnPrimary>
          </div>
        </div>
        {courses.length > 0 && (
          <CourseList
            courses={courses.filter((c) => !existingCourses.includes(c.id))}
            show={'all'}
            renderItem={({ course, listType, searchTerm, displayDate }) => (
              <CourseItem
                key={course.id}
                course={course}
                listType={listType}
                highlightTerm={searchTerm}
                displayDate={displayDate}
                readonly
                onClick={() => {
                  onAdd({
                    id: course.id,
                    title: course.title,
                    accessFormat: course.accessFormat,
                    status: course.status,
                    imagePath: course.imagePath,
                    guide: course.guide,
                  });
                }}
              />
            )}
          />
        )}
      </div>
    </div>
  );
}
