import { AppRoute } from 'client/lib/app-route/types';
import { useCurrentUser } from '@components/router/session-context';
import { useIntl } from 'shared/intl/use-intl';
import { rpx } from 'client/lib/rpx-client';
import { LoadedProps } from 'client/lib/loaders';
import { Certificate } from '@components/certificate';
import { router } from '@components/router';
import { IcoBadge, IcoInfo, IcoPrinter } from '@components/icons';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { URLS } from 'shared/urls';
import { useState } from 'preact/hooks';

function StudentCertificate({ state }: LoadedProps<typeof load>) {
  const intl = useIntl();
  const user = useCurrentUser();
  const isGuide = user?.id === state.course.guide.id;
  const isMine = state.userId === user?.id;
  const [printStyles, setPrintStyles] = useState<undefined | string>(undefined);
  const { cert } = state;

  const computeAndSetPrintStyles = (container: Element | null) => {
    if (printStyles || !container) {
      return;
    }
    const el = container.querySelector('.certificate');
    if (!el) {
      return;
    }
    const bounds = el.getBoundingClientRect();
    const layout = bounds.width > bounds.height ? 'landscape' : 'portrait';
    setPrintStyles(`
      @page {
        size: ${layout};
        margin: 0;
      }
      main {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid black;
      }
    `);
  };

  if (!cert.isEnabled && !isGuide) {
    return null;
  }

  if (!state.isComplete && !isGuide) {
    return (
      <div class="flex items-center text-gray-900 dark:text-white">
        <div class="inline-flex flex-col p-20 max-w-readable text-center space-y-6">
          <header class="flex items-top">
            <span>
              <IcoBadge class="w-32 h-32 theme-link fill-current opacity-50 inline-flex rounded-full" />
            </span>
            <div class="flex flex-col justify-center text-left ml-8 mt-2">
              <h1 class="font-display text-3xl leading-snug tracking-tight">
                {intl('Certificate of Completion')}
              </h1>
              <p class="opacity-75">
                {intl('Your certificate will be available as soon as you complete all lessons.')}
              </p>
              <footer class="mt-4">
                <BtnPrimary
                  class="gap-2 py-2"
                  href={URLS.student.lessons({ courseId: state.courseId })}
                >
                  <span>⬸</span>
                  <span>Go to lessons</span>
                </BtnPrimary>
              </footer>
            </div>
          </header>
        </div>
      </div>
    );
  }

  return (
    <div class="w-full flex flex-col gap-6" ref={computeAndSetPrintStyles}>
      <header class="print:hidden flex justify-center">
        <BtnSecondary class="gap-2 text-xs p-2 px-4" onClick={window.print}>
          <IcoPrinter />
          Print or Save to PDF
        </BtnSecondary>
      </header>
      {isGuide && isMine && (
        <header class="print:hidden bg-yellow-50 p-2 px-4 rounded-md border border-yellow-200 flex flex-wrap gap-6 items-center">
          <span class="flex gap-2">
            <span>
              <IcoInfo class="text-yellow-600 w-6 h-6 opacity-50" />
            </span>
            <span>
              This is a preview of how certificates will look for your students when they finish all
              lessons in your course.
            </span>
          </span>
          <aside class="ml-auto">
            <BtnSecondary
              class="bg-white p-2"
              href={`/manage/courses/${state.courseId}/certificate`}
            >
              Edit
            </BtnSecondary>
          </aside>
        </header>
      )}
      {printStyles && (
        <style media="print" dangerouslySetInnerHTML={{ __html: printStyles }}></style>
      )}
      <Certificate
        cert={cert}
        data={{
          course: state.course,
          student: state.student,
          issuedDate: state.issuedDate || '',
        }}
      />
    </div>
  );
}

function Page(props: LoadedProps<typeof load>) {
  return (
    <div class="print:min-h-0 flex flex-col justify-center items-center min-h-screen p-4 max-w-full">
      <StudentCertificate {...props} />
    </div>
  );
}

async function load(route: AppRoute) {
  const { courseId, userId } = route.params;
  const result = await rpx.certificates.getCertificate({ courseId, userId });
  return { ...result, userId, courseId, issuedDate: result.issuedDate || new Date() };
}

router.add({
  url: 'courses/:courseId/certificate/:userId',
  render: Page,
  authLevel: 'student',
  load,
  isPublic: true,
});
