import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { AsyncForm } from '../async-form';
import { BtnPrimary } from '../buttons';
import { showError } from '../app-error';
import { useAsyncEffect } from 'client/utils/use-async-effect';

/**
 * The checkout form provides a standard wrapper and submit behavior
 * for the various checkout forms.
 */
export function CheckoutForm({
  actionText,
  onSubmit,
  autoSubmit,
  children,
  footer,
}: {
  actionText: string;
  children?: ComponentChildren;
  footer?: ComponentChildren;
  /**
   * If specified, the form will automatically submit itself when it
   * loads. This is handy if the form is render post-login, and the
   * login screen was the "accept / submit" action.
   */
  autoSubmit?: boolean;
  onSubmit(data: any): Promise<unknown>;
}) {
  const [isAutomatic, setIsAutomatic] = useState(autoSubmit);
  const [isLoading, setIsLoading] = useState(autoSubmit);

  useAsyncEffect(async () => {
    if (!isAutomatic) {
      return;
    }
    try {
      await onSubmit(undefined);
    } catch (err) {
      setIsAutomatic(false);
      showError(err);
      setIsLoading(false);
    }
  }, [isAutomatic]);

  return (
    <AsyncForm
      class="flex flex-col gap-4"
      onSubmit={async (data: any) => {
        try {
          setIsLoading(true);
          return await onSubmit(data);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      {children}
      <BtnPrimary class="w-full p-3" isLoading={isLoading}>
        {actionText}
      </BtnPrimary>
      {footer}
    </AsyncForm>
  );
}
