import { Crumb } from '@components/breadcrumbs';
import { Button, ButtonProps } from '@components/buttons';
import { CourseBreadcrumbs } from '@components/course-breadcrumbs';
import { IcoDocumentSearch } from '@components/icons';
import { SiteNavLogo } from '@components/nav/site-nav-logo';
import { UserMenu } from '@components/nav/user-menu';
import { ComponentChildren } from 'preact';
import { BrandRow, Course } from 'server/types';

interface Props {
  course?: Pick<Course, 'id' | 'title'>;
  crumbs?: Array<Crumb | undefined>;
  viewLink?: string;
  children?: ComponentChildren;
  brand?: Pick<BrandRow, 'name' | 'showName' | 'iconUrl' | 'logoColor'>;
}

/**
 * TopBar is the component that displays the breadcrumbs and current user,
 * at the top of the manage courses screens.
 */
export function TopBar({ course, brand, crumbs, viewLink, children }: Props) {
  return (
    <div class="flex items-center justify-between p-4 sm:px-6 sm:pr-4 py-4">
      <header class="flex items-center">
        {(course || !!crumbs?.length) && <CourseBreadcrumbs course={course} crumbs={crumbs} />}
        {!course && <SiteNavLogo brand={brand} />}
        {children}
      </header>
      <nav class="inline-flex items-center">
        {course && (
          <Button
            class="mr-4 bg-green-600 text-white px-4 p-1 rounded whitespace-nowrap"
            href={viewLink || `/courses/${course.id}`}
          >
            <IcoDocumentSearch class="h-4 w-4 mr-1" />
            Student View
          </Button>
        )}
        <UserMenu />
      </nav>
    </div>
  );
}

export function TopBarBanner({ text, buttonProps }: { text: string; buttonProps: ButtonProps }) {
  return (
    <div class="w-full bg-indigo-600 py-3 px-3 sm:px-6 lg:px-8 text-lg text-white text-center">
      <span class="hidden md:inline mr-4">{text}</span>
      <Button
        class="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50 dark:hover:bg-indigo-800"
        {...buttonProps}
      />
    </div>
  );
}
