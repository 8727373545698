import { MemberFeed } from './member-feed';
import { UserProfileIcon } from '@components/avatars';
import { SelectedUser } from './index';
import { Case } from '@components/conditional';
import { Pill } from '@components/pill';
import { useConfiguration } from '@components/router/session-context';
import { useIntl } from 'shared/intl/use-intl';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { BtnSecondary } from '@components/buttons';
import { IcoPencilSquare } from '@components/icons';
import { sendPrivateMessage } from '@components/chat-widget/events';
import { CourseWithGuide } from 'server/types';

interface Props {
  user: SelectedUser;
  course: Pick<CourseWithGuide, 'id' | 'title' | 'guide' | 'chatEnabled'>;
}

export function MemberProfile({ user, course }: Props) {
  const intl = useIntl();
  const { user: authUser, coreUser } = useConfiguration();
  const isMyself = user.id === authUser?.id;
  const canStartChatting = course.chatEnabled && user.enableIncomingChats && !isMyself;
  const isMimickingAsAdmin = coreUser?.level === 'admin';

  return (
    <div class="flex grow flex-col max-w-2xl px-4 mx-auto lg:mx-8">
      {canStartChatting && !isMimickingAsAdmin && (
        <div class="flex justify-end">
          <BtnSecondary
            onClick={() =>
              sendPrivateMessage({
                user,
                courseId: course.id,
              })
            }
          >
            <IcoPencilSquare class="w-4 h-4 mr-1" />{' '}
            {intl('Chat with {name:string}', {
              name: user.displayName || user.name,
            })}
          </BtnSecondary>
        </div>
      )}
      <div class="flex border-b dark:border-gray-700 py-6 mb-6">
        <span>
          <UserProfileIcon user={user} size="w-16 h-16 text-2xl" />
        </span>
        <div class="flex flex-col justify-center px-4 py-2">
          <div class="flex items-center">
            <h1 class="font-display text-3xl tracking-tight text-gray-900 dark:text-gray-200 mr-4">
              {user.name}
            </h1>
            <Case
              when={
                user.id === authUser?.id || user.level === 'guide' || user.level === 'facilitator'
              }
            >
              <Pill capitalize>
                {user.level === 'guide'
                  ? intl('guide')
                  : user.level === 'facilitator'
                  ? intl('facilitator')
                  : intl('you')}
              </Pill>
            </Case>
          </div>
          {course.guide.id !== user.id && user.displayCompletedLessons && (
            <p class="text-sm text-gray-500 dark:text-gray-400">
              {intl('Completed {percent:number}% of the course', {
                percent: user.completedPercent || 0,
              })}
            </p>
          )}
          {user.bio && (
            <ReadonlyMinidoc class="mt-4 text-gray-500 dark:text-gray-400" content={user.bio} />
          )}
          {isMyself && (
            <a href="/account" class="inline-block mt-4 dark:text-sky-400">
              {intl('Edit My Profile')}
            </a>
          )}
        </div>
      </div>
      <MemberFeed user={user} />
    </div>
  );
}
