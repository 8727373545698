import { useContext } from 'preact/hooks';
import { ModalFormContext, showModalForm } from '@components/modal-form';
import { Modal } from '@components/modal';
import { AsyncForm } from '@components/async-form';
import { rpx } from 'client/lib/rpx-client';
import { HeadingPrimary } from '@components/headings';
import { IcoCreditCard } from '@components/icons';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import {
  AllowGiftsToggle,
  ExpandableLimitsFields,
  FreeTrialPeriod,
  PricePointName,
  Provider,
  RequireContactToCancel,
} from './new-price-form';
import { formDataToPriceLimits } from './signup-limits-tab';
import { AccessLimitsFormFields } from './access-limits-tab';
import { ExpandedPrice } from 'server/payments/service';

const priceStore = rpx.prices;
const ALWAYS_DURATION = -1;

export function showUpdatePriceModal({
  price,
  stripeMetadata,
  supportsPaypal,
  supportsStripe,
  onSave,
}: {
  price: ExpandedPrice;
  supportsStripe: boolean;
  supportsPaypal: boolean;
  stripeMetadata?: Record<string, string>;
  onSave(price: ExpandedPrice): Promise<void>;
}) {
  return showModalForm(() => {
    const { resolve } = useContext(ModalFormContext);
    const hide = () => resolve(price);

    return (
      <Modal isOpen onCancel={hide}>
        <AsyncForm
          class="flex flex-col"
          onSubmit={async (formData) => {
            const accessDuration = parseInt(formData.accessDuration, 10) || ALWAYS_DURATION;
            const freeTrialPeriod = parseInt(formData.freeTrialPeriod) || 0;
            const { requireContactToCancel, ...restOfData } = formData;
            const updatedPrice = await priceStore.updatePrice({
              ...restOfData,
              ...formDataToPriceLimits(formData),
              freeTrialPeriod,
              accessDuration: accessDuration === ALWAYS_DURATION ? null : accessDuration,
              priceId: price.id,
              productId: price.productId,
              stripeMetadata: stripeMetadata,
              allowSelfCancel: !requireContactToCancel,
            });
            const result = { ...updatedPrice, accessDuration };
            await onSave(result);
            resolve(result);
          }}
        >
          <HeadingPrimary
            title={
              <span class="flex items-center">
                <IcoCreditCard class="w-12 h-12 bg-green-400 rounded-full text-white p-2 mr-4" />
                Edit Price Point
              </span>
            }
          />
          <div class="flex flex-col gap-6 mb-10">
            <PricePointName name={price.name} paymentType={price.paymentType} />

            <Provider
              paymentType={price.paymentType}
              supportsStripe={supportsStripe}
              supportsPaypal={supportsPaypal}
              checkStripe={!!price.allowStripe}
              checkPaypal={!!price.allowPaypal}
            />

            <FreeTrialPeriod paymentType={price.paymentType} value={price.freeTrialPeriod} />
            <RequireContactToCancel
              paymentType={price.paymentType}
              defaultValue={!price.allowSelfCancel}
            />
            <AllowGiftsToggle paymentType={price.paymentType} defaultValue={price.isGiftable} />
            <AccessLimitsFormFields accessDuration={price.accessDuration} />

            <ExpandableLimitsFields price={price} />
          </div>

          <footer class="flex sticky bottom-0 bg-white pt-6 flex-row-reverse justify-items-end items-center border-t">
            <BtnPrimary>Save Price Point</BtnPrimary>
            <BtnSecondary class="mr-2" type="button" onClick={hide}>
              Cancel
            </BtnSecondary>
          </footer>
        </AsyncForm>
      </Modal>
    );
  });
}
