import { Modal, ModalFooter, ModalMessage, ModalTitle } from '@components/modal';
import { AsyncForm, FormGroup, InputLabel } from '@components/async-form';
import { BtnSecondary, BtnPrimary } from '@components/buttons';
import { useState } from 'preact/hooks';
import { rpx } from 'client/lib/rpx-client';
import { Meeting } from 'server/types';
import { DateInputWithTimezone } from '@components/date-picker';
import { showError } from '@components/app-error';
import { useRouteParams } from '@components/router';

interface Props {
  meeting: Pick<Meeting, 'id' | 'title' | 'scheduledAt'>;
  onSuccess: (newMeeting: Meeting) => void;
  hide(): void;
}

interface FormData {
  title: string;
  scheduledAt?: string;
}

export function DuplicateModal({ meeting, onSuccess, hide }: Props) {
  const { courseId } = useRouteParams();
  const [isLoading, setIsLoading] = useState(false);
  const [scheduledAt, setScheduledAt] = useState<Date | undefined>();

  async function duplicate({ title }: FormData) {
    setIsLoading(true);

    try {
      const newMeeting = await rpx.meetings.duplicateMeeting({
        courseId,
        meetingId: meeting.id,
        title,
        scheduledAt: scheduledAt ? new Date(scheduledAt).toISOString() : undefined,
      });
      onSuccess(newMeeting);
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal isOpen onCancel={hide}>
      <AsyncForm onSubmit={duplicate}>
        <ModalTitle centered>
          Duplicate "<strong>{meeting.title}</strong>" Meeting
        </ModalTitle>
        <ModalMessage class="mt-6 space-y-6">
          <FormGroup prop="title">
            <InputLabel>Title</InputLabel>
            <input
              type="text"
              placeholder="Meeting title"
              name="title"
              class="ruz-input"
              // @ts-ignore
              defaultValue={`Copy of ${meeting.title}`}
            />
          </FormGroup>
          <FormGroup class="mb-6" prop="scheduledAt">
            <InputLabel>Scheduled Start</InputLabel>
            <DateInputWithTimezone
              name="scheduledAt"
              includeTime
              value={scheduledAt}
              onChange={setScheduledAt}
              placeholder="Scheduled start date"
            />
          </FormGroup>
        </ModalMessage>

        <ModalFooter>
          <BtnSecondary type="button" onClick={hide}>
            Cancel
          </BtnSecondary>
          <BtnPrimary isLoading={isLoading}>Duplicate Meeting</BtnPrimary>
        </ModalFooter>
      </AsyncForm>
    </Modal>
  );
}
