import { useState, useMemo } from 'preact/hooks';
import { showError } from '@components/app-error';
import { showToast } from '@components/toaster';
import PreviewModal from './preview-modal';
import { MessageEditor } from './message-editor';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { IcoCalendar, IcoDuplicate, IcoSend, IcoTrash } from '@components/icons';
import { LoadingIndicator } from '@components/loading-indicator';
import { showConfirmModal, showModalForm } from '@components/modal-form';
import { FullCourse, CourseRow, MessageRow } from 'server/types';
import { ScheduleModal } from './schedule-modal';
import { timezoneCity, toHumanTime, toISOString, toLocalDate, toUTCDate } from 'shared/dateutil';
import { Subtext } from '@components/async-form';
import { scheduleDescription } from '@components/module-helpers';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { Message } from './types';
import { pluralize } from 'shared/formatting';
import { useCurrentUser } from '@components/router/session-context';
import { Case } from '@components/conditional';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useTimezone } from 'client/lib/hooks';
import dayjs from 'dayjs';

const store = rpx.messages;

export type CustomMessage = RpxResponse<typeof store.getFullMessage>;

export type Updater = (message: Partial<Message> & { id: UUID | string }) => void;

export interface Props {
  course: Pick<
    FullCourse,
    'id' | 'accessFormat' | 'numStudents' | 'isBundle' | 'isProduct' | 'isAbsoluteSchedule'
  >;
  message: CustomMessage;
  onUpdate: Updater;
  onDelete(id: UUID): void;
  onDuplicate?(message: Message): void;

  /**
   * Used to specify that the editor should render as if it's an embedded
   * editor (e.g. in a modal / embedded in another screen).
   */
  embeddedMode?: boolean;
}

export type MessagePreviewResult = RpxResponse<typeof store.getMessagePreview> & {
  toMyself: boolean;
};

export const DEFAULT_CONTENT =
  '<p>Hi <template-field contenteditable="false">{{{recipient-name}}}</template-field>,</p>';

export const isScheduled = (
  course: Pick<CourseRow, 'accessFormat'>,
  m: { startOffset?: number; startDate?: string | Date },
) => {
  if (course.accessFormat === 'ondemand') {
    return m.startOffset !== undefined;
  }
  return !!m.startDate;
};

export function CustomMessageEditor(props: Props) {
  const { course, onUpdate } = props;
  const courseId = course.id;
  const guide = useCurrentUser();

  const [message, setMessage] = useState(props.message);
  const [isSending, setIsSending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [previewResult, setPreviewResult] = useState<MessagePreviewResult | undefined>(undefined);

  const hasSchedule = isScheduled(course, message);
  const timezone = useTimezone();
  const shouldConvertDate = course.accessFormat === 'scheduled' && course.isAbsoluteSchedule;

  const sentAt = useMemo(() => {
    if (message.sentAt) {
      return message.sentAt;
    }
    if (message.startDate) {
      const date = new Date(message.startDate);
      if (course.isAbsoluteSchedule) {
        return dayjs().isAfter(message.startDate) ? date : undefined;
      } else if (dayjs().diff(message.startDate, 'hours') > 24) {
        // Mark as sent if it's been more than 24 hours since the scheduled time
        // because the message is sent on student's local time
        return date;
      }
    }
  }, [message.sentAt, message.startDate]);

  async function save(content: string, title: string) {
    try {
      const result = await store.saveMessage({
        id: message.id,
        courseId,
        title,
        content,
      });

      setMessage((m) => ({
        ...m,
        ...result,
        content,
      }));

      onUpdate({
        id: result.id,
        subject: result.title,
        updatedAt: result.updatedAt,
      });
    } catch (err) {
      showError(err);
    }
  }

  async function scheduleMessage(
    data: Pick<MessageRow, 'startDate' | 'startOffset'>,
    onSuccess?: () => void,
  ) {
    try {
      const strData = {
        ...data,
        startDate:
          course.accessFormat === 'scheduled' && course.isAbsoluteSchedule
            ? toISOString(data.startDate)
            : toUTCDate(data.startDate),
      };
      await store.scheduleMessage({
        id: message.id,
        courseId,
        ...strData,
      });

      setMessage((m) => ({ ...m, ...strData }));

      onUpdate({
        id: message.id,
        startDate: strData.startDate,
        startOffset: strData.startOffset,
      });
      onSuccess?.();
    } catch (err) {
      showError(err);
    }
  }

  async function displayPreview(toMyself: boolean) {
    setIsSending(true);
    try {
      const result = await store.getMessagePreview({
        id: message.id,
        courseId,
      });
      setPreviewResult({
        ...result,
        toMyself,
      });
    } catch (err) {
      showError(err);
    } finally {
      setIsSending(false);
    }
  }

  async function sendPreview() {
    if (isSending) {
      return;
    }
    setIsSending(true);

    try {
      await store.sendPreviewMessage({
        id: message.id,
        courseId,
      });
      setPreviewResult(undefined);
    } catch (err) {
      showError(err);
    } finally {
      setIsSending(false);
    }
  }

  async function sendToStudents() {
    if (isSending) {
      return;
    }
    setIsSending(true);
    try {
      const result = await store.sendMessage({
        id: message.id,
        courseId,
      });
      setMessage((m) => ({ ...m, ...result }));
      showToast({
        type: 'ok',
        title: 'Message sent',
        message: `"${message.title}" sent successfully.`,
      });
      onUpdate({
        id: message.id,
        sentAt: result.sentAt,
      });
      setPreviewResult(undefined);
    } catch (err) {
      showError(err);
    } finally {
      setIsSending(false);
    }
  }

  async function deleteMessage() {
    try {
      if (
        await showConfirmModal({
          mode: 'warn',
          title: 'Permanently delete message?',
          body: `Do you want to delete "${message.title}"? Your changes will be lost and cannot be recovered.`,
          confirmButtonText: 'Delete Message',
        })
      ) {
        setIsDeleting(true);
        await store.deleteMessage({ courseId, id: message.id });
        showToast({
          type: 'ok',
          title: 'Deleted message',
          message: message.title,
        });
        props.onDelete(message.id);
      }
    } catch (err) {
      showError(err);
    } finally {
      setIsDeleting(false);
    }
  }

  async function duplicateMessage() {
    if (!props.onDuplicate) {
      return;
    }

    try {
      setIsDuplicating(true);
      const result = await store.saveMessage({
        courseId,
        title: `Copy of ${message.title}`,
        content: message.content,
      });
      const msg: Message = {
        ...result,
        subject: result.title,
        editUrl: '',
      };
      props.onDuplicate(msg);
    } catch (err) {
      showError(err);
    } finally {
      setIsDuplicating(false);
    }
  }

  function showScheduleModal() {
    showModalForm(({ resolve }) => {
      return (
        <ScheduleModal
          accessFormat={course.accessFormat}
          isAbsoluteSchedule={!!course.isAbsoluteSchedule}
          startDate={message.startDate}
          startOffset={message.startOffset}
          applySchedule={async (schedule) => {
            scheduleMessage(schedule, () => resolve(true));
          }}
          hide={() => resolve(undefined)}
        />
      );
    });
  }

  if (isDeleting || isDuplicating) {
    return <LoadingIndicator />;
  }

  return (
    <div class="w-full">
      {previewResult && (
        <Case
          when={!previewResult.toMyself}
          fallback={
            <PreviewModal
              email={previewResult}
              recipientsText={guide?.email || ''}
              isSending={isSending}
              onCancel={() => setPreviewResult(undefined)}
              onSend={sendPreview}
            />
          }
        >
          <PreviewModal
            email={previewResult}
            recipientsText={`${course.numStudents} ${pluralize(
              'student',
              course.numStudents || 0,
            )}`}
            isSending={isSending}
            onCancel={() => setPreviewResult(undefined)}
            onSend={sendToStudents}
          />
        </Case>
      )}
      {!sentAt && (
        <MessageEditor
          isProduct={course.isProduct}
          content={message?.content ?? DEFAULT_CONTENT}
          title={message?.title}
          isSending={isSending}
          isBundle={course.isBundle}
          allowPreview={!props.embeddedMode}
          onSave={save}
          onSend={() => displayPreview(true)}
        />
      )}
      {!!sentAt && (
        <>
          <h1 class="text-4xl">{message.title}</h1>
          <ReadonlyMinidoc class="prose-lg" content={message.content} id={message.id} />
        </>
      )}
      <footer class="mt-6 border-t p-4 py-6 flex justify-between items-center relative">
        {!sentAt && hasSchedule && (
          <Subtext>
            Scheduled for{' '}
            <Button class="mr-2 text-indigo-600" onClick={() => showScheduleModal()}>
              {scheduleDescription({
                accessFormat: course.accessFormat,
                startDate: shouldConvertDate
                  ? new Date(message.startDate!)
                  : toLocalDate(message.startDate),
                startOffset: message.startOffset,
                omitPrefix: true,
              })}
              {shouldConvertDate && ` (${timezoneCity(timezone)} time)`}
            </Button>
            <Button
              class="text-red-500 underline"
              onClick={() => {
                scheduleMessage({
                  startDate: undefined,
                  startOffset: undefined,
                });
              }}
            >
              Convert to draft
            </Button>
          </Subtext>
        )}
        {!sentAt && !hasSchedule && (
          <nav class="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-4">
            {!course.isProduct && (
              <BtnPrimary title="Schedule message" onClick={() => showScheduleModal()}>
                <IcoCalendar />
                <span class="ml-2">Schedule Message</span>
              </BtnPrimary>
            )}
            <Button
              title="Send message"
              class={`btn-secondary inline-flex items-center relative ${
                course.numStudents ? '' : 'opacity-50'
              }`}
              onClick={() => displayPreview(false)}
              data-tooltip={
                course.numStudents ? `${course.numStudents} students` : 'No students yet'
              }
              disabled={!course.numStudents || isSending}
            >
              <IcoSend />
              <span class="ml-2">Send Immediately</span>
            </Button>
          </nav>
        )}
        {!!sentAt && <Subtext>Sent on {toHumanTime(new Date(sentAt))}</Subtext>}
        {!props.embeddedMode && (
          <BtnSecondary title="Duplicate message" class="ml-auto" onClick={duplicateMessage}>
            <IcoDuplicate />
          </BtnSecondary>
        )}
        {!props.embeddedMode && (
          <BtnSecondary title="Delete message" class="ml-2" onClick={deleteMessage}>
            <IcoTrash />
          </BtnSecondary>
        )}
      </footer>
    </div>
  );
}
