/**
 * Manage courses view
 */

import { FixedPage, FixedContent } from '@components/fixed-page';
import { router, RouteProps } from '@components/router';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { CreateCourseModal, CreateBundleModal } from './create-course-modal';
import { TopBar } from '@components/top-bar';
import { IcoChevronDown, IcoDotsHorizontal } from '@components/icons';
import { useFlashError } from 'client/lib/flash-errors';
import { CopyCourseTemplateModal, onCourseDeleted } from '@components/course-action-modals';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { Data, load, Show } from './types';
import { EmptyScreen } from './empty-screen';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { CreateCourseRibbon, Template } from './create-course-ribbon';
import { InstantCoursesButton } from './instant-courses-button';
import { FreeTierBanner } from '@components/guide-page/free-tier-banner';
import { CourseList } from './course-list';
import CourseItem from './course-item';
import { useIntl } from 'shared/intl/use-intl';
import { Dropdown } from '@components/dropdown';
import { InvalidPaypalBanner } from '@components/guide-page/invalid-paypal-banner';
import { ProTierBanner } from '@components/guide-page/pro-tier-banner';
import { useLocalStorageState } from 'client/lib/hooks';
import { DownloadCsvButton } from '@components/download-csv-modal';
import { MyCoursesPageWrapper } from './my-courses-page-wrapper';

function TenantMigrationNotice() {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <div
      class={`${
        isVisible ? 'block' : 'hidden'
      } shadow-xl rounded-2xl border p-12 max-w-96 mx-auto my-20 relative space-y-4`}
    >
      <h2 class="text-2xl">Hold tight!</h2>
      <p>
        We're moving your courses to the newest version of our hosting platform. You'll see them
        here soon.
      </p>
      <footer class="mt-4" onClick={() => setIsVisible(false)}>
        <BtnSecondary>Dismiss</BtnSecondary>
      </footer>
    </div>
  );
}

function TabWithCounts(props: { href: string; isSelected: boolean; text: string; count: number }) {
  return (
    <a
      href={props.href}
      class={`font-semibold inline-flex gap-2 p-1 px-2 hover:bg-gray-100 rounded-md ${
        props.isSelected ? 'text-indigo-800 bg-indigo-100' : 'text-inherit'
      }`}
    >
      <span>{props.text}</span>
      <span>
        <span
          class={`${
            props.isSelected ? 'bg-indigo-200 text-inherit' : 'bg-gray-100'
          } hidden rounded-full px-2.5 text-xs font-medium sm:inline-block`}
        >
          {props.count}
        </span>
      </span>
    </a>
  );
}

const TAB_STORAGE_KEY = 'active-courses-tab';

function Page(props: RouteProps<Data>) {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const { courses } = props.state;
  const me = useCurrentUser()!;
  const [lastOpenedTab, setLastOpenedTab] = useLocalStorageState<Show>(TAB_STORAGE_KEY, 'all');
  const show: Show = (props.route.params.show || lastOpenedTab) as Show;

  const [showNewCourseModal, setShowNewCourseModal] = useState<{ isProduct: boolean } | undefined>(
    undefined,
  );
  const [showNewBundleModal, setShowNewBundleModal] = useState(false);
  const [showCopyTemplateModal, setShowCopyTemplateModal] = useState<Template | undefined>(
    undefined,
  );
  const [showNewRibbon, setShowNewRibbon] = useState(!!props.route.params.newRibbon);

  const isStudentOnly = me.level === 'student';
  const count = useMemo(
    () =>
      courses.reduce(
        (acc: { archived: number; teaching: number; attending: number }, c) => {
          if (c.isArchived) {
            acc.archived = (acc.archived || 0) + 1;
          } else if (c.level === 'guide' || c.level === 'facilitator') {
            acc.teaching = (acc.teaching || 0) + 1;
          } else {
            acc.attending = (acc.attending || 0) + 1;
          }
          return acc;
        },
        { archived: 0, teaching: 0, attending: 0 },
      ),
    [courses],
  );

  useEffect(() => {
    return onCourseDeleted(({ courseId }) => {
      props.setState((s) => ({
        ...s,
        courses: s.courses.filter((c) => c.id !== courseId),
      }));
    });
  });

  // Persist tab selection in local storage
  useEffect(() => {
    setLastOpenedTab(show);
  }, [show]);

  // Add hook to check for flashed error messages, display error modal if any exist
  useFlashError();

  const createButtons = (
    <>
      {!isStudentOnly && show !== 'bundles' && <InstantCoursesButton />}
      {!isStudentOnly && show === 'bundles' && (
        <BtnPrimary onClick={() => setShowNewBundleModal(true)} class="px-4">
          {intl('Create a new bundle')}
        </BtnPrimary>
      )}
      {!showNewRibbon && !isStudentOnly && show !== 'bundles' && (
        <BtnPrimary onClick={() => setShowNewRibbon(true)} class="px-4">
          {intl('Create a new course')}
          <IcoChevronDown class="w-4 h-4 opacity-75 ml-2" />
        </BtnPrimary>
      )}
    </>
  );

  return (
    <FixedPage title={intl('My Courses')}>
      <FixedContent class="bg-white">
        <TopBar />
        <ProTierBanner />
        <InvalidPaypalBanner />
        {!isStudentOnly && me.tier === 'free' && <FreeTierBanner class="mb-8" />}
        {!isStudentOnly && (
          <CreateCourseRibbon
            isOpen={showNewRibbon}
            onBlankCourse={setShowNewCourseModal}
            onTemplateCourse={(template) => setShowCopyTemplateModal(template)}
            hide={() => setShowNewRibbon(false)}
          />
        )}
        {tenant.isMigrating && <TenantMigrationNotice />}
        {courses.length === 0 && (
          <EmptyScreen
            title={show === 'bundles' ? intl('No Bundles') : intl('No Courses')}
            subtext={
              isStudentOnly
                ? intl('When you register for a course, it will show up here.')
                : intl(`When you create courses, they'll show up here.`)
            }
            displayBundlesButton={!isStudentOnly}
            onNewCourse={isStudentOnly ? undefined : () => setShowNewRibbon(true)}
          />
        )}
        {courses.length > 0 && (
          <MyCoursesPageWrapper
            tab={show === 'bundles' ? 'bundles' : 'courses'}
            buttons={
              <>
                <div class="space-x-4 md:inline-flex items-center -mt-4 hidden">
                  {createButtons}
                </div>

                <Dropdown
                  hideDownIcon
                  triggerClass="md:hidden"
                  renderMenu={() => (
                    <div class="flex flex-col p-2 pb-0 space-y-2">{createButtons}</div>
                  )}
                >
                  <span class="inline-block rounded-full p-1">
                    <IcoDotsHorizontal class="w-6 h-6" />
                  </span>
                </Dropdown>
              </>
            }
          >
            {!isStudentOnly && show !== 'bundles' && (
              <nav class="space-x-6 mb-8 flex items-center">
                <div class="flex items-center justify-between gap-6">
                  <TabWithCounts
                    href="/courses?show=all"
                    isSelected={show === 'all'}
                    text={intl('All')}
                    count={courses.length}
                  />
                  <TabWithCounts
                    href="/courses?show=teaching"
                    isSelected={show === 'teaching'}
                    text={intl('Teaching')}
                    count={count.teaching}
                  />
                  <TabWithCounts
                    href="/courses?show=attending"
                    isSelected={show === 'attending'}
                    text={intl('Attending')}
                    count={count.attending}
                  />
                  <TabWithCounts
                    href="/courses?show=archived"
                    isSelected={show === 'archived'}
                    text={intl('Archived')}
                    count={count.archived}
                  />
                </div>

                <div class="grow md:flex justify-end hidden">
                  <DownloadCsvButton type="guide" />
                </div>
              </nav>
            )}
            <CourseList
              courses={courses}
              show={show}
              renderItem={({ course, listType, searchTerm, displayDate }) => (
                <CourseItem
                  key={course.id}
                  listType={listType}
                  course={course}
                  highlightTerm={searchTerm}
                  displayDate={displayDate}
                  isArchived={!isStudentOnly && course.isArchived}
                />
              )}
            />
          </MyCoursesPageWrapper>
        )}
        {showNewCourseModal && (
          <CreateCourseModal
            isProduct={showNewCourseModal.isProduct}
            onCancel={() => setShowNewCourseModal(undefined)}
          />
        )}
        {showNewBundleModal && (
          <CreateBundleModal isProduct={false} onCancel={() => setShowNewBundleModal(false)} />
        )}
        {showCopyTemplateModal && (
          <CopyCourseTemplateModal
            course={showCopyTemplateModal}
            onCancel={() => setShowCopyTemplateModal(undefined)}
          />
        )}
      </FixedContent>
    </FixedPage>
  );
}

router.add({ url: 'manage/courses', render: Page, authLevel: 'student', load });
router.add({ url: 'manage', render: Page, authLevel: 'student', load });
router.add({ url: 'courses', render: Page, authLevel: 'student', load });
router.add({ url: 'home', render: Page, authLevel: 'student', load });
router.add({ url: '', render: Page, authLevel: 'student', load });
