/**
 * The common layout for the upsell item and upsell apply to pages.
 */

import { IcoChevronRight } from '@components/icons';
import { ComponentChildren } from 'preact';
import { useRef } from 'preact/hooks';
import { useEsc } from 'client/utils/use-esc';
import { router } from '@components/router';
import { Upsell } from './types';
import { ModalPage } from './modal-page';
import { Button } from '@components/buttons';

type Props = {
  cancelURL: string;
  upsell: Upsell;
  header?: ComponentChildren;
  children: ComponentChildren;
  title: string;
  paneIndex: number;
};

export function WizardSubtitle({ children }: { children: ComponentChildren }) {
  return <h1 class="font-semibold text-2xl">{children}</h1>;
}

export function UpsellWizard(props: Props) {
  const { cancelURL, upsell, header, title, paneIndex, children } = props;
  const prevPaneIndex = useRef(paneIndex);
  const fadeDirection = paneIndex >= prevPaneIndex.current ? 'an-fade-in-left' : 'an-fade-in-right';
  const close = () => router.goto(cancelURL);
  useEsc(close, { escapeOnly: true });
  prevPaneIndex.current = paneIndex;

  return (
    <ModalPage onClose={close}>
      <div
        key={paneIndex}
        class={`flex flex-col gap-6 flex-grow p-4 pt-8 sm:p-10 overflow-auto ${fadeDirection}`}
      >
        <header class="flex flex-col gap-4">
          <h2 class="flex flex-col sm:flex-row sm:items-center sm:gap-2 text-sm text-gray-500">
            <span>{upsell.title}</span>
            <IcoChevronRight class="hidden sm:inline-flex size-4" />
            <span>{title}</span>
          </h2>
          {header}
        </header>
        {children}
      </div>
    </ModalPage>
  );
}

export function WizardProgress({
  paneIndex,
  numPanes,
  gotoPane,
}: {
  paneIndex: number;
  numPanes: number;
  gotoPane(index: number): void;
}) {
  const panes = new Array(numPanes).fill(1);
  return (
    <span class="flex flex-col gap-2">
      <span class="font-semibold">
        Step {paneIndex + 1} of {numPanes}
      </span>
      <span class="flex gap-2">
        {panes.map((_, i) => (
          <Button
            key={i}
            disabled={i > paneIndex}
            onClick={() => gotoPane(i)}
            class={`inline-flex h-2 flex-grow rounded-full ${
              i <= paneIndex ? 'bg-indigo-600 hover:bg-indigo-500' : 'bg-gray-200'
            }`}
          ></Button>
        ))}
      </span>
    </span>
  );
}
