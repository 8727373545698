import { BtnSecondary } from '@components/buttons';
import { rpx } from 'client/lib/rpx-client';
import { useAsyncData } from 'client/lib/hooks';
import { useConfiguration } from '@components/router/session-context';
import { useIntl } from 'shared/intl/use-intl';

interface Props {
  noTooltip?: boolean;
}

const store = rpx.instantCourses;

export function InstantCoursesButton({ noTooltip }: Props) {
  const configuration = useConfiguration();
  const intl = useIntl();
  const { isLoading, data: shouldDisplay } = useAsyncData(async () => {
    // We are always displaying the "instant courses" bundle
    // on the core tenant in the preview mode so no need to fire a request here.
    if (configuration.tenant.isCore) {
      return Promise.resolve(true);
    }
    const { isUnlimited, credits } = await store.getInstantCourseCredits();
    return isUnlimited || credits > 0;
  }, []);

  // Do not display this is until loaded
  // or when there are not available bundles for the user
  if (isLoading || !shouldDisplay) {
    return null;
  }

  return (
    <BtnSecondary
      data-tooltip={noTooltip ? '' : 'Access 30+ done-for-you courses'}
      href="/instant-courses"
    >
      {intl('Browse Instant Courses')}
    </BtnSecondary>
  );
}
