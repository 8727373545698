import { useEffect, useState, useRef } from 'preact/hooks';
import { BtnSecondary, Button } from '@components/buttons';
import { IcoTrash, IcoX, IcoSoundHigh, IcoSoundOff } from '@components/icons';
import { UserProfileIcon } from '@components/avatars';
import { Case } from '@components/conditional';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { Course, User } from 'server/types';
import { useAsyncData } from 'client/lib/hooks';
import { generateUUID } from 'shared/utils';
import { useCurrentUser } from '@components/router/session-context';
import dayjs from 'dayjs';
import { SingleAttachment } from '@components/attachments';
import { playNotificationSound } from './helpers';
import { useIntl } from 'shared/intl/use-intl';
import { showError } from '@components/app-error';
import { ChatMessageEditor, NewChatMessage } from './chat-message-editor';
import { ChatScrollContainer } from './chat-scroll-container';
import { DefaultSpinner } from '@components/spinner';
import { URLS } from 'shared/urls';
import { router } from '@components/router';
import { invokeNewChatRoomEvent } from './events';
import { showAttachmentModal, showConfirmModal } from '@components/modal-form';
import { isImage } from 'shared/media';

interface Props {
  room?: {
    id?: UUID;
    isMuted: boolean;
  };
  toUser: Pick<User, 'id' | 'name'>;
  course: Pick<Course, 'id' | 'title'> & {
    guideId: UUID;
  };
  shouldFocus: boolean;
  isExpanded: boolean;
  isMobile: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  onClose: () => void;
}

const store = rpx.chats;

type Feed = RpxResponse<typeof store.getRoomFeed>;
type Message = Feed['messages'][0];

const PAGE_SIZE = 20;

export function ChatRoom(props: Props) {
  const intl = useIntl();
  const { toUser, course, shouldFocus, isExpanded, setIsExpanded, onClose } = props;
  const currentUser = useCurrentUser()!;

  const [unreadCount, setUnreadCount] = useState(0);
  const [room, setRoom] = useState(props.room);
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [noAccess, setNoAccess] = useState(false);
  const [feed, setFeed] = useState<Feed>({
    messages: [],
    cursor: '',
    hasMore: false,
  });
  const [editorHeight, setEditorHeight] = useState(114);
  const lastIncomingMessageDate = useRef<Date>(new Date());
  const today = dayjs();

  const { isLoading } = useAsyncData(async () => {
    if (!room?.id) {
      return;
    }

    try {
      const result = await store.getRoomFeed({
        roomId: room.id,
        cursor,
        limit: PAGE_SIZE,
      });
      setFeed((f) => ({
        cursor: result.cursor,
        messages: cursor ? [...result.messages, ...f.messages] : result.messages,
        hasMore: result.hasMore,
      }));
    } catch (err) {
      if (err.statusCode === 401) {
        setNoAccess(true);
      } else {
        showError(err);
      }
    }
  }, [cursor]);

  // Fetches incoming messages
  useEffect(() => {
    let timeout: NodeJS.Timeout;

    async function fetchData() {
      if (!room?.id) {
        return;
      }

      if (timeout) {
        clearTimeout(timeout);
      }

      const REFRESH_INTERVAL = isExpanded ? 5000 : 15000;

      try {
        const newMessages = await store.getNewMessages({
          roomId: room.id,
          lastMessageDate: lastIncomingMessageDate.current.toISOString(),
          saveLastRead: isExpanded,
        });
        if (newMessages.length > 0) {
          playNotificationSound();
          setFeed((f) => ({
            ...f,
            messages: [...f.messages, ...newMessages],
          }));
          setUnreadCount((c) => c + newMessages.length);
        }
        lastIncomingMessageDate.current = new Date();
        timeout = setTimeout(fetchData, REFRESH_INTERVAL);
      } catch (e) {
        console.error('Chat room fetch failed', e);
        // Fetch again if it is not the unauthorized error
        if (e.statusCode !== 401) {
          timeout = setTimeout(fetchData, REFRESH_INTERVAL);
        }
      }
    }

    fetchData();

    return () => clearTimeout(timeout);
  }, [room?.id, isExpanded]);

  async function sendMessage(message: NewChatMessage) {
    let toRoomId = room?.id;
    if (!toRoomId) {
      try {
        const newRoomId = await store.createRoom({
          courseId: course.id,
          toUserId: toUser.id,
        });
        setRoom({
          id: newRoomId,
          isMuted: false,
        });
        toRoomId = newRoomId;

        /*
         * This component could be started without a room id, in which case it will
         * create a new room. This callback will be called when the room is created.
         */
        invokeNewChatRoomEvent({
          roomId: newRoomId,
          user: toUser,
          courseId: course.id,
          message: {
            content: message.content,
            createdAt: new Date(),
          },
        });
      } catch (e) {
        showError(e);
        return;
      }
    }

    setFeed((f) => ({
      ...f,
      messages: [
        ...f.messages,
        {
          id: generateUUID(),
          content: message.content,
          userId: currentUser.id,
          attachment: message.attachment,
          createdAt: new Date().toISOString(),
        },
      ],
    }));

    try {
      await store.sendMessage({
        roomId: toRoomId,
        content: message.content,
        attachment: message.attachment
          ? {
              id: message.attachment.id,
              path: message.attachment.path,
            }
          : undefined,
      });
    } catch (e) {
      setFeed((f) => ({
        ...f,
        // Delete the message we just added
        messages: f.messages.slice(0, -1),
      }));
      throw e;
    }
  }

  async function deleteMessage(message: Message) {
    if (!room?.id) {
      return;
    }

    const isConfirmed = await showConfirmModal({
      title: intl('Delete message'),
      body: intl('Are you sure you want to delete this message?'),
    });

    if (!isConfirmed) {
      return;
    }

    try {
      await store.deleteMessage({
        roomId: room.id,
        messageId: message.id,
      });
      setFeed((f) => ({
        ...f,
        messages: f.messages.filter((m) => m.id !== message.id),
      }));
    } catch (err) {
      showError(err);
    }
  }

  return (
    <div
      class={`${
        isExpanded ? 'w-full md:w-lg' : 'w-60'
      } h-[calc(100dvh)] md:h-auto fixed md:relative inset-0 md:inset-auto min-w-0 mr-4 border mg:rounded-t-lg bg-white`}
    >
      <Button
        class="flex items-center p-2 bg-gray-50 hover:bg-gray-100 w-full border-b mg:rounded-t-lg"
        disabled={props.isMobile}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <UserProfileIcon size="w-8 h-8 mr-2" user={toUser} />
        <div class="text-sm grow text-left">
          <h2 class="font-semibold line-clamp-1">
            {toUser.name}
            <Case when={unreadCount > 0}>
              <span class="inline-flex items-center ml-2 justify-center rounded-full text-xs font-semibold leading-4 bg-red-500 text-white dark:border-none w-8 h-8">
                {unreadCount}
              </span>
            </Case>
          </h2>
          {isExpanded && (
            <RecipientInfo course={course} toUser={toUser} isMobile={props.isMobile} />
          )}
        </div>
        {!!room?.id && (
          <Button
            class="p-2 hover:bg-gray-200 rounded-full"
            title={room.isMuted ? intl('Unmute notifications') : intl('Mute notifications')}
            onClick={async (e) => {
              e.stopPropagation();
              setRoom((r) => ({
                ...r,
                isMuted: !r?.isMuted,
              }));
              await store.toggleRoomNotifications({
                roomId: room.id!,
                isMuted: !room.isMuted,
              });
            }}
          >
            {room.isMuted ? (
              <IcoSoundOff class="w-5 h-5 opacity-40" />
            ) : (
              <IcoSoundHigh class="w-5 h-5" />
            )}
          </Button>
        )}
        <Button
          class="p-2 hover:bg-gray-200 rounded-full"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <IcoX class="w-5 h-5" />
        </Button>
      </Button>
      <Case when={isExpanded}>
        <div class="bg-white an-slide-up">
          <ChatScrollContainer
            latestMessageId={feed.messages[feed.messages.length - 1]?.id}
            editorHeight={editorHeight}
            onScrollToBottom={() => setUnreadCount(0)}
          >
            <Case
              when={!noAccess}
              fallback={
                <div class="flex h-full items-center justify-center">
                  <p>{intl('You do not have access to this room anymore.')}</p>
                </div>
              }
            >
              {feed.hasMore && (
                <div class="flex justify-center mb-2">
                  <BtnSecondary isLoading={isLoading} onClick={() => setCursor(feed.cursor)}>
                    {intl('Load Earlier Messages')}
                  </BtnSecondary>
                </div>
              )}
              <Case
                when={feed.messages.length > 0}
                fallback={
                  <div class="flex h-full items-end justify-center">
                    <Case when={!isLoading} fallback={<DefaultSpinner />}>
                      <span class="text-gray-900">{intl('No messages yet.')}</span>
                    </Case>
                  </div>
                }
              >
                {feed.messages.map((message, index) => {
                  const lastMessage = feed.messages[index - 1];
                  const day = dayjs(message.createdAt).startOf('day');
                  const isNewDay = !lastMessage || !dayjs(lastMessage.createdAt).isSame(day, 'day');
                  const diff = today.diff(day, 'days');

                  let dayText = '';
                  if (isNewDay) {
                    if (diff > 365) {
                      dayText = day.format('MMM D, YYYY');
                    } else if (diff > 7) {
                      dayText = day.format('ddd, MMM D');
                    } else if (diff === 0) {
                      dayText = intl('Today');
                    } else if (diff === 1) {
                      dayText = intl('Yesterday');
                    } else {
                      dayText = day.format('dddd');
                    }
                  }

                  return (
                    <>
                      {isNewDay && (
                        <div key={today.toString()} class="flex items-center justify-center mb-2 ">
                          <div class="p-2 text-xs text-gray-500 font-semibold">{dayText}</div>
                        </div>
                      )}
                      <MessageItem
                        key={message.id}
                        message={message}
                        isMobile={props.isMobile}
                        isMine={message.userId === currentUser.id}
                        onDelete={deleteMessage}
                      />
                    </>
                  );
                })}
              </Case>
            </Case>
          </ChatScrollContainer>
          {!noAccess && (
            <ChatMessageEditor
              sendMessage={sendMessage}
              shouldFocus={shouldFocus}
              isMobile={props.isMobile}
              onHeightChange={setEditorHeight}
            />
          )}
        </div>
      </Case>
    </div>
  );
}

function RecipientInfo({
  toUser,
  course,
  isMobile,
}: {
  toUser: Props['toUser'];
  course: Props['course'];
  isMobile: boolean;
}) {
  const intl = useIntl();

  let parts: string[] = [];

  if (toUser.id === course.guideId) {
    parts = intl.split('Guide in <>{courseName:string}</>.', {
      courseName: course.title,
    });
  } else {
    parts = intl.split('Student in <>{courseName:string}</>.', {
      courseName: course.title,
    });
  }

  return (
    <p class="text-xs line-clamp-2">
      {parts[0]}
      {!isMobile && (
        <Button
          class="text-indigo-600"
          onClick={(e) => {
            e.stopPropagation();
            router.goto(
              URLS.student.course({
                course: {
                  id: course.id,
                  title: course.title,
                },
              }),
            );
          }}
        >
          {parts[1]}
        </Button>
      )}
      {isMobile && parts[1]}
      {parts[2]}
    </p>
  );
}

function LinkedMessage({ message, isMine }: { message: string; isMine: boolean }) {
  const urlRegex = /((?:https?:\/\/|www\.)[^\s]+)/g;
  if (!urlRegex.test(message)) {
    return <span>{message}</span>;
  }

  return (
    <span>
      {message.split(urlRegex).map((s, i) =>
        i % 2 ? (
          <a
            key={`a-${i}`}
            href={s}
            target="_blank"
            rel="noopener noreferrer"
            class={`${isMine ? 'text-white' : 'text-indigo-500'} underline`}
          >
            {s}
          </a>
        ) : (
          <span key={`span-${i}`}>{s}</span>
        ),
      )}
    </span>
  );
}

function MessageItem({
  message,
  isMine,
  isMobile,
  onDelete,
}: {
  message: Message;
  isMine: boolean;
  isMobile: boolean;
  onDelete: (message: Message) => void;
}) {
  const { content } = message;
  // Emojis are 2 characters long,
  // so if the length is 2 and it includes an emoji
  // it's an emoji only message.
  const isEmojiOnly = content.length === 2 && /\p{Emoji}/u.test(content);

  return (
    <div class={`flex pb-2 rounded-lg ${isMine ? 'justify-end' : ''} group`}>
      <div
        class={`w-5/6 relative p-2 px-4 rounded-2xl whitespace-break-spaces ${
          isMine ? 'bg-blue-500 text-white items-end' : 'bg-gray-100 text-black'
        } ${isEmojiOnly ? 'text-4xl' : 'text-sm'}`}
      >
        {message.attachment && (
          <SingleAttachment
            attachments={[message.attachment]}
            onClick={(attachment) => {
              if (!isMobile && isImage(attachment.type)) {
                showAttachmentModal({
                  attachment,
                });
              }
            }}
          />
        )}
        <LinkedMessage message={content} isMine={isMine} />
        <span
          class={`float-right inline-block pt-0.5 text-xs opacity-50 ${
            isMine ? 'group-hover:invisible' : ''
          }`}
        >
          {dayjs(message.createdAt).format('h:mm A')}
        </span>
        {isMine && (
          <Button
            class="absolute bottom-3 right-3 hidden group-hover:block text-white"
            onClick={() => onDelete(message)}
          >
            <IcoTrash class="w-4 h-4 opacity-80" />
          </Button>
        )}
      </div>
    </div>
  );
}
