import {
  IcoArchive,
  IcoBook,
  IcoCalendar,
  IcoChat,
  IcoCheckBadge,
  IcoCreditCard,
  IcoDuplicate,
  IcoHome,
  IcoList,
  IcoMail,
  IcoPencil,
  IcoPresentation,
  IcoReply,
  IcoSettings,
  IcoSlides,
  IcoTag,
  IcoTrash,
  IcoUsers,
  IcoVideoCamera,
} from '@components/icons';
import { ComponentChildren } from 'preact';
import { FullCourse, User } from 'server/types';
import { Command, CommandType, PaletteState, useCommandPalette } from './command-palette';
import { CourseEnrollmentToggle } from '@components/course-enrollment-status';

import { toggleCourseArchivedState } from '@components/course-archived-status';
import { showCourseTitleModal } from './course-title-modal';
import { showCopyCourseModal, showDeleteCourseModal } from '@components/course-action-modals';
import { UserProfileIcon } from '@components/avatars';
import { useCurrentTenant } from '@components/router/session-context';
import { courseLabel } from 'shared/terminology';
import { rpx } from 'client/lib/rpx-client';
import { URLS } from 'shared/urls';
import { genericDiscussionCategoryIds } from 'shared/consts';
import { useIntl } from 'shared/intl/use-intl';
import { useState } from 'preact/hooks';

interface Props {
  user?: Pick<User, 'id'>;
  course: Pick<
    FullCourse,
    | 'id'
    | 'title'
    | 'accessFormat'
    | 'isAbsoluteSchedule'
    | 'status'
    | 'hidePeople'
    | 'isBundle'
    | 'isProduct'
    | 'isArchived'
    | 'level'
    | 'numStudents'
  >;
  listenCtrlKey?: boolean;
  onCourseDelete?: () => void;
}

function CmdIcon({ children, color }: { children: ComponentChildren; color: string }) {
  return (
    <span class={`w-8 h-8 min-w-8 rounded-md ${color} inline-flex items-center justify-center`}>
      {children}
    </span>
  );
}

export function useCourseGuideMenu({ course, listenCtrlKey = true, onCourseDelete }: Props) {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const { terminology } = tenant;
  const terminologyCourse = courseLabel({
    course,
    tenant,
  });
  const [state, setState] = useState<PaletteState>({
    term: '',
    type: undefined,
  });

  return useCommandPalette(() => {
    const mkhref = (s: string) =>
      `/manage/${course.isProduct ? 'products' : 'courses'}/${course.id}/${s}`;

    const menuType: CommandType = 'menu';
    const staticGuideMenu = [
      // Course / content
      course.level !== 'guide'
        ? undefined
        : {
            id: 'dashboard',
            type: menuType,
            subtype: 'Content',
            title: `${terminologyCourse} Dashboard`,
            keywords: 'dashboard overview homepage',
            href: mkhref(''),
            availableForBundles: true,
            icon() {
              return (
                <CmdIcon color="bg-green-100 text-green-600">
                  <IcoHome />
                </CmdIcon>
              );
            },
          },
      {
        id: 'modules',
        type: menuType,
        subtype: 'Content',
        title: course.isProduct ? `Content` : `${terminology.Modules} & ${terminology.Lessons}`,
        keywords: 'module module lesson edit quiz poll survey assignment',
        href: mkhref(course.isProduct ? 'content' : 'lessons'),
        icon() {
          return (
            <CmdIcon color="bg-indigo-100 text-indigo-600">
              <IcoBook />
            </CmdIcon>
          );
        },
      },
      !course.isProduct && {
        id: 'calendar',
        type: menuType,
        subtype: 'Content',
        title: 'Calendar',
        keywords: 'schedule calendar',
        href: mkhref('calendar'),
        icon() {
          return (
            <CmdIcon color="bg-cyan-100 text-cyan-600">
              <IcoCalendar />
            </CmdIcon>
          );
        },
      },
      {
        id: 'style',
        type: menuType,
        subtype: 'Content',
        title: 'Style',
        keywords: 'appearance style theme',
        href: mkhref('style'),
        availableForBundles: true,
        icon() {
          return (
            <CmdIcon color="bg-pink-50 text-pink-400">
              <IcoPencil />
            </CmdIcon>
          );
        },
      },
      !course.isProduct && {
        id: 'messages',
        type: menuType,
        subtype: 'Content',
        title: 'Messages',
        keywords: 'email message notification',
        href: mkhref('messages/welcome'),
        availableForBundles: true,
        icon() {
          return (
            <CmdIcon color="bg-green-100 text-green-600">
              <IcoReply />
            </CmdIcon>
          );
        },
      },
      !course.isProduct && {
        id: 'meetings',
        type: menuType,
        subtype: 'Content',
        title: tenant.terminology.meetings,
        keywords: 'webinar zoom meeting slack chat conference video skype',
        href: mkhref('meetings'),
        icon() {
          return (
            <CmdIcon color="bg-violet-100 text-violet-600">
              <IcoVideoCamera />
            </CmdIcon>
          );
        },
      },
      course.isProduct || tenant.isCore
        ? undefined
        : {
            id: 'cert',
            type: menuType,
            subtype: 'Content',
            title: 'Certificate',
            // Using complet here, rather than complete, copmletion, etc, so
            // that we get a fuzzier match for various suffixes.
            keywords: 'certificate complet',
            href: mkhref('certificate'),
            icon() {
              return (
                <CmdIcon color="bg-cyan-100 text-cyan-600">
                  <IcoCheckBadge />
                </CmdIcon>
              );
            },
          },

      // Sales and registration
      course.level !== 'guide'
        ? undefined
        : {
            id: 'price',
            type: menuType,
            subtype: 'Sales & Signups',
            title: 'Price Points',
            keywords: 'pay price point sale stripe charge link links',
            href: mkhref('prices'),
            availableForBundles: true,
            icon() {
              return (
                <CmdIcon color="bg-violet-100 text-violet-600">
                  <IcoCreditCard />
                </CmdIcon>
              );
            },
          },
      course.level !== 'guide'
        ? undefined
        : {
            id: 'coupons',
            type: menuType,
            subtype: 'Sales & Signups',
            title: 'Coupons',
            keywords: 'coupon discount code',
            href: mkhref('coupons'),
            availableForBundles: true,
            icon() {
              return (
                <CmdIcon color="bg-green-100 text-green-600">
                  <IcoTag />
                </CmdIcon>
              );
            },
          },
      {
        id: 'sales',
        type: menuType,
        subtype: 'Sales & Signups',
        title: 'Sales page',
        keywords: 'sales page market',
        href: mkhref('salespage'),
        availableForBundles: true,
        icon() {
          return (
            <CmdIcon color="bg-cyan-100 text-cyan-600">
              <IcoSlides />
            </CmdIcon>
          );
        },
      },
      !course.isProduct && {
        id: 'invitations',
        type: menuType,
        subtype: 'Sales & Signups',
        title: 'Invitations',
        keywords: 'invit',
        href: mkhref('invitations'),
        availableForBundles: true,
        icon() {
          return (
            <CmdIcon color="bg-blue-100 text-blue-600">
              <IcoMail />
            </CmdIcon>
          );
        },
      },
      course.isProduct || tenant.isCore
        ? undefined
        : {
            id: 'profile',
            type: menuType,
            subtype: 'Sales & Signups',
            title: 'Profile fields',
            keywords: 'profile field custom data',
            href: mkhref('profile-fields'),
            icon() {
              return (
                <CmdIcon color="bg-gray-100 text-gray-600">
                  <IcoList />
                </CmdIcon>
              );
            },
          },

      // Student support
      {
        id: 'students',
        type: menuType,
        subtype: 'Student Support',
        title: course.isProduct ? 'Customers' : 'Students',
        keywords: 'student customer member learner signup enroll refund report gifts',
        href: mkhref('students'),
        availableForBundles: true,
        icon() {
          return (
            <CmdIcon color="bg-indigo-100 text-indigo-600">
              <IcoUsers />
            </CmdIcon>
          );
        },
      },
      !course.isProduct && {
        id: 'assignments',
        type: menuType,
        subtype: 'Student Support',
        title: 'Review Assessments',
        keywords: 'review assessment assignment quiz poll survey result',
        href: mkhref('assessments'),
        icon() {
          return (
            <CmdIcon color="bg-green-100 text-green-600">
              <IcoPresentation />
            </CmdIcon>
          );
        },
      },
      !course.isProduct && {
        id: 'discussions',
        type: menuType,
        subtype: 'Student Support',
        title: 'Discussion Categories',
        keywords: 'discussions forum chat category categories',
        href: mkhref('discussions'),
        icon() {
          return (
            <CmdIcon color="bg-cyan-100 text-cyan-600">
              <IcoChat />
            </CmdIcon>
          );
        },
      },

      // Actions
      {
        id: 'settings',
        type: menuType,
        subtype: 'Actions',
        title: `${terminologyCourse} Settings`,
        keywords:
          'settings preferences enrollment signup signups email student notification change course type open access on demand schedule drip people page limits facilitators collaborators privacy community chat',
        href: mkhref('settings'),
        icon() {
          return (
            <CmdIcon color="bg-gray-100 text-gray-600">
              <IcoSettings />
            </CmdIcon>
          );
        },
      },
      {
        id: 'renamecourse',
        type: menuType,
        subtype: 'Actions',
        title: `Rename ${terminologyCourse}`,
        keywords: `rename ${terminologyCourse} change title edit`,
        availableForBundles: true,
        onClick() {
          showCourseTitleModal(course);
        },
        icon() {
          return (
            <CmdIcon color="bg-cyan-100 text-cyan-600">
              <IcoPencil />
            </CmdIcon>
          );
        },
      },
      {
        id: 'copycourse',
        type: menuType,
        subtype: 'Actions',
        title: `Copy ${terminologyCourse}`,
        keywords: 'copy course duplicate',
        availableForBundles: true,
        onClick() {
          showCopyCourseModal(course);
        },
        icon() {
          return (
            <CmdIcon color="bg-green-100 text-green-600">
              <IcoDuplicate />
            </CmdIcon>
          );
        },
      },
      {
        id: 'archivecourse',
        type: menuType,
        subtype: 'Actions',
        title: course.isArchived
          ? `Unarchive ${terminologyCourse}`
          : `Archive ${terminologyCourse}`,
        keywords: `archive ${terminologyCourse} hide remove delete unarchive`,
        availableForBundles: true,
        onClick() {
          toggleCourseArchivedState({ course });
        },
        icon() {
          return (
            <CmdIcon color="bg-violet-100 text-violet-600">
              <IcoArchive />
            </CmdIcon>
          );
        },
      },
      course.numStudents
        ? undefined
        : {
            id: 'deletecourse',
            type: menuType,
            subtype: 'Actions',
            title: `Delete ${terminologyCourse}`,
            keywords: `delete ${terminologyCourse} remove`,
            availableForBundles: true,
            onClick() {
              showDeleteCourseModal(course, onCourseDelete);
            },
            icon() {
              return (
                <CmdIcon color="bg-red-100 text-red-600">
                  <IcoTrash />
                </CmdIcon>
              );
            },
          },
    ];

    return {
      course,
      state,
      setState,
      isGuideSearch: true,
      types:
        course.isProduct || course.isBundle
          ? []
          : ['menu', 'module', 'lesson', 'meeting', 'discussion', 'student'],
      footerActions: <CourseEnrollmentToggle course={course} />,
      staticGuideMenu: course.isBundle
        ? staticGuideMenu.filter((c) => c && c.availableForBundles)
        : staticGuideMenu,
      async search(term, type) {
        const data = await rpx.search.getCourseSearch({
          courseId: course.id,
          term: encodeURIComponent(term),
          type,
        });
        const results = {
          discussions: {
            totalHits: 0,
            items: [],
          },
          meetings: {
            totalHits: 0,
            items: [],
          },
          lessons: {
            totalHits: 0,
            items: [],
          },
          modules: {
            totalHits: 0,
            items: [],
          },
          students: {
            totalHits: 0,
            items: [],
          },
          ...data,
        };

        const items = [
          ...(course.isProduct
            ? []
            : results.modules.items.map<Command>((m) => ({
                id: m.id,
                title: m.title || '',
                type: 'module',
                keywords: '',
                href: mkhref(`modules/${m.id}`),
                icon() {
                  return (
                    <CmdIcon color="bg-indigo-200 text-indigo-600">
                      <IcoBook />
                    </CmdIcon>
                  );
                },
              }))),
          ...results.lessons.items.map<Command>((l) => ({
            id: l.id,
            title: l.title || '',
            subtitle: l.content,
            type: 'lesson',
            keywords: '',
            href: mkhref(`${course.isProduct ? 'content' : 'lessons'}/${l.id}`),
            icon() {
              return (
                <CmdIcon color="bg-indigo-200 text-indigo-600">
                  <IcoBook />
                </CmdIcon>
              );
            },
          })),
          ...(course.isProduct
            ? []
            : results.meetings.items.map<Command>((m) => ({
                id: m.id,
                title: m.title,
                type: 'meeting',
                keywords: '',
                href: mkhref(`meetings/${m.id}`),
                icon() {
                  return (
                    <CmdIcon color="bg-violet-200 text-violet-600">
                      <IcoVideoCamera />
                    </CmdIcon>
                  );
                },
              }))),
          ...(course.isProduct
            ? []
            : results.discussions.items.map<Command>((d) => ({
                id: d.id,
                title: d.prompt,
                type: 'discussion',
                keywords: '',
                subtitle: intl('Started by {author:string}', {
                  author: d.author,
                }),
                href: URLS.student.discussion({
                  course,
                  discussionId: d.id,
                  categoryId: d.categoryId || genericDiscussionCategoryIds.all,
                }),
                icon() {
                  return (
                    <CmdIcon color="bg-green-100 text-green-800">
                      <IcoChat />
                    </CmdIcon>
                  );
                },
              }))),
          ...results.students.items.map<Command>((s) => ({
            id: s.userId,
            title: s.name || '',
            type: 'student',
            subtitle: s.email,
            keywords: '',
            href: mkhref(`${course.isProduct ? 'customers' : 'students'}/${s.userId}`),
            availableForBundles: true,
            icon() {
              return <UserProfileIcon user={s} size="w-8 h-8" />;
            },
          })),
        ];

        return {
          totalHits: {
            student: results.students.totalHits,
            module: results.modules.totalHits,
            lesson: results.lessons.totalHits,
            meeting: results.meetings.totalHits,
            discussion: results.discussions.totalHits,
          },
          items,
        };
      },
    };
  }, listenCtrlKey);
}
