/**
 * Helper components and building blocks for building sections which support
 * viewing and editing.
 */

import { showError } from '@components/app-error';
import { AutosizeText, AutosizeProps } from '@components/autosize-text';
import { Button } from '@components/buttons';
import { IcoUser } from '@components/icons';
import { filepicker } from 'client/lib/filepicker';
import { useImageUrl } from 'client/utils/cdn';
import { useMemo } from 'preact/hooks';
import { RichTextEditor, RichTextViewer, ToolbarPosition } from './rich-text';
import { EditorProps } from './types';

export interface FlexProps {
  path: string | Array<string | number>;
  class?: string;
  isSelected?: boolean;
  placeholder?: string;
  state: any;
  setState?: EditorProps<any>['setState'];
}

export function getProp(props: FlexProps) {
  if (typeof props.path === 'string') {
    return props.path ? props.state[props.path] : props.state;
  }
  return props.path.reduce((acc, k) => {
    return acc[k];
  }, props.state);
}

export function setProp(props: FlexProps, val: any) {
  if (!props.setState) {
    return;
  }
  const applyStateProp = (state: any) => (typeof val === 'function' ? val(state) : val);
  if (!props.path) {
    props.setState((s: any) => ({ ...s, ...applyStateProp(val) }));
    return;
  }
  const path = typeof props.path === 'string' ? [props.path] : props.path;
  const assoc = (obj: any, [k, ...path]: any[]) => {
    let next = obj[k];
    if (Array.isArray(next)) {
      next = [...next];
    } else if (next && typeof next === 'object') {
      next = { ...next };
    } else {
      next = val;
    }
    if (path.length) {
      obj[k] = next;
      assoc(next, path);
    } else {
      obj[k] = applyStateProp(next);
    }
    return obj;
  };

  props.setState((s: any) => assoc({ ...s }, path));
}

export function RichProp(props: FlexProps & { toolbarPosition?: ToolbarPosition }) {
  const readonly = !props.setState;
  const doc = getProp(props);
  if (readonly) {
    return <RichTextViewer doc={doc} class={props.class} />;
  }
  return (
    <RichTextEditor
      doc={doc}
      toolbarPosition={props.toolbarPosition}
      class={props.class}
      onChange={(val) => setProp(props, val)}
      isSelected={props.isSelected}
    />
  );
}

function setQueryParam(url: undefined | string, param: string, val: string) {
  if (!url) {
    return;
  }
  const u = new URL(url, window.location.origin);
  u.searchParams.set(param, val);
  return u.toString();
}

function NoPic(props: { class?: string }) {
  return (
    <span class={`${props.class} overflow-hidden`}>
      <IcoUser class="w-1/2 h-1/2" />
    </span>
  );
}

export function ImgProp(
  props: FlexProps & {
    wrapperClass?: string;
    width?: string;
    allowDelete?: boolean;
    placeholder?: string;
  },
) {
  const readonly = !props.setState;
  const rawVal = useImageUrl(getProp(props));
  const val = useMemo(() => setQueryParam(rawVal, 'width', props.width || '600'), [rawVal]);

  if (readonly) {
    return (
      <span class={props.wrapperClass}>
        {val ? (
          <img class={props.class} src={val} onError={() => setProp(props, props.placeholder)} />
        ) : (
          <NoPic class={props.class} />
        )}
      </span>
    );
  }

  async function pickPhoto() {
    try {
      const result = await filepicker({
        accept: 'image/*',
        cropRatio: 1,
        requireCrop: true,
        sources: ['filepicker', 'takephoto'],
      });

      if (result) {
        setProp(props, result.publicUrl);
      }
    } catch (err) {
      showError(err);
    }
  }

  if (!val) {
    return (
      <label class={`relative cursor-pointer ${props.wrapperClass || ''}`}>
        <input
          type="image"
          class="absolute opacity-0 text-transparent"
          alt="Photo"
          onClick={pickPhoto}
          src={val}
        />
        <NoPic class={props.class} />
      </label>
    );
  }

  return (
    <Button
      class={`relative cursor-pointer ${props.wrapperClass || ''}`}
      onClick={() => setProp(props, '')}
    >
      <img class={props.class} src={val} onError={() => setProp(props, props.placeholder)} />
      <span class="absolute top-0 h-full w-full bg-red-600 opacity-0 hover:opacity-90 text-white rounded-full flex items-center justify-center transition-all text-xs">
        Remove
        <br />
        Photo
      </span>
    </Button>
  );
}

export function MultilineProp(props: FlexProps & { inline?: boolean } & AutosizeProps) {
  const readonly = !props.setState;
  const val = getProp(props);
  if (readonly) {
    return <>{val}</>;
  }
  return (
    <AutosizeText
      {...props}
      placeholder={props.placeholder || 'Enter text'}
      containerClass={`text-inherit inline-block ${props.inline ? 'min-w-1' : 'w-full'}`}
      class={`border-transparent placeholder-inherit rounded border p-0 text-inherit break-normal inline-block ${
        props.class || ''
      }`}
      onInput={(e: any) => setProp(props, e.target.value)}
      value={val}
    />
  );
}
