import { rpx } from 'client/lib/rpx-client';
import { UserProfileIcon } from '@components/avatars';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { showError } from '@components/app-error';
import { IcoChevronLeft } from '@components/icons';
import { showModalForm } from '@components/modal-form';
import { useState } from 'preact/hooks';
import { Modal, ModalContent, ModalFooter, ModalTitle } from '@components/modal';
import { showToast } from '@components/toaster';
import { TenantUsersList } from '../admin-people/user-list';
import { User } from 'server/types';

export function showMergeStudentsModal(fromUser: Pick<User, 'id' | 'name'>) {
  showModalForm(({ resolve }) => {
    const [baseUser, setBaseUser] = useState<{ id: UUID; name?: string } | undefined>(undefined);
    const [isMerging, setIsMerging] = useState(false);

    const mergeStudents = async () => {
      if (!baseUser) {
        return;
      }
      setIsMerging(true);
      try {
        await rpx.admin.mergeStudentAccounts({
          base: baseUser.id,
          from: fromUser.id,
        });
        showToast({
          title: 'Merge succeeded',
          message: `Merged ${fromUser.name} into ${baseUser.name}`,
          type: 'ok',
        });
        resolve(true);
      } catch (err) {
        showError(err);
      } finally {
        setIsMerging(false);
      }
    };

    return (
      <Modal isOpen size="max-w-3xl" onCancel={() => resolve(undefined)}>
        {baseUser && (
          <>
            <ModalTitle>
              <Button class="inline-flex items-center" onClick={() => setBaseUser(undefined)}>
                <IcoChevronLeft class="w-4 h-4 opacity-75 mr-1" />
                Back
              </Button>
            </ModalTitle>
            <ModalContent mode="none">
              Do you want to merge <em>{fromUser.name}</em> into <strong>{baseUser.name}</strong>?
              This action is cannot be undone.
            </ModalContent>
            <ModalFooter>
              <BtnSecondary onClick={resolve}>Cancel</BtnSecondary>
              <BtnPrimary onClick={mergeStudents} disabled={isMerging}>
                Yes, merge students
              </BtnPrimary>
            </ModalFooter>
          </>
        )}
        {!baseUser && (
          <>
            <ModalTitle>Choose a base user to merge {fromUser.name} into</ModalTitle>
            <TenantUsersList
              type="all"
              userLevels={['student', 'guide']}
              tableHeaders={['New Guide', 'ID']}
              onSelect={setBaseUser}
              render={({ user, href }) => (
                <a
                  class="table-row text-inherit hover:bg-indigo-50 text-sm"
                  href={`#${href}`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setBaseUser(user);
                  }}
                >
                  <span class="table-cell p-4 border-t">
                    <span class="flex items-center">
                      <UserProfileIcon user={user} size="w-10 h-10" />
                      <span class="flex flex-col ml-4">
                        <strong>{user.name}</strong>
                        <span>{user.email}</span>
                      </span>
                    </span>
                  </span>
                  <span class="table-cell p-4 border-t">
                    <span class="flex flex-col">
                      <span>{user.id}</span>
                    </span>
                  </span>
                </a>
              )}
            />
            <ModalFooter>
              <BtnSecondary onClick={resolve}>Cancel</BtnSecondary>
            </ModalFooter>
          </>
        )}
      </Modal>
    );
  });
}
