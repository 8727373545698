import { showModalForm } from '@components/modal-form';
import type { Upsell, Offer } from './types';
import { useState } from 'preact/hooks';
import * as pmtmath from 'shared/payments/math';
import * as fmt from 'shared/payments/fmt';
import { IcoArrowLeft, IcoCart } from '@components/icons';
import { Currency } from 'server/types';
import { useEsc } from 'client/utils/use-esc';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { BtnPrimary } from '@components/buttons';
import { Toggle } from '@components/toggle';
import { CourseAndPricePreview } from './course-price-preview';

export function showUpsellPreviewModal(upsell: Upsell) {
  return showModalForm(({ resolve }) => {
    const [offerIndex, setOfferIndex] = useState(0);
    const [cartItems, setCartItems] = useState<Offer[]>([]);
    const offer = upsell.offers[offerIndex];
    const isLast = offerIndex === upsell.offers.length - 1;
    const isTotals = offerIndex >= upsell.offers.length;
    const moveNext = () => {
      if ((isLast && !cartItems.length) || isTotals) {
        resolve();
      } else {
        setOfferIndex(offerIndex + 1);
      }
    };
    const dueToday = cartItems.reduce<{ amount: number; currency: Currency }>(
      (acc, item) => {
        acc.amount += pmtmath.initialPrice(item);
        acc.currency = item.price.currency;
        return acc;
      },
      { amount: 0, currency: 'USD' },
    );
    useEsc(resolve, { escapeOnly: true });
    return (
      <section
        class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800/50 backdrop-blur"
        onMouseDown={(e) => {
          if (e.target === e.currentTarget) {
            e.preventDefault();
            e.stopPropagation();
            resolve();
          }
        }}
      >
        <div class="bg-white rounded-3xl shadow-2xl w-2xl max-w-full p-8 px-10">
          <section class="flex flex-col gap-10 an-fade-in-left" key={offerIndex}>
            <header class="flex justify-between">
              <h1 class="text-center font-semibold">{upsell.title}</h1>
              <button
                class="flex items-center gap-2 tex-xs"
                onClick={() => setOfferIndex(upsell.offers.length)}
              >
                <IcoCart /> {cartItems.length}
              </button>
            </header>
            {offer && (
              <>
                <div class="flex flex-col gap-6">
                  {offer.content && <ReadonlyMinidoc content={offer.content} />}
                  <CourseAndPricePreview
                    course={offer.course}
                    price={offer.price}
                    listPrice={offer.listPrice}
                  />
                </div>
                <footer class="flex flex-col gap-4">
                  <BtnPrimary
                    class="rounded-full gap-2 pl-6 pr-4"
                    onClick={() => {
                      setCartItems((s) => [...s, offer]);
                      moveNext();
                    }}
                  >
                    <span>{offer.acceptButtonText || 'Yes. Add to cart.'}</span>
                    <IcoArrowLeft class="rotate-180" />
                  </BtnPrimary>
                  <button class="text-indigo-600 rounded-full" onClick={moveNext}>
                    {offer.rejectButtonText || 'No, thanks.'}
                  </button>
                </footer>
              </>
            )}
            {!offer && (
              <>
                <div class="flex flex-col gap-4">
                  {upsell.offers.map((offer) => {
                    return (
                      <CourseAndPricePreview
                        course={offer.course}
                        price={offer.price}
                        listPrice={offer.listPrice}
                        key={offer.id}
                      >
                        <label class="ml-auto pl-4">
                          <Toggle
                            checked={cartItems.includes(offer)}
                            onClick={(e) => {
                              setCartItems((s) => {
                                if (!(e.target as HTMLInputElement).checked) {
                                  return s.filter((x) => x !== offer);
                                } else {
                                  return [...s, offer];
                                }
                              });
                            }}
                          />
                        </label>
                      </CourseAndPricePreview>
                    );
                  })}
                  <span class="border rounded-xl bg-green-50 border-green-200 text-green-800 p-2 inline-flex items-center gap-4 min-w-96 pr-4 group relative w-full">
                    <span class="flex w-14 aspect-square rounded-xl bg-gradient-to-r from-green-400 to-green-500 text-white items-center justify-center text-2xl">
                      {fmt.currencySymbol(dueToday.currency)}
                    </span>
                    <span class="flex flex-col font-semibold">
                      <span class="text-sm text-green-600">Due Today</span>
                      <span class="text-lg leading-5">
                        {fmt.price({ priceInCents: dueToday.amount, currency: dueToday.currency })}
                      </span>
                    </span>
                  </span>
                </div>
                <footer class="flex flex-col gap-4 pb-4">
                  <BtnPrimary
                    class="rounded-full gap-2 pl-6 pr-4 bg-green-500 hover:bg-green-600 font-semibold"
                    onClick={() => {
                      moveNext();
                    }}
                  >
                    <span>Finish checkout</span>
                    <IcoArrowLeft class="rotate-180" />
                  </BtnPrimary>
                </footer>
              </>
            )}
          </section>
        </div>
      </section>
    );
  });
}
