import { showError } from '@components/app-error';
import { DragState } from '@components/draggable';
import { router } from '@components/router';
import { Course, FullLesson, Lesson, ManageState, Module } from './types';
import { groupBy } from 'shared/utils';
import { Dispatch } from 'client/lib/hooks';
import { toISOString, toLocalDate, toUTCDate } from 'shared/dateutil';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { captureException } from 'client/lib/sentry';

const store = rpx.modules;

type Outline = Pick<RpxResponse<typeof rpx.lessons.getFullLessonState>, 'lessons' | 'modules'> & {
  courseId: string;
};

export interface Props {
  courseId: string;
  lessonId: string;
}

export type Action =
  | { type: 'deselectLesson' }
  | { type: 'loadingLesson'; payload: { lessonId: UUID } }
  | { type: 'loadedLesson'; payload: FullLesson }
  | { type: 'insertingModule' }
  | { type: 'editingModule'; payload: { moduleId?: UUID } }
  | {
      type: 'rescheduleModule';
      payload: Pick<Module, 'id' | 'startDate' | 'startOffset'>;
    }
  | { type: 'insertedModule'; payload: { module: Module; moduleIds: UUID[] } }
  | {
      type: 'savedModule';
      payload: Pick<Module, 'id' | 'title' | 'startDate' | 'courseId'> & {
        moduleIds: UUID[];
      };
    }
  | { type: 'deletedModule'; payload: { id: UUID } }
  | { type: 'deletedLesson'; payload: { id: UUID } }
  | { type: 'insertingLesson'; payload: { moduleId: UUID } }
  | {
      type: 'insertedLesson';
      payload: { lesson: FullLesson; lessonIds: UUID[] };
    }
  | { type: 'dragReorder'; payload: DragState }
  | { type: 'lessonSaved'; payload: Partial<Lesson> }
  | { type: 'reset'; payload: ManageState }
  | { type: 'loadedOutline'; payload: Outline };

export type Dispatcher = Dispatch<Action>;

/**
 * The props come in as strings from the route params, and we need to convert
 * them to numbers.
 */
export function parseProps(props: Props): { courseId: UUID; lessonId?: UUID } {
  return {
    courseId: props.courseId,
    lessonId: props.lessonId || undefined,
  };
}

export function normalizeOutline({ lessons, modules, courseId }: Outline) {
  const moduleIds = modules.map((s) => s.id);
  const moduleLessons = groupBy((x) => x.moduleId, lessons);
  const modulesMap = modules.reduce((acc, s) => {
    acc[s.id] = {
      ...s,
      courseId,
      startDate: toLocalDate(s.startDate),
      lessons: moduleLessons[s.id]?.map((x) => x.id) || [],
      isDraft: s.isDraft,
    };
    return acc;
  }, {} as ManageState['modules']);

  return {
    moduleIds,
    orderedLessonIds: moduleIds.flatMap((moduleId) => modulesMap[moduleId].lessons),
    lessons: lessons.reduce((acc, s) => {
      acc[s.id] = {
        ...s,
        type: 'partial',
      };
      return acc;
    }, {} as Record<UUID, Lesson>),
    modules: modulesMap,
  };
}

/**
 * Load the initial state.
 */
export async function load(
  courseId: UUID,
  lessonId: UUID | undefined,
): Promise<ManageState & { lastViewedLessonId?: UUID }> {
  const [course, result] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.lessons.getFullLessonState({ courseId, lessonId }),
  ]);

  const outline = normalizeOutline({
    courseId,
    lessons: result.lessons,
    modules: result.modules,
  });

  if (result.lesson) {
    outline.lessons[result.lesson.id] = {
      type: 'full',
      ...result.lesson,
    };
  }

  return {
    courseId,
    lessonId,
    lastViewedLessonId: result.lastViewedLesson,
    membershipDate: result.membershipDate,
    completedLessons: result.completedLessons,
    orderedLessonIds: outline.orderedLessonIds,
    courses: {
      [courseId]: {
        ...course,
        modules: outline.moduleIds,
      },
    },
    isLoading: false,
    lessons: outline.lessons,
    modules: outline.modules,
    accessLevel: result.accessLevel,
  };
}

/**
 * Load a full lesson.
 */
async function loadLesson(courseId: UUID, lessonId: UUID, dispatch: Dispatcher) {
  dispatch({ type: 'loadingLesson', payload: { lessonId } });
  const lesson = await rpx.lessons.getLesson({ courseId, lessonId });
  dispatch({
    type: 'loadedLesson',
    payload: {
      ...lesson,
      type: 'full',
    },
  });
}

export async function refreshOutline(props: { courseId: string }, dispatch: Dispatcher) {
  const { courseId } = props;
  try {
    const outline = await rpx.lessons.getFullLessonState({ courseId });
    dispatch({
      type: 'loadedOutline',
      payload: {
        modules: outline.modules,
        lessons: outline.lessons,
        courseId,
      },
    });
  } catch (err) {
    showError(err);
  }
}

export async function loadState(props: Props, state: ManageState, dispatch: Dispatcher) {
  const { courseId, lessonId } = parseProps(props);
  try {
    if (lessonId && state.lessonId !== lessonId) {
      await loadLesson(courseId, lessonId, dispatch);
    } else if (!lessonId) {
      dispatch({ type: 'deselectLesson' });
    }
  } catch (err) {
    showError(err);
  }
}

/**
 * The current course.
 */
export const getCurrentCourse = (state: Pick<ManageState, 'courseId' | 'courses'>) =>
  state.courses[state.courseId];

/**
 * The current lesson.
 */
export const getCurrentLesson = (state: ManageState) => {
  if (!state.lessonId) {
    return undefined;
  }
  const lesson = state.lessons[state.lessonId];
  if (lesson.type !== 'full') {
    return undefined;
  }
  return lesson;
};

/**
 * Insert a new module.
 */
export async function insertModule(opts: {
  modules: ManageState['modules'];
  course: Course;
  dispatch: Dispatcher;
}) {
  const { course, modules, dispatch } = opts;
  dispatch({ type: 'insertingModule' });

  try {
    const title = '';
    const courseId = course.id;
    const prev = modules[course.modules[course.modules.length - 1]];
    const schedule: Pick<Module, 'startDate' | 'startOffset'> = {};

    if (course.accessFormat === 'scheduled') {
      const prevDate = new Date(prev?.startDate || new Date());
      prevDate.setDate(prevDate.getDate() + 1);
      schedule.startDate = prevDate;
    } else if (course.accessFormat === 'ondemand') {
      schedule.startOffset = prev ? (prev.startOffset || 0) + 24 * 60 : 0;
    }

    const utcSchedule = {
      ...schedule,
      startDate: toUTCDate(schedule.startDate),
    };

    // Create a new module
    const moduleId = await store.createModule({
      title,
      courseId,
      ...utcSchedule,
    });
    // Compute the correct module ordering
    const moduleIds = [...course.modules, moduleId];
    // Save the module ordering
    await store.reorderModules({
      courseId,
      moduleIds,
      moduleId,
      ...utcSchedule,
    });

    dispatch({
      type: 'insertedModule',
      payload: {
        moduleIds,
        module: {
          id: moduleId,
          title,
          courseId: course.id,
          lessons: [],
          isDraft: false,
          ...schedule,
        },
      },
    });
  } catch (err) {
    showError(err);
  }
}

/**
 * Save a lesson.
 */
export async function saveLesson(opts: {
  courseId: UUID;
  lesson: Omit<Lesson, 'moduleId' | 'type' | 'isAvailable'>;
  dispatch: Dispatcher;
}) {
  const { courseId, lesson, dispatch } = opts;
  try {
    await rpx.lessons.saveLesson({
      id: lesson.id,
      courseId,
      title: lesson.title,
      content: lesson.content || '',
      discussion: lesson.discussion,
      downloads: lesson.downloads,
      isPrerequisite: lesson.isPrerequisite,
      assessmentType: lesson.assessmentType,
      mediaFiles: lesson.mediaFiles,
    });

    dispatch({
      type: 'lessonSaved',
      payload: lesson,
    });
  } catch (err) {
    showError(err);

    // A validation error is not expected here as all arguments either have a default
    // or they are optional. So this error usually means something else is wrong
    // and we are throwing an exception to make sure this session is logged in Sentry Replay.
    if (err.type === 'validation') {
      captureException(new Error('Unexpected validation error'));
    }
  }
}

/**
 * Insert a new lesson.
 */
export async function insertLesson(module: Module, dispatch: Dispatcher) {
  dispatch({ type: 'insertingLesson', payload: { moduleId: module.id } });
  try {
    const lesson = await rpx.lessons.createLesson({
      moduleId: module.id,
      title: '',
      content: '',
      seq: 0,
    });
    const lessonIds = [...module.lessons, lesson.id];
    await store.reorderLessons({
      moduleId: module.id,
      lessonIds,
    });

    dispatch({
      type: 'insertedLesson',
      // TODO: get rid of the any cast here
      payload: { lesson: { ...(lesson as any), type: 'full' }, lessonIds },
    });

    // The setTimeout is a hacky way to prevent this from conflicting with other state
    // and layout issues. TODO: investigate why...
    setTimeout(() => router.goto(`/manage/courses/${module.courseId}/lessons/${lesson.id}`));
  } catch (err) {
    showError(err);
  }
}

function autoAdjustModuleSchedule(
  moduleId: UUID,
  state: ManageState,
): Pick<Module, 'startDate' | 'startOffset'> {
  const course = getCurrentCourse(state);
  const i = course.modules.indexOf(moduleId);
  const module = state.modules[course.modules[i]];
  const prevModule = state.modules[course.modules[i - 1]];
  const nextModule = state.modules[course.modules[i + 1]];

  // Do not modify the start date or offset if the module
  // has the same start date as the next module
  // because this is just the re-ordering of modules scheduled
  // for the same date.
  if (module?.startDate && nextModule?.startDate) {
    if (module.startDate.getTime() === nextModule.startDate.getTime()) {
      return {
        startDate: module.startDate,
        startOffset: module.startOffset,
      };
    }
  }
  if (module?.startOffset !== undefined && nextModule?.startOffset !== undefined) {
    if (module.startOffset === nextModule.startOffset) {
      return {
        startDate: module.startDate,
        startOffset: module.startOffset,
      };
    }
  }

  // If we've dropped the module between two other modules, we'll schedule
  // our dropped module to be halfway between them.
  return {
    startDate: prevModule?.startDate ?? nextModule?.startDate ?? module?.startDate,
    startOffset: prevModule?.startOffset ?? nextModule?.startOffset ?? (module?.startOffset || 0),
  };
}

/**
 * When the drag operation completes, we need to persist the
 * new world order.
 */
export function persistDragOrder(state: ManageState, dragState: DragState, dispatch: Dispatcher) {
  const id = dragState.dragging.id;
  if (dragState.dragging.table === 'lessons') {
    const lesson = state.lessons[id];
    const module = state.modules[lesson.moduleId];
    return store.reorderLessons({
      moduleId: module.id,
      lessonIds: module.lessons,
    });
  }

  // We're reordering modules
  const course = getCurrentCourse(state);
  // We need to make date adjustments, if this is a scheduled / ondemand course.
  const schedule =
    course.accessFormat === 'ondemand' || course.accessFormat === 'scheduled'
      ? autoAdjustModuleSchedule(id, state)
      : {};

  dispatch({
    type: 'rescheduleModule',
    payload: { ...schedule, id },
  });

  return store.reorderModules({
    courseId: course.id,
    moduleIds: course.modules,
    moduleId: id,
    startOffset: schedule.startOffset,
    startDate: toUTCDate(schedule.startDate),
  });
}

/**
 * This convoluted bit of magic reorders lessons or modules
 * based on the drag state. This really could do with some tidying up.
 */
export function dragReorder(dragState: DragState, dispatch: Dispatcher) {
  dispatch({ type: 'dragReorder', payload: dragState });
}

/**
 * Delete the lesson.
 */
export async function deleteLesson(courseId: UUID, lesson: Lesson, dispatch: Dispatcher) {
  await rpx.lessons.deleteLesson({ id: lesson.id, courseId });
  dispatch({ type: 'deletedLesson', payload: { id: lesson.id } });
}

/**
 * Update the module.
 */
export async function updateModule(
  state: Pick<ManageState, 'courseId' | 'courses' | 'modules'>,
  updates: Pick<Module, 'id' | 'title' | 'startOffset' | 'prices' | 'startDate' | 'isDraft'>,
  dispatch: Dispatcher,
) {
  const course = getCurrentCourse(state);
  const module = state.modules[updates.id];
  let moduleIds = course.modules;

  // If we have an ondemand or scheduled course, and the date / day has changed,
  // we need to reorder the modules.
  const isReorderable = course.accessFormat === 'ondemand' || course.accessFormat === 'scheduled';
  const shouldReorder =
    module.startDate?.toISOString() !== updates.startDate?.toISOString() ||
    module.startOffset !== updates.startOffset;
  if (isReorderable && shouldReorder) {
    moduleIds = course.modules
      .map((id) => {
        const module = state.modules[id];
        return id === updates.id ? { ...module, ...updates } : module;
      })
      .sort((a, b) => {
        const MAX_INT = Number.MAX_SAFE_INTEGER;
        const [val1, val2] =
          course.accessFormat === 'ondemand'
            ? [a.startOffset || MAX_INT, b.startOffset || MAX_INT]
            : [a.startDate?.getTime() || MAX_INT, b.startDate?.getTime() || MAX_INT];
        if (val1 === val2) {
          return a.seq! > b.seq! ? 1 : -1;
        }
        return val1 > val2 ? 1 : -1;
      })
      .map((l) => l.id);

    await store.reorderModules({
      courseId: course.id,
      moduleId: updates.id,
      moduleIds,
      startOffset: updates.startOffset,
      startDate: course.isAbsoluteSchedule
        ? toISOString(updates.startDate || module.startDate)
        : toUTCDate(updates.startDate || module.startDate),
    });
  }

  await store.updateModule({
    id: updates.id,
    title: updates.title,
    startDate: toUTCDate(updates.startDate),
    prices: updates.prices,
    isDraft: updates.isDraft,
  });

  dispatch({
    type: 'savedModule',
    payload: { ...updates, courseId: course.id, moduleIds },
  });
}

/**
 * Edit the specified module. If undefined, this clears the module editor.
 */
export function editModule(moduleId: UUID, dispatch: Dispatcher) {
  dispatch({ type: 'editingModule', payload: { moduleId } });
}
