import { Tab, Tabs } from '@components/tabs';
import { useCurrentUser } from 'client/lib/auth';
import { ComponentChildren } from 'preact';
import { useIntl } from 'shared/intl/use-intl';

type TabName = 'courses' | 'bundles' | 'upsells';
type Props = {
  tab: TabName;
  buttons?: ComponentChildren;
  children: ComponentChildren;
};

export function MyCoursesPageWrapper(props: Props) {
  const intl = useIntl();
  const level = useCurrentUser()?.level;
  return (
    <div class="w-screen max-w-5xl px-6 mb-40 mx-auto grow">
      <div class="flex justify-between pt-6 pb-2">
        <Tabs class="grow">
          <Tab isSelected={props.tab === 'courses'} href="/courses?show=all">
            {intl('My Courses')}
          </Tab>
          <Tab isSelected={props.tab === 'bundles'} href="/courses?show=bundles">
            {intl('My Bundles')}
          </Tab>
          {level === 'superadmin' && (
            <Tab isSelected={props.tab === 'upsells'} href="/upsells">
              {intl('My Upsells')}
            </Tab>
          )}
          {props.buttons && <div class="flex justify-end grow">{props.buttons}</div>}
        </Tabs>
      </div>
      {props.children}
    </div>
  );
}
