import { Button } from '@components/buttons';
import { Draggable, DraggableProvider, reorderItems } from '@components/draggable';
import { IcoGripperHorizontal, IcoPlus, IcoRefresh, IcoTrash } from '@components/icons';
import { Toggle } from '@components/toggle';
import { filepicker } from 'client/lib/filepicker';
import { substateUpdater } from 'client/lib/hooks';
import { useState, useMemo } from 'preact/hooks';
import { Meeting, Recording } from 'server/types';
import { RecordingPlayer } from '@components/recording-player';
import { showConfirmModal } from '@components/modal-form';
import { useBasicAutosaver } from '@components/autosaver';
import { showError } from '@components/app-error';
import { rpx } from 'client/lib/rpx-client';
import { showVideoPosterModal } from '@components/media-card/video-poster-modal';
import { useImageUrl } from 'client/utils/cdn';

const store = rpx.meetings;

interface Props {
  meeting: Pick<Meeting, 'id' | 'allowRecordingDownloads'>;
  recordings: Recording[];
  onChange: (recordings: Recording[]) => void;
}

function Item({
  meeting,
  recording,
  onChange,
  onDelete,
}: {
  meeting: Props['meeting'];
  recording: Recording;
  onChange: (update: Partial<Recording>) => void;
  onDelete: () => void;
}) {
  const videoUrl = useImageUrl(recording.url);

  async function changePoster() {
    const result = await showVideoPosterModal({
      url: videoUrl!,
      type: recording.type,
      isPublic: false,
    });

    if (!result) {
      return;
    }

    try {
      await store.updateMeetingRecordingPoster({
        meetingId: meeting.id,
        recordingFileId: recording.id,
        posterFileId: result.fileId,
      });
      onChange({
        poster: result.filePath,
      });
    } catch (err) {
      showError(err);
    }
  }

  return (
    <div class="p-8 pb-4 border relative">
      <div class="absolute top-2 left-1/2 text-gray-400 cursor-move" draggable>
        <IcoGripperHorizontal class="w-5 h-5" />
      </div>
      <div class="">
        <input
          class="mb-6 p-1 w-full text-lg leading-6 cursor-pointer rounded border border-transparent focus:outline-none hover:border-gray-300 focus:border-transparent focus:ring-2 focus:ring-indigo-500 grow"
          value={recording.title}
          placeholder="Untitled recording"
          onKeyDown={(e: any) => e.code === 'Enter' && e.target.blur()}
          onChange={(e: any) => onChange({ title: e.target.value })}
        />
        <RecordingPlayer recording={recording} allowDownload={false} hideTitle />
        <footer class="flex flex-col lg:flex-row gap-3 lg:justify-between lg:items-center mt-8">
          <div>
            <label>
              <Toggle
                checked={recording.allowDownload}
                onClick={() => onChange({ allowDownload: !recording.allowDownload })}
              />
              <span class="ml-2 text-xs">Allow students to download</span>
            </label>
          </div>
          <div class="flex gap-2 items-center">
            <Button
              class="flex items-center gap-1 text-indigo-600 p-2 cursor-pointer hover:opacity-75"
              onClick={changePoster}
            >
              <IcoRefresh class="w-4 h-4" /> Change Poster Image
            </Button>
            <span class={`flex items-center`}>
              <span class="border-r border-gray-500 inline-flex h-6"></span>
            </span>
            <Button
              class="flex items-center gap-1 text-red-600 p-2 cursor-pointer hover:opacity-75"
              onClick={onDelete}
            >
              <IcoTrash class="w-4 h-4" /> Delete Recording
            </Button>
          </div>
        </footer>
      </div>
    </div>
  );
}

export function RecordingsEditor(props: Props) {
  const [state, setState] = useState<{
    recordings: Recording[];
  }>({
    recordings: props.recordings,
  });
  const { recordings } = state;

  const setRecordings = useMemo(
    () =>
      substateUpdater(
        setState,
        (s) => s.recordings,
        (s, recordings) => ({ ...s, recordings }),
      ),
    [setState],
  );

  function updateRecording(id: UUID, update: Partial<Recording>) {
    setRecordings((recordings) => recordings.map((c) => (c.id === id ? { ...c, ...update } : c)));
  }

  async function createRecording() {
    const file = await filepicker();
    if (!file) {
      return;
    }

    const recording = {
      id: file.fileId,
      title: '',
      name: file.name,
      type: file.type,
      url: file.filePath,
      allowDownload: props.meeting.allowRecordingDownloads,
      hasCaptions: false,
    };
    setRecordings((recordings) => [...recordings, recording]);
  }

  useBasicAutosaver(recordings, async (val) => {
    try {
      const savedRecordings = val.map((recording, i) => ({
        fileId: recording.id,
        url: recording.url,
        title: recording.title || '',
        allowDownload: recording.allowDownload,
        seq: i,
      }));
      await store.upsertRecordings({
        recordings: savedRecordings,
        meetingId: props.meeting.id,
      });
      props.onChange(val);
    } catch (err) {
      showError(err);
    }
  });

  return (
    <>
      <div class="flex flex-col w-full pt-8">
        <div class="flex flex-col w-full gap-2">
          <DraggableProvider
            canHandleDrop={(_, table) => table === 'discussion-categories'}
            onDragComplete={() => {}}
            onTargetChange={(dragState) => setRecordings((s) => reorderItems(s, dragState))}
          >
            {recordings.map((recording) => (
              <Draggable key={recording.id} id={recording.id} table="discussion-categories">
                <Item
                  key={recording.id}
                  meeting={props.meeting}
                  recording={recording}
                  onChange={(update) => updateRecording(recording.id, update)}
                  onDelete={async () => {
                    if (
                      await showConfirmModal({
                        mode: 'warn',
                        title: 'Remove recording?',
                        body: `Remove ${recording.name} from the downloads section?`,
                        confirmButtonText: 'Remove recording',
                      })
                    ) {
                      await store.deleteRecording({
                        fileId: recording.id,
                        meetingId: props.meeting.id,
                      });
                      setRecordings((r) => r.filter((x) => x.id !== recording.id));
                    }
                  }}
                />
              </Draggable>
            ))}
          </DraggableProvider>
        </div>
        <Button
          class="mt-8 inline-flex items-center justify-center text-indigo-600 outline-none focus:ring-2 focus:ring-indigo-400 rounded"
          onClick={createRecording}
        >
          <IcoPlus class="h-6 w-6 mr-1 opacity-75" />
          Upload a new recording
        </Button>
      </div>
    </>
  );
}
