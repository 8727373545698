import { showError } from '@components/app-error';
import { useCurrentTenant } from '@components/router/session-context';
import { rpx } from 'client/lib/rpx-client';
import { useEffect, useState } from 'react';
import { FullCourse } from 'server/types';
import { Modal, ModalTitle, ModalMessage, ModalFooter, ModalContent } from '@components/modal';
import { courseLabel } from 'shared/terminology';
import { AsyncForm } from '@components/async-form';
import { Toggle } from '@components/toggle';
import { BtnPrimary, BtnSecondary, BtnWarning } from '@components/buttons';
import { showModalForm } from '@components/modal-form';
import { useTryAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';
import { pluralize } from 'shared/formatting';
import { ProgressBar } from '@components/progress-bar';

interface Props {
  course: Pick<FullCourse, 'id' | 'title' | 'status' | 'isBundle' | 'isArchived'>;
  onCancel(): void;
}

interface ArchiveState {
  mode: 'revokeaccess' | 'archive';
  progress: number;
  description: string;
}

function ArchiveProgress({
  courseId,
  revokeStudentAccess,
  signupsOpen,
}: {
  courseId: UUID;
  revokeStudentAccess: boolean;
  signupsOpen: boolean;
}) {
  const [state, setState] = useState<ArchiveState>({
    progress: 0,
    mode: revokeStudentAccess ? 'revokeaccess' : 'archive',
    description: 'Preparing to archive course...',
  });
  const archiveCourse = async () => {
    setState((s) => ({
      ...s,
      description: 'Archiving course...',
      progress: 99, // Just for effect
    }));
    try {
      await rpx.courseSettings.archiveCourse({
        courseId,
        revokeStudentAccess,
        signupsOpen,
      });

      setState((s) => ({ ...s, progress: 100 }));
      // Artificial delay so the progress bar doens't just flash on the screen
      await new Promise((r) => setTimeout(r, 1000));
      location.reload();
      await new Promise(() => {});
    } catch (err) {
      showError(err);
    }
  };

  const revokeAccess = async () => {
    try {
      const purchaseIds = await rpx.subscriptionStatus.getActiveSubscriptions({ courseId });

      for (let i = 0; i < purchaseIds.length; ++i) {
        setState((s) => ({
          ...s,
          description: 'Revoking student access...',
          progress: Math.round(Math.min(99, (i / purchaseIds.length) * 100)),
        }));
        await rpx.subscriptionStatus.cancelPurchase({
          courseId,
          purchaseId: purchaseIds[i],
        });
      }
      setState((s) => ({ ...s, mode: 'archive' }));
    } catch (err) {
      showError(err);
    }
  };

  useEffect(() => {
    if (state.mode === 'archive') {
      archiveCourse();
    } else if (state.mode === 'revokeaccess') {
      revokeAccess();
    }
  }, [state.mode]);

  return (
    <ModalContent mode="none">
      <span>{state.description}</span>
      <ProgressBar progress={state.progress} />
    </ModalContent>
  );
}

function ArchiveCourseModal({ course, onCancel }: Props) {
  const tenant = useCurrentTenant();
  const { title } = course;
  const { isLoading, data: stats } = useTryAsyncData(
    () => rpx.courses.getCourseDeletionStats({ courseId: course.id }),
    [course.id],
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [revokeStudentAccess, setRevokeStudentAccess] = useState(false);
  const [signupsOpen, setSignupsOpen] = useState(false);
  const terminologyCourse = courseLabel({
    course,
    tenant,
  });

  return (
    <Modal isOpen onCancel={onCancel}>
      {isLoading && (
        <div class="absolute inset-0 flex justify-center items-center">
          <DefaultSpinner />
        </div>
      )}
      {isSubmitting && (
        <ArchiveProgress
          courseId={course.id}
          revokeStudentAccess={revokeStudentAccess}
          signupsOpen={signupsOpen}
        />
      )}
      {!isSubmitting && (
        <ModalContent mode={isLoading ? 'none' : 'warn'}>
          <AsyncForm
            class={isLoading ? 'invisible' : ''}
            onSubmit={async () => {
              setIsSubmitting(true);
            }}
          >
            <ModalTitle>Archive {terminologyCourse}?</ModalTitle>
            <ModalMessage class="flex flex-col gap-4">
              <p>
                Are you sure you want to archive <strong>{title}</strong>?
              </p>
              <label class="flex items-center gap-3 cursor-pointer">
                <Toggle checked={signupsOpen} onClick={() => setSignupsOpen(!signupsOpen)} />
                <span>Signups open</span>
              </label>

              {stats && (!!stats.numActiveSubs || !!stats.numActiveStudents) && (
                <label class="flex items-center gap-3 cursor-pointer">
                  <Toggle
                    checked={revokeStudentAccess}
                    onClick={() => setRevokeStudentAccess(!revokeStudentAccess)}
                  />
                  <span>
                    Revoke access for all {stats.numActiveStudents} students
                    {stats.numActiveSubs ? (
                      <strong>
                        {' '}
                        and cancel {stats.numActiveSubs}{' '}
                        {pluralize('subscription', stats.numActiveSubs)} and{' '}
                        {pluralize('payment plan', stats.numActiveSubs)}
                      </strong>
                    ) : (
                      ''
                    )}
                    ?
                  </span>
                </label>
              )}
            </ModalMessage>

            <ModalFooter>
              <BtnSecondary type="button" onClick={onCancel}>
                Cancel
              </BtnSecondary>
              <BtnWarning isLoading={isSubmitting}>Archive {terminologyCourse}</BtnWarning>
            </ModalFooter>
          </AsyncForm>
        </ModalContent>
      )}
    </Modal>
  );
}

function UnarchiveCourseModal({ course, onCancel }: Props) {
  const tenant = useCurrentTenant();
  const { title } = course;
  const [isDeleting, setIsDeleting] = useState(false);
  const [grantStudentAccess, setGrantStudentAccess] = useState(false);
  const [signupsOpen, setSignupsOpen] = useState(true);
  const { isLoading, data: stats } = useTryAsyncData(
    () => rpx.courses.getCourseDeletionStats({ courseId: course.id }),
    [course.id],
  );
  const terminologyCourse = courseLabel({
    course,
    tenant,
  });

  return (
    <Modal isOpen onCancel={onCancel}>
      {isLoading && (
        <div class="absolute inset-0 flex justify-center items-center">
          <DefaultSpinner />
        </div>
      )}
      <ModalContent
        mode="custom"
        icon={
          <b class="bg-indigo-600 text-white inline-flex items-center justify-center text-2xl w-10 h-10 rounded-full font-semibold">
            ?
          </b>
        }
      >
        <AsyncForm
          class={isLoading ? 'invisible' : ''}
          onSubmit={async () => {
            setIsDeleting(true);

            try {
              await rpx.courseSettings.unarchiveCourse({
                courseId: course.id,
                grantStudentAccess,
                signupsOpen,
              });

              location.reload();
              await new Promise(() => {});
            } catch (err) {
              showError(err);
            } finally {
              setIsDeleting(false);
            }
          }}
        >
          <ModalTitle>Unarchive {terminologyCourse}?</ModalTitle>
          <ModalMessage class="flex flex-col gap-6">
            <p>
              Would you like to unarchive <strong>{title}</strong>?
            </p>

            <label class="flex items-center gap-3 cursor-pointer">
              <Toggle checked={signupsOpen} onClick={() => setSignupsOpen(!signupsOpen)} />
              <span>Signups open</span>
            </label>

            {!!stats?.numStudents && (
              <label class="flex items-center gap-4 cursor-pointer">
                <Toggle
                  checked={grantStudentAccess}
                  onClick={() => setGrantStudentAccess(!grantStudentAccess)}
                />
                Grant access to {stats.numStudents} {pluralize('student', stats.numStudents)}
              </label>
            )}
          </ModalMessage>

          <ModalFooter>
            <BtnSecondary type="button" onClick={onCancel}>
              Cancel
            </BtnSecondary>
            <BtnPrimary isLoading={isDeleting}>Unarchive {terminologyCourse}</BtnPrimary>
          </ModalFooter>
        </AsyncForm>
      </ModalContent>
    </Modal>
  );
}

// Toggle the archived status of a course for the current user.
export async function toggleCourseArchivedState({ course }: { course: Props['course'] }) {
  return showModalForm(({ resolve }) =>
    course.isArchived ? (
      <UnarchiveCourseModal course={course} onCancel={resolve} />
    ) : (
      <ArchiveCourseModal course={course} onCancel={resolve} />
    ),
  );
}
