import { router } from '@components/router';
import { LoadedProps } from 'client/lib/loaders';
import { AppRoute } from 'client/lib/app-route/types';
import { AppliedTo, Upsell } from './types';
import { store } from './store';
import { UpsellsPage } from './upsells-page';
import { UpsellWizard, WizardSubtitle } from './upsell-wizard';
import { BtnPreWarning, BtnPrimary, Button } from '@components/buttons';
import { IcoArrowLeft } from '@components/icons';
import { UpsellCoursePicker } from './pickers';
import { showConfirmModal } from '@components/modal-form';
import { showError } from '@components/app-error';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { LoadingIndicator } from '@components/loading-indicator';
import { ComponentChildren } from 'preact';
import { PriceSummary } from '@components/checkout';
import { CourseAndPricePreview } from './course-price-preview';
import { useCurrentTenant } from 'client/lib/auth';

type Pane = 'pickcourse' | 'pickprice';

type State = {
  page: 'new' | 'edit';
  pane: Pane;
  upsell: Upsell;
  appliedTo: {
    course?: AppliedTo['course'];
    prices: AppliedTo['prices'];
  };
};

async function loadNew(props: AppRoute): Promise<State> {
  const upsell = await store.getUpsell({ id: props.params.id });
  if (!upsell) {
    throw new Error(`Upsell not found!`);
  }
  return {
    page: 'new',
    upsell,
    pane: 'pickcourse',
    appliedTo: {
      prices: [],
    },
  };
}

async function loadExisting(props: AppRoute): Promise<State> {
  const upsell = await store.getUpsell({ id: props.params.id });
  if (!upsell) {
    throw new Error(`Upsell not found!`);
  }
  const appliedTo = upsell.appliedTo?.find((x) => x.course.id === props.params.courseId);
  if (!appliedTo) {
    throw new Error(`Applied to item not found!`);
  }
  return { page: 'edit', upsell, pane: 'pickprice', appliedTo };
}

type Props = LoadedProps<typeof loadNew>;

function UpsellPrice({
  onClick,
  isSelected,
  children,
}: {
  children: ComponentChildren;
  isSelected: boolean;
  onClick(): void;
}) {
  return (
    <label
      class={`flex items-center border-t last:rounded-b-2xl first:rounded-t-2xl first:border-transparent p-4 gap-4 ring-1 cursor-pointer ${
        isSelected
          ? 'bg-indigo-50 ring-indigo-200 z-10 border-transparent hover:bg-indigo-100'
          : 'ring-transparent hover:bg-gray-50'
      }`}
    >
      <input
        type="checkbox"
        checked={isSelected}
        onClick={onClick}
        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      />
      <span
        class={`inline-flex size-8 items-center justify-center rounded-full font-semibold ${
          isSelected ? ' bg-indigo-500 text-white' : 'bg-gray-100 text-gray-500'
        }`}
      >
        $
      </span>
      {children}
    </label>
  );
}

function PricePicker(props: Props) {
  const { state, setState } = props;
  const { course, prices } = state.appliedTo;
  const { isLoading, data } = useTryAsyncData(
    () => rpx.prices.getCoursePrices({ courseId: course!.id, excludeArchived: true }),
    [],
  );
  const allSelected = prices.length === data?.length;
  return (
    <section class="flex flex-col gap-6 overflow-auto">
      {state.appliedTo.course && <CourseAndPricePreview course={state.appliedTo.course} />}
      <WizardSubtitle>Choose prices which will show this upsell</WizardSubtitle>
      {isLoading && <LoadingIndicator />}
      {data && (
        <div class="border rounded-2xl flex flex-col">
          <UpsellPrice
            onClick={() => {
              setState((s) => {
                if (allSelected) {
                  return { ...s, appliedTo: { ...s.appliedTo, prices: [] } };
                }
                return { ...s, appliedTo: { ...s.appliedTo, prices: data } };
              });
            }}
            isSelected={allSelected}
          >
            <span class="font-semibold">Select all prices</span>
          </UpsellPrice>

          {data.map((p) => {
            const isSelected = !!prices.find((x) => x.id === p.id);
            return (
              <UpsellPrice
                key={p.id}
                onClick={() => {
                  setState((s) => {
                    if (!isSelected) {
                      return {
                        ...s,
                        appliedTo: { ...s.appliedTo, prices: [...s.appliedTo.prices, p] },
                      };
                    }
                    return {
                      ...s,
                      appliedTo: {
                        ...s.appliedTo,
                        prices: s.appliedTo.prices.filter((x) => x.id !== p.id),
                      },
                    };
                  });
                }}
                isSelected={isSelected}
              >
                {p.name}
                <span
                  class={`rounded text-xs px-1 border ${
                    isSelected ? 'bg-indigo-100 border-indigo-200' : 'bg-gray-50'
                  }`}
                >
                  <PriceSummary price={p} />
                </span>
              </UpsellPrice>
            );
          })}
        </div>
      )}
    </section>
  );
}

function Page(props: Props) {
  const { terminology } = useCurrentTenant();
  const { state, setState } = props;
  const { upsell, appliedTo } = state;
  const paneSequence: Pane[] = ['pickcourse', 'pickprice'];
  const paneIndex = paneSequence.indexOf(state.pane);
  const title = 'Apply to course or product';
  const cancelURL = `/upsells/${upsell.id}?tab=applyto`;
  const firstPaneIndex = state.page === 'new' ? 0 : 1;

  const showDeleteApplyToModal = async () => {
    try {
      const ok = await showConfirmModal({
        title: 'Remove upsell?',
        body: `This upsell will no longer be displayed on this ${
          appliedTo.course?.isProduct ? 'product' : terminology.course
        }'s checkout pages.`,
        confirmButtonText: `Remove upsell from ${
          appliedTo.course?.isProduct ? 'product' : terminology.course
        }`,
        mode: 'warn',
      });
      if (!ok) {
        return;
      }
      if (appliedTo.course) {
        await store.deleteAppliedTo({ upsellId: upsell.id, courseId: appliedTo.course.id });
      }
      router.goto(cancelURL);
    } catch (err) {
      showError(err);
    }
  };

  const saveChanges = async () => {
    try {
      if (appliedTo.course) {
        await store.saveAppliedTo({
          upsellId: upsell.id,
          appliedTo: {
            course: appliedTo.course,
            prices: appliedTo.prices,
          },
        });
      }
      router.goto(cancelURL);
    } catch (err) {
      showError(err);
    }
  };

  return (
    <UpsellsPage title={title}>
      <UpsellWizard
        cancelURL={cancelURL}
        upsell={upsell}
        title={title}
        paneIndex={paneIndex}
        header={
          <>
            {state.page === 'new' && firstPaneIndex < paneIndex && (
              <span>
                <Button
                  class="inline-flex gap-2 items-center text-indigo-600 font-semibold"
                  onClick={() => setState((s) => ({ ...s, pane: 'pickcourse' }))}
                >
                  <IcoArrowLeft class="size-4" />
                  Back
                </Button>
              </span>
            )}
          </>
        }
      >
        {state.pane === 'pickcourse' && (
          <UpsellCoursePicker
            onPick={(course) =>
              setState((s) => ({ ...s, pane: 'pickprice', appliedTo: { course, prices: [] } }))
            }
            filter={(course) => !upsell.appliedTo.find((x) => x.course.id === course.id)}
          />
        )}
        {state.pane === 'pickprice' && <PricePicker {...props} />}
        <footer class="pt-4 sticky bottom-0 w-full bg-white empty:hidden">
          {appliedTo.prices.length > 0 && (
            <BtnPrimary class="rounded-full px-4 gap-2" onClick={saveChanges}>
              Apply upsell to the selected price points
              <IcoArrowLeft class="rotate-180" />
            </BtnPrimary>
          )}
          {state.page === 'edit' && appliedTo.prices.length === 0 && (
            <BtnPreWarning class="rounded-full px-4 gap-2" onClick={showDeleteApplyToModal}>
              Remove upsell from this {appliedTo.course?.isProduct ? 'product' : terminology.course}
              <IcoArrowLeft class="rotate-180" />
            </BtnPreWarning>
          )}
        </footer>
      </UpsellWizard>
    </UpsellsPage>
  );
}

router.add({ url: 'upsells/:id/applied-to/new', render: Page, authLevel: 'guide', load: loadNew });
router.add({
  url: 'upsells/:id/applied-to/:courseId',
  render: Page,
  authLevel: 'guide',
  load: loadExisting,
});
