/**
 * This module contains the logic for managing asset uploads within the editor.
 */
import quikpik from 'quikpik';
import { rpx, upload } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { shouldUseWasabiProxy } from 'client/utils/cdn';
import { captureException } from 'client/lib/sentry';

type QuikpikOpts = Parameters<typeof quikpik>[0];

export interface FilePickerResult {
  fileId: UUID;
  filePath: string;
  publicUrl: string;
  type: string;
  name: string;
}

const noop = () => {};

const store = rpx.files;

export function uploadFile({
  file,
  name,
  isPublic,
  onProgress = noop,
}: {
  file: Blob;
  name: string;
  isPublic: boolean;
  onProgress?(percent: number, label: string): void;
}) {
  let canceled = false;
  let uploader: ReturnType<typeof upload> | undefined;

  const cancel = () => {
    canceled = true;
    uploader?.cancel();
  };

  const promise = shouldUseWasabiProxy().then((useProxy) => {
    return store
      .createPresignedPost({
        type: file.type,
        name,
        size: file.size,
        isPublic,
        // Upload through the proxy server when Wasabi is not accessible.
        shouldUseWasabiProxy: useProxy,
      })
      .then(({ presignedPost, fileId, filePath, publicUrl }) => {
        if (canceled) {
          return;
        }

        uploader = upload({
          file,
          fileId,
          presignedPost,
          onProgress,
        });

        return uploader.promise
          .then(
            (): FilePickerResult => ({
              fileId,
              filePath,
              publicUrl,
              type: file.type,
              name,
            }),
          )
          .catch((err) => {
            // Log the error so the session is recorded on Sentry Replay
            captureException(new Error('Failed to upload file'));
            throw err;
          });
      });
  });

  return {
    promise,
    cancel,
  };
}

/**
 * Wraps the quikpik file picker with a more convenient, app-specific signature.
 * quikpik().then(({ fileId, url }) => doSomething(fileId, url))
 * @param opts
 */
export async function filepicker(
  opts: Omit<QuikpikOpts, 'upload'> & {
    isPublic?: boolean;
  } = {},
) {
  const picker = quikpik({
    sources: ['filepicker', 'takephoto', 'takeaudio', 'takevideo'],
    ...opts,
    upload({ files, onProgress }) {
      // TODO: Add support for multiple-uploads, possibly as a separate function.
      const file = files[0];

      return uploadFile({
        file,
        name: file.name,
        isPublic: !!opts.isPublic,
        onProgress,
      });
    },
  });

  try {
    return await picker;
  } catch (err) {
    picker.cancel();
    showError({
      error: 'Failed to upload file. Please try again.',
    });
    throw err;
  }
}
