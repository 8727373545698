import { BtnPreWarning, BtnSecondary } from '@components/buttons';
import { IcoRefresh, IcoTrash } from '@components/icons';
import { showConfirmModal } from '@components/modal-form';

/**
 * The toggle button for prices / coupons.
 */
export function ArchiveToggle({
  isEnabled,
  itemName,
  onToggle,
}: {
  isEnabled: boolean;
  onToggle(): void;
  /**
   * "price point" or "coupon"
   */
  itemName: string;
}) {
  const toggleArchived = async () => {
    const accepted = await showConfirmModal(
      !isEnabled
        ? {
            title: `Activate ${itemName}?`,
            body: `This will enable signups with this ${itemName}.`,
            confirmButtonText: 'Activate',
            mode: 'ok',
          }
        : {
            title: `Archive ${itemName}?`,
            body: `Signups will no longer be allowed with this ${itemName}.`,
            confirmButtonText: 'Archive',
          },
    );
    if (accepted) {
      onToggle();
    }
  };

  if (!isEnabled) {
    return (
      <BtnSecondary class="md:ml-auto" type="button" onClick={toggleArchived}>
        <IcoRefresh />
        <span class="ml-1">Activate</span>
      </BtnSecondary>
    );
  }

  return (
    <BtnPreWarning class="md:ml-auto" type="button" onClick={toggleArchived}>
      <IcoTrash />
      <span class="ml-1">Archive</span>
    </BtnPreWarning>
  );
}
