/**
 * This file contains the logic for managing global notifications (e.g. the little
 * notifications that slide in from the right).
 */

import { evt } from '../../lib/app-evt';
import { IcoCheckCircle, IcoExclamation, IcoX } from '@components/icons';
import { genId } from 'client/utils/gen-id';
import { useState, useEffect } from 'preact/hooks';
import { capitalizeFirst } from 'shared/utils';
import { Button } from '@components/buttons';

interface ToastItem {
  id: number;
  type: 'ok' | 'warn';
  title: string;
  message: string;
  timeout?: number;
}

const add = '$notify';

export const showToast = (item: Omit<ToastItem, 'id'>) =>
  evt.emit(add, {
    ...item,
    id: genId(),
  });

export function Toaster() {
  const [state, setState] = useState<ToastItem[]>([]);
  const remove = (item: ToastItem) => setState((s) => s.filter((x) => x !== item));

  useEffect(() => {
    return evt.on((name, item) => {
      if (name === add) {
        setState((s) => s.concat(item));
        setTimeout(() => remove(item), item.timeout || 4000);
      }
    });
  }, []);

  return (
    <div class="fixed right-6 top-6 z-50">
      {state.map((item) => (
        <div
          key={item.id}
          class="max-w-sm w-80 md:w-96 bg-white shadow-lg rounded-lg mb-2 an-fade-in-left"
        >
          <div class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div class="p-4">
              <div class="flex items-start">
                <div class="shrink-0">
                  {item.type === 'warn' ? (
                    <IcoExclamation class="h-6 w-6 text-red-500" />
                  ) : (
                    <IcoCheckCircle class="h-6 w-6 text-green-400" />
                  )}
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm leading-5 font-medium text-gray-900">
                    {capitalizeFirst(item.title)}
                  </p>
                  <p class="mt-1 text-sm leading-5 text-gray-500">
                    {capitalizeFirst(item.message)}
                  </p>
                </div>
                <div class="ml-4 shrink-0 flex">
                  <Button
                    class="border-none shadow-none inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                    onClick={() => remove(item)}
                  >
                    <IcoX class="h-5 w-5" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
