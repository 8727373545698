/**
 * Helpers to sort out what sort of media we're dealing with.
 */

export type IframeSize = 'auto' | 'large' | 'medium' | 'small';

export const isImage = (type: string) => type.startsWith('image/');
export const isAudio = (type: string) => type.startsWith('audio/');
export const isPDF = (type: string) => type.endsWith('/pdf');
export const isHls = (type: string, url?: string) =>
  type === 'application/x-mpegURL' || url?.endsWith('.m3u8');
export const isVideo = (type: string) => isHls(type) || type.startsWith('video/');

/**
 * We can't pull in extname from path, since this is shared w/ the browser.
 */
const extname = (path: string) => {
  const i = path.lastIndexOf('.');
  return path.slice(i);
};

/**
 * Given the specified file info, generate the files url.
 */
export const virtualFileUrl = (id: UUID, path: string) => `/files/${id}${extname(path)}`;

export const stripSVG = (svg: string) => {
  const re = /<script|\/script/gim;
  const matches = svg.match(re);
  if (!matches?.length) {
    return svg;
  }
  const first = matches[0];
  const last = matches[matches.length - 1];
  const prefix = svg.slice(0, svg.indexOf(first));
  const suffix = svg.slice(svg.lastIndexOf(last) + last.length);
  return prefix + suffix.slice(suffix.indexOf('>') + 1);
};
