import { Modal, ModalContent, ModalTitle } from '@components/modal';
import { AccessFormat, MessageRow } from 'server/types';
import { AsyncForm, FormGroup, Subtext } from '@components/async-form';
import { BtnPrimary, Button } from '@components/buttons';
import { useState } from 'preact/hooks';
import { TimeInput } from '@components/time-input';
import { offsetToTime, timeToOffset } from '@components/module-helpers';
import { toLocalDate, toNullableDate } from 'shared/dateutil';
import { BtnSchedule, TimezoneDiff } from '@components/date-picker';
import { DatePicker, mkdate } from '@components/date-picker';

interface Props {
  hide(): void;
  accessFormat: AccessFormat;
  isAbsoluteSchedule: boolean;
  startDate?: string;
  startOffset?: number;
  applySchedule(schedule: Pick<MessageRow, 'startOffset' | 'startDate'>): Promise<unknown>;
}

export function ScheduleModal(props: Props) {
  const scheduleDate = props.accessFormat === 'scheduled' || props.accessFormat === 'openaccess';
  const [time, setTime] = useState(() => offsetToTime(props.startOffset));
  const [startDate, setStartDate] = useState(() => {
    if (!props.startDate) {
      return mkdate((dt) => {
        dt.setHours(dt.getHours(), 0, 0, 0);
        dt.setDate(dt.getDate() + 7);
      });
    }
    return props.isAbsoluteSchedule
      ? toNullableDate(props.startDate)
      : toLocalDate(props.startDate);
  });

  return (
    <Modal isOpen onCancel={props.hide}>
      <div onClick={(e) => e.stopPropagation()}>
        <AsyncForm
          class="min-w-96"
          onSubmit={() =>
            props.applySchedule({
              startDate,
              startOffset: timeToOffset(time),
            })
          }
        >
          <ModalTitle centered={true}>When do you want to send this message?</ModalTitle>
          <ModalContent mode="none">
            {scheduleDate && <DatePicker value={startDate} onChange={setStartDate} />}
            {!scheduleDate && (
              <FormGroup prop="startDate">
                <div class="text-center my-8 border-b pb-8">
                  <TimeInput time={time} setTime={(t1) => setTime((t2) => ({ ...t2, ...t1 }))} />
                </div>
              </FormGroup>
            )}
          </ModalContent>

          <footer class="my-4">
            {props.isAbsoluteSchedule && <TimezoneDiff />}
            {!props.isAbsoluteSchedule && (
              <Subtext centered>
                (Times are in the student's time zone and are approximate.)
              </Subtext>
            )}
          </footer>
          <footer class="flex items-center justify-end gap-4">
            <Button class="p-2 px-4 rounded-md hover:bg-gray-100" onClick={props.hide}>
              Cancel
            </Button>
            {!props.isAbsoluteSchedule && <BtnPrimary>Schedule Message</BtnPrimary>}
            {props.isAbsoluteSchedule && startDate && (
              <BtnSchedule prefix="Send on " date={startDate} />
            )}
          </footer>
        </AsyncForm>
      </div>
    </Modal>
  );
}
