import { ComponentChildren } from 'preact';
import type { Offer } from './types';
import { CourseLogo } from '@components/course-image';
import { PriceRow } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';
import * as fmt from 'shared/payments/fmt';
import * as pmtmath from 'shared/payments/math';

type Price = Pick<PriceRow, 'priceInCents' | 'paymentType' | 'currency' | 'numPayments'>;

export function UpsellPriceSummary({
  price,
  listPrice,
  showPlaceholder,
}: {
  price: Price;
  listPrice?: string;
  showPlaceholder?: boolean;
}) {
  const intl = useIntl();
  const { initial, final } = pmtmath.batches({ price });
  return (
    <>
      {(listPrice || showPlaceholder) && <s class="opacity-75 mr-1">{listPrice || 'List price'}</s>}
      {initial && (
        <span class="font-semibold">
          {fmt.price(initial)}{' '}
          {fmt.firstBatchSuffix({
            item: initial,
            intl,
          })}
        </span>
      )}
      <span class={initial ? 'opacity-75' : listPrice ? 'text-green-600 font-semibold' : ''}>
        {`${initial ? ', then ' : ''}${fmt.price(final)} ${fmt.priceSuffix({
          item: final,
          intl,
        })}`}
      </span>
    </>
  );
}

export function CourseAndPricePreview({
  course,
  price,
  listPrice,
  children,
  showPlaceholder,
}: {
  course: Pick<Offer['course'], 'title' | 'imagePath'>;
  price?: Price;
  listPrice?: Offer['listPrice'];
  children?: ComponentChildren;
  showPlaceholder?: boolean;
}) {
  if (!course) {
    return null;
  }
  return (
    <span class="inline-flex items-center gap-4 min-w-96 group relative">
      <CourseLogo image={course.imagePath} />
      <span class="flex flex-col items-start">
        <span class="font-medium">{course.title}</span>
        <span class="text-gray-600">
          {price && (
            <UpsellPriceSummary
              price={price}
              listPrice={listPrice}
              showPlaceholder={showPlaceholder}
            />
          )}
        </span>
      </span>
      {children}
    </span>
  );
}
