import { useEsc } from 'client/utils/use-esc';
import { Case } from '@components/conditional';
import { Carousel } from './carousel';
import { useBodyScrollLock } from 'client/lib/hooks/use-body-scroll-lock';
import { AttachmentItem } from './attachment-item';
import { useState } from 'preact/hooks';
import { isImage } from 'shared/media';
import { FileRec, User } from 'server/types';
import { UserProfileIcon } from '@components/avatars';
import { fmtFullTime } from 'shared/dateutil';
import { IcoX } from '@components/icons';
import { Thumbnail } from './thumbnail';
import { Button } from '@components/buttons';

export interface AttachmentProps {
  attachments: FileRec[];
  user?: Pick<User, 'id' | 'name' | 'profilePhotoUrl'>;
  timestamp?: Date | string;
  editMode?: boolean;
  allowFullScreen?: boolean;
  onDelete?: (attachment: FileRec) => void;
  onSort?: (attachment: FileRec, direction: 'next' | 'prev') => void;
  onClick?: (attachment: FileRec) => void;
}

interface HeaderProps {
  user: AttachmentProps['user'];
  timestamp: AttachmentProps['timestamp'];
  onClose: () => void;
}

export function FullScreenHeader({ user, timestamp, onClose }: HeaderProps) {
  return (
    <div class="flex justify-between bg-white w-full dark:bg-gray-900 dark:text-gray-200 px-8 py-4">
      <div class="flex items-center">
        {user && <UserProfileIcon user={user} size="w-12 h-12" />}
        <div class="flex flex-col ml-4">
          {user && <span class="text-lg font-bold">{user.name}</span>}
          {timestamp && <span>{fmtFullTime(timestamp)}</span>}
        </div>
      </div>
      <Button onClick={onClose}>
        <IcoX class="w-10 h-10" />
      </Button>
    </div>
  );
}

/*
 * Lets image attachments grow full screen when clicked.
 */
export function SingleAttachment({
  attachments,
  user,
  timestamp,
  allowFullScreen,
  editMode,
  onDelete,
  onClick,
}: AttachmentProps) {
  const attachment = attachments[0];
  const [isFullScreen, setIsFullScreen] = useState(false);
  useEsc(() => setIsFullScreen(false), { escapeOnly: true });
  useBodyScrollLock(isFullScreen);

  if (!attachment) {
    return null;
  }

  return (
    <div
      class={`${
        isFullScreen
          ? 'fixed flex flex-col z-50 inset-0 bg-white dark:bg-gray-900 bg-opacity-95'
          : 'relative mb-4'
      }`}
    >
      {isFullScreen && (
        <FullScreenHeader
          user={user}
          timestamp={timestamp}
          onClose={() => setIsFullScreen(false)}
        />
      )}
      <div class="flex flex-grow justify-center items-center rounded overflow-hidden">
        <AttachmentItem
          class={`h-full ${isFullScreen ? 'p-2 lg:p-8' : 'cursor-pointer'}`}
          attachment={attachment}
          fullSize={isFullScreen}
          allowFullScreen={allowFullScreen}
          onClick={() => {
            if (allowFullScreen && isImage(attachment.type)) {
              setIsFullScreen(true);
            }
            onClick?.(attachment);
          }}
        />
      </div>
      <Case when={!!editMode && !isFullScreen}>
        <div class="w-32 lg:ml-10 mt-6">
          <Thumbnail
            index={0}
            attachment={attachment}
            selected
            canDelete
            onDelete={() => onDelete?.(attachment)}
          />
        </div>
      </Case>
    </div>
  );
}

export function Attachments(props: AttachmentProps) {
  if (props.attachments.length > 1) {
    return <Carousel {...props} />;
  }

  return <SingleAttachment {...props} />;
}
