import { Modal, ModalContent, ModalFooter, ModalTitle } from '@components/modal';
import { AsyncForm, FormGroup } from '@components/async-form';
import { BtnSecondary, BtnPrimary } from '@components/buttons';
import { useState } from 'preact/hooks';
import { Toggle } from '@components/toggle';
import { Student } from './student-detail-slideover';
import { rpx } from 'client/lib/rpx-client';
import dayjs from 'dayjs';
import { DateInput } from '@components/date-picker';

interface Props {
  courseId: UUID;
  student: Student;
  hide(): void;
  onSuccess: (newExpiresAt?: string) => void;
}

const store = rpx.manageStudents;

export function ModifyAccessModal({ student, courseId, hide, onSuccess }: Props) {
  const [permanentAccess, setPermanentAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(() => {
    return student.expiresAt ? new Date(student.expiresAt) : undefined;
  });

  async function resetExpiration(formData: { notifyStudent: boolean }) {
    setIsLoading(true);
    try {
      const newExpiresAt = permanentAccess
        ? undefined
        : dayjs(currentDate).startOf('day').toISOString();
      await store.setExpirationDate({
        courseId,
        userId: student.id,
        expiresAt: newExpiresAt,
        notifyStudent: formData.notifyStudent,
      });
      onSuccess(newExpiresAt);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal isOpen onCancel={hide}>
      <AsyncForm class="md:min-w-96 p-4" onSubmit={resetExpiration}>
        <ModalTitle centered>Modify student access</ModalTitle>
        <ModalContent mode="none">
          {!!student.expiresAt && (
            <div class="flex my-4">
              <Toggle
                class="mr-2"
                checked={permanentAccess}
                onClick={() => setPermanentAccess(!permanentAccess)}
              />
              <label class="block mb-2">Grant permanent access</label>
            </div>
          )}
          {!permanentAccess && (
            <FormGroup prop="expiresAt">
              <DateInput value={currentDate} onChange={setCurrentDate} min={student.expiresAt} />
            </FormGroup>
          )}
        </ModalContent>
        <div class="flex my-4">
          <Toggle class="mr-2" name="notifyStudent" defaultChecked />
          <label class="block mb-2">Notify student with an email</label>
        </div>
        <ModalFooter>
          <BtnSecondary onClick={hide}>Cancel</BtnSecondary>
          <BtnPrimary isLoading={isLoading}>Save</BtnPrimary>
        </ModalFooter>
      </AsyncForm>
    </Modal>
  );
}
