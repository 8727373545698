import { AutosizeText } from '@components/autosize-text';
import { BtnPrimary, Button } from '@components/buttons';
import { IcoPaperclip, IcoTrash } from '@components/icons';
import { filepicker } from 'client/lib/filepicker';
import { FileRec } from 'server/types';
import { useEffect, useRef, useState } from 'preact/hooks';
import { Attachments } from '@components/attachments';
import { useIntl } from 'shared/intl/use-intl';
import { EmojiPicker } from '@components/emoji-picker';

export interface NewChatMessage {
  content: string;
  attachment?: Attachment;
}

interface Props {
  shouldFocus: boolean;
  sendMessage: (message: NewChatMessage) => Promise<void>;
  isMobile: boolean;
  onHeightChange: (height: number) => void;
}

type Attachment = FileRec & {
  path: string;
};

const MAX_LENGTH = 1024;

export function ChatMessageEditor(props: Props) {
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);

  const [isSending, setIsSending] = useState(false);
  const [showError, setShowError] = useState(false);
  const [newMessage, setNewMessage] = useState<NewChatMessage>({
    content: '',
    attachment: undefined,
  });
  const [lastCursorPos, setLastCursorPos] = useState({ start: 0, end: 0 });
  const trimmedContent = newMessage.content.trim();

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      props.onHeightChange(containerRef.current!.clientHeight);
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, [containerRef.current]);

  async function sendMessage() {
    if (isSending) {
      return;
    }

    if (trimmedContent.length === 0 && !newMessage.attachment) {
      return;
    }

    setIsSending(true);
    setShowError(false);

    // Save the message before sending it, so that if the message fails to send
    // we can restore it.
    const oldMessage = { ...newMessage };
    setNewMessage({
      content: '',
      attachment: undefined,
    });
    try {
      await props.sendMessage({
        content: trimmedContent,
        attachment: newMessage.attachment,
      });
    } catch (error) {
      setNewMessage(oldMessage);
      setShowError(true);
    } finally {
      setIsSending(false);
    }
  }

  return (
    <footer ref={containerRef} class="w-full p-4">
      {newMessage.attachment && (
        <div class="flex justify-center">
          <div class="relative w-48">
            <Attachments
              attachments={[newMessage.attachment]}
              allowFullScreen={!props.isMobile}
              onDelete={() => {
                setNewMessage((m) => ({ ...m, attachment: undefined }));
              }}
            />
            <Button
              class="absolute right-2 top-2 p-2 bg-opacity-50 bg-gray-300 rounded-lg hover:bg-opacity-90"
              onClick={() => setNewMessage((m) => ({ ...m, attachment: undefined }))}
            >
              <IcoTrash class="w-4 h-4 text-black" />
            </Button>
          </div>
        </div>
      )}
      <AutosizeText
        class="ruz-input p-2 min-h-12"
        placeholder={intl('Type your message...')}
        maxLength={MAX_LENGTH}
        focusSelf={props.shouldFocus}
        value={newMessage.content}
        onInput={(e) => setNewMessage((m) => ({ ...m, content: e.currentTarget.value }))}
        onKeyDown={(e: KeyboardEvent) => {
          if (!e.shiftKey && e.code === 'Enter') {
            e.preventDefault();
            sendMessage();
          }
        }}
        onBlur={(e) => {
          setLastCursorPos({
            start: e.currentTarget.selectionStart,
            end: e.currentTarget.selectionEnd,
          });
        }}
      />
      <div class="flex justify-between pt-2">
        <div class="flex gap-2">
          <Button
            class="flex items-center justify-center text-gray-600 hover:opacity-80"
            disabled={!!newMessage.attachment}
            onClick={async () => {
              const file = await filepicker();
              if (file) {
                const attachment: Attachment = {
                  id: file.fileId,
                  type: file.type,
                  name: file.name,
                  url: file.publicUrl,
                  path: file.filePath,
                };
                setNewMessage((m) => ({ ...m, attachment }));
              }
            }}
          >
            <IcoPaperclip class="w-5 h-5" />
          </Button>
          <EmojiPicker
            onPick={(emoji) => {
              setNewMessage((m) => ({
                ...m,
                content: `${m.content.slice(0, lastCursorPos.start)}${emoji}${m.content.slice(
                  lastCursorPos.end,
                )}`,
              }));
            }}
          />
        </div>
        <span class="text-red-500 text-xs">
          {showError ? intl('Failed to send message. Please try again.') : ''}
        </span>
        <BtnPrimary
          class="py-1"
          disabled={trimmedContent.length === 0 && !newMessage.attachment}
          isLoading={isSending}
          onClick={sendMessage}
        >
          {intl('Send')}
        </BtnPrimary>
      </div>
    </footer>
  );
}
