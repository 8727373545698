/**
 * This file contains logic for managing global error state.
 */

import { Modal, ModalFooter, ModalMessage, ModalTitle, ModalWarning } from '@components/modal';
import { BtnSecondary } from '@components/buttons';
import { evt } from 'client/lib/app-evt';
import { useEffect, useState } from 'preact/hooks';
import { Boom } from '@hapi/boom';

type AppErrorState =
  | undefined
  | {
      title?: string;
      error: string | Error;
      message?: string;
      data?: { errors?: Error[] };
    };

type ShowArg = Error | AppErrorState;

const show = '$appError';

export const showError = (err: ShowArg) => {
  evt.emit(show, err);
  console.error(err);
};

function errMessage(state: AppErrorState) {
  if (!state) {
    return;
  }

  // Look for validation errors, and show the first one,
  // if they exist.
  const errs = state.data?.errors;

  if (!errs?.length) {
    return state.message || (state.error as Error)?.message || state.error?.toString();
  }

  return errs[0].message;
}

export function AppError() {
  const [state, setState] = useState<AppErrorState>(undefined);
  useEffect(
    () =>
      evt.on(
        (name, error) =>
          name === show &&
          setState(
            error instanceof Error
              ? {
                  title: (error as Boom).data?.title,
                  error,
                }
              : {
                  title: (error as Boom).data?.title,
                  ...error,
                },
          ),
      ),
    [],
  );
  const hide = () => setState(undefined);

  if (!state) {
    return null;
  }

  return (
    <Modal isOpen onCancel={hide}>
      <ModalWarning>
        <ModalTitle>{state.title || 'An unknown error occurred'}</ModalTitle>
        <ModalMessage>{errMessage(state)}</ModalMessage>
      </ModalWarning>
      <ModalFooter>
        <BtnSecondary onClick={hide}>Dismiss</BtnSecondary>
      </ModalFooter>
    </Modal>
  );
}
