import { showError } from '@components/app-error';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { ManualDom } from '@components/manual-dom';
import { Modal, ModalTitle } from '@components/modal';
import { showModalForm } from '@components/modal-form';
import { Spinner } from '@components/spinner';
import { FilePickerResult, uploadFile } from 'client/lib/filepicker';
import { useMemo, useState } from 'preact/hooks';
import { renderMediaPlayer } from './media-player';

interface Props {
  url: string;
  type: string;
  isPublic: boolean;
}

async function getVideoImage(video: HTMLVideoElement) {
  const canvas = document.createElement('canvas');
  canvas.height = video.videoHeight;
  canvas.width = video.videoWidth;
  const ctx = canvas.getContext('2d')!;
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (b) => (b ? resolve(b) : reject(new Error(`Failed to capture image`))),
      'image/jpeg',
    );
  });
}

export function showVideoPosterModal({ type, url, isPublic }: Props) {
  return showModalForm<FilePickerResult>(({ resolve }) => {
    const [video, onVideoElement] = useState<HTMLVideoElement | undefined>(undefined);
    const player = useMemo(
      () =>
        renderMediaPlayer({
          isResolved: url.startsWith('blob:'),
          type,
          url,
          // We always use a 100:X ratio, so 56 is roughly 16:9 which is good
          // for the poster picker regardless of the video's actual ratio. This
          // allows us to fit the video into the modal.
          ratio: 56,
          // Start loading the video immediately so we can capture a frame.
          load: 'visible',
          onVideoElement,
        }),
      [],
    );

    const [saving, setSaving] = useState(false);
    const loading = !video;

    const captureImage = async () => {
      if (!video) {
        return;
      }
      setSaving(true);
      try {
        const img = await getVideoImage(video);
        const result = await uploadFile({
          file: img,
          name: 'Poster',
          isPublic,
        }).promise;
        if (result) {
          resolve(result);
        } else {
          throw new Error('Upload canceled');
        }
      } catch (err) {
        setSaving(false);
        showError(err);
      }
    };

    const hide = () => resolve();

    return (
      <Modal onCancel={hide} isOpen size="w-readable max-w-full" padding="p-8 pb-0">
        {saving && (
          <header>
            <h2 class="text-center text-2xl text-gray-600">Saving poster image...</h2>
            <div class="my-4 flex items-center justify-center">
              <Spinner class="border-indigo-400" />
            </div>
          </header>
        )}
        {loading && (
          <div class="my-4 flex items-center justify-center absolute top-1/2 inset-x-0">
            <Spinner class="border-indigo-400" />
          </div>
        )}
        {!saving && !loading && (
          <ModalTitle>
            <h2 class="text-center font-bold text-gray-600 mb-2">Choose a poster image</h2>
            <p class="text-gray-500 text-center px-8 mb-4 text-sm">
              Seek the video to the frame you want students to see before the video is played.
            </p>
          </ModalTitle>
        )}
        {!saving && (
          <section class="w-full rounded-lg" style={{ aspectRatio: '16/9' }}>
            <ManualDom el={player} />
          </section>
        )}
        {!saving && !loading && (
          <footer class="bg-white sticky bottom-0 pt-6 pb-4 text-right">
            <BtnSecondary onClick={hide}>Cancel</BtnSecondary>
            <BtnPrimary class="ml-2" disabled={!video} onClick={captureImage}>
              Set Poster Image
            </BtnPrimary>
          </footer>
        )}
      </Modal>
    );
  });
}
