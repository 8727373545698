import { ModuleNavTitle, State } from '@components/module-helpers';
import { useMemo } from 'preact/hooks';
import { ProgressBar } from '@components/progress-bar';
import { URLS } from 'shared/urls';
import { useScrollRef } from 'client/lib/hooks';
import { StudentPageNavHeader } from '@components/page-nav-bar';
import { LessonBadges } from '@components/lesson-badges';
import { useIntl } from 'shared/intl/use-intl';
import { emptyLessonTitle } from 'shared/terminology';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { Button } from '@components/buttons';
import { IcoExternalLink } from '@components/icons';

export function HiddenModulesNav({
  modules,
  course,
  currentLessonId,
}: {
  modules: State['modules'];
  course: State['course'];
  currentLessonId?: UUID;
}) {
  return (
    <span class="p-6 py-2 hidden md:block dark:opacity-50">
      {course.modules.map((moduleId) => {
        const module = modules[moduleId];

        return (
          <>
            <span class="block w-4 mb-2 h-0.5 bg-gray-400 rounded" />
            {module.lessons.map((lessonId) => (
              <span
                key={lessonId}
                class={`block w-4 ml-1 mb-1.5 h-0.5 rounded ${
                  lessonId === currentLessonId ? 'bg-indigo-500' : 'bg-gray-300'
                }`}
              />
            ))}
          </>
        );
      })}
    </span>
  );
}

export function ModulesNav({
  completedLessons,
  currentLessonId,
  modules,
  lessons,
  lessonIds,
  course,
  canManageCourse,
}: {
  currentLessonId?: UUID;
  course: State['course'];
  completedLessons: State['completedLessons'];
  modules: State['modules'];
  lessons: State['lessons'];
  lessonIds: UUID[];
  canManageCourse: boolean;
}) {
  const tenant = useCurrentTenant();
  const intl = useIntl();
  const currentUser = useCurrentUser()!;
  const completedLessonsSet = useMemo(() => new Set(completedLessons), [completedLessons]);
  const scrollRef = useScrollRef();

  return (
    <div class="pb-96">
      <div class="mb-6 text-gray-700 dark:text-gray-200">
        <StudentPageNavHeader title={intl('Modules & Lessons')} />
        <div class="mb-2 flex justify-between items-center font-display font-medium text-gray-500 dark:text-gray-200">
          {intl('Course Progress')}
          <span class="text-sm">
            {intl('{completed:number} of {total:number}', {
              completed: completedLessons.length,
              total: lessonIds.length,
            })}
          </span>
        </div>
        <ProgressBar
          progress={(completedLessons.length * 100) / lessonIds.length}
          showPercent={false}
          bgClass="bg-gray-100 dark:bg-gray-600"
          barClass="bg-green-400 dark:bg-green-600"
        />
      </div>
      {course.modules.map((moduleId) => {
        const module = modules[moduleId];
        const firstLesson = lessons[module.lessons[0]];

        return (
          <div key={moduleId} class="flex flex-col py-4 relative space-y-1 mb-2">
            <a
              class="mb-4 text-gray-500 dark:text-gray-400"
              href={
                firstLesson
                  ? URLS.student.lesson({
                      course,
                      lesson: firstLesson,
                    })
                  : undefined
              }
            >
              <ModuleNavTitle
                module={module}
                accessFormat={course.accessFormat}
                canManageCourse={canManageCourse}
              />
            </a>
            <div class="ml-0.5 border-l-2 border-gray-200 dark:border-gray-800 dark:text-gray-400">
              {module.lessons.map((a) => {
                const isSelected = a === currentLessonId;
                const lesson = lessons[a];
                const isComplete = completedLessonsSet.has(a);

                return (
                  <span
                    key={a}
                    ref={isSelected ? scrollRef : undefined}
                    class={`mr-px tex-sm flex flex-col relative w-full hover:text-indigo-600 dark:hover:text-white hover:theme-link ${
                      isSelected
                        ? 'js-scroll-to relative z-10 text-indigo-600 theme-link dark:text-sky-400'
                        : 'text-gray-500 dark:text-gray-400'
                    }`}
                  >
                    <a
                      id={`lesson-${a}`}
                      class={`p-4 py-2 mb-1 text-inherit font-normal flex items-center`}
                      href={URLS.student.lesson({
                        course,
                        lesson,
                      })}
                    >
                      <span class="inline-flex">
                        <span
                          class={`rounded-full h-1.5 w-1.5 -ml-5 relative z-10 ${
                            isSelected
                              ? 'bg-indigo-400 dark:bg-sky-400 bg-theme-primary'
                              : isComplete
                              ? 'bg-green-300 dark:bg-green-900'
                              : ''
                          }`}
                        ></span>
                      </span>
                      <span
                        class={`grow whitespace-nowrap text-ellipsis overflow-hidden ${
                          isSelected ? 'font-semibold' : ''
                        }`}
                      >
                        {lesson.title || emptyLessonTitle(tenant)}
                      </span>
                      <LessonBadges
                        lesson={lesson}
                        hasDiscussion={!!lesson.discussion?.isEnabled}
                        isSelected={isSelected}
                        isComplete={isComplete}
                      />
                    </a>
                  </span>
                );
              })}
            </div>
          </div>
        );
      })}
      {course.hasEnabledCert && (
        <footer class="border-t border-gray-500/50 pt-6 mt-4 border-dashed">
          <Button
            href={URLS.student.certificate({ course, userId: currentUser.id })}
            target="_blank"
            class="flex gap-2 border border-gray-200 dark:border-gray-700 rounded-md p-2 items-center justify-center hover:bg-gray-100 text-inherit dark:hover:bg-gray-700 active:bg-gray-500"
          >
            <span>{intl('Certificate of Completion')}</span>
            <IcoExternalLink />
          </Button>
        </footer>
      )}
    </div>
  );
}
