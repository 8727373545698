import { SummaryStat } from './summary-section';
import { Props, setOrderBy } from './state';
import * as fmt from 'shared/payments/fmt';
import { Cell, HCell, SortableColumn } from './table';
import { UserProfile } from './user-profile';
import { compactDate } from 'shared/dateutil';
import { URLS } from 'shared/urls';
import { IcoTag } from '@components/icons';
import { urls } from './urls';

export function PaymentStats({ course, revenue }: Props['data']) {
  return (
    <>
      <SummaryStat label="# Signups">{(course.numStudents || 0).toLocaleString()}</SummaryStat>
      {!revenue.length && <SummaryStat label="Revenue">0</SummaryStat>}
      {revenue.map((x) => (
        <SummaryStat key={x.currency} label={`Revenue (${x.currency})`}>
          {fmt.price({ priceInCents: x.amount, currency: x.currency })}
        </SummaryStat>
      ))}
    </>
  );
}

export function PaymentsTable({ state, setState, route: { params } }: Props) {
  const { opts, students, course } = state;
  return (
    <div class="table table-auto border w-full divide-y">
      <div class="table-row bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        <SortableColumn col="name" orderBy={opts} onClick={() => setOrderBy(setState, 'name')}>
          {state.terminology}
        </SortableColumn>

        <SortableColumn
          col="signupDate"
          hiddenOnMobile
          orderBy={opts}
          onClick={() => setOrderBy(setState, 'signupDate')}
        >
          Signup
        </SortableColumn>

        <HCell>Price Point</HCell>

        <HCell>Payment Status</HCell>

        <HCell>Latest Payment</HCell>

        <HCell class="text-right">Total Paid</HCell>

        <HCell>Access</HCell>
      </div>
      {students.data.map((s) => {
        return (
          <a
            key={s.id}
            class="table-row text-inherit hover:bg-gray-50 text-sm border-t font-medium"
            href={urls.showStudent({
              ...params,
              courseId: params.courseId,
              userId: s.id,
              tab: 'payments',
              baseUrl: course.isProduct ? urls.productsBaseUrl : urls.coursesBaseUrl,
            })}
          >
            <Cell key="student">
              <UserProfile user={s} />
            </Cell>
            <Cell key="signup">{compactDate(s.signupDate)}</Cell>

            <Cell key="pricepoint">
              <span class="flex flex-col justify-center leading-5">
                <span>
                  {s.wasInvited && s.bundleId && (
                    <a href={URLS.guide.course({ courseId: s.bundleId })}>Invited through bundle</a>
                  )}
                  {s.wasInvited && !s.bundleId && <span>Invited</span>}
                  {!s.wasInvited && s.priceName && <span>{s.priceName}</span>}
                  {s.gifterName && <span> (Gifted)</span>}
                  {!s.wasInvited && !s.priceName && !s.gifterName && (
                    <span>External payment or invitation</span>
                  )}
                </span>
              </span>
              {s.couponCode && (
                <span class="mt-1 flex items-center text-inherit text-sm opacity-75">
                  <IcoTag class="w-3 h-3 mr-1 opacity-75" />
                  {s.couponCode}
                </span>
              )}
            </Cell>

            <Cell key="status">
              {s.paymentType && (
                <span class="flex items-center gap-2">
                  {s.purchaseStatus && (
                    <span
                      class={`${
                        s.purchaseStatus === 'active'
                          ? 'bg-green-50 border-green-200 text-green-600'
                          : s.purchaseStatus === 'complete'
                          ? 'bg-gray-50 border-gray-200 text-gray-600'
                          : 'bg-red-50 border-red-200 text-red-500'
                      } px-1 border  font-semibold rounded text-xs capitalize`}
                    >
                      {s.paymentType === 'paymentplan' && 'Plan '}
                      {s.paymentType === 'subscription' && 'Subscription '}
                      {s.paymentType === 'paid' && 'Payment '}
                      {s.purchaseStatus}
                    </span>
                  )}
                </span>
              )}
            </Cell>

            <Cell key="latestpayment">
              {s.lastPaymentDate && (
                <span
                  class={`flex gap-2 items-center ${s.lastPaymentFailed ? 'text-red-600' : ''}`}
                >
                  <span class="opacity-75 min-w-12">{compactDate(s.lastPaymentDate)}</span>
                  <span class="font-semibold">
                    {s.lastPaymentAmount &&
                      fmt.price({
                        priceInCents: s.lastPaymentAmount,
                        currency: s.currency || 'USD',
                      })}
                    {s.lastPaymentFailed && <span class="ml-2 font-semibold">Failed</span>}
                  </span>
                </span>
              )}
            </Cell>

            <Cell key="totalpaid">
              <span class="flex justify-end font-bold">
                {s.totalPaid &&
                  fmt.price({ priceInCents: s.totalPaid, currency: s.currency || 'USD' })}
              </span>
            </Cell>

            <Cell key="access">
              <span class="flex gap-2 items-center">
                <span
                  class={`rounded-full inline-flex w-2.5 h-2.5 border-2 ${
                    s.status === 'enabled'
                      ? 'bg-green-500 border-green-200'
                      : s.status === 'disabled'
                      ? 'bg-red-500 border-red-200'
                      : s.status === 'expired'
                      ? 'bg-yellow-50 border-yellow-200'
                      : ''
                  }`}
                ></span>
                {s.status === 'enabled' && <span class="text-green-600">Granted</span>}
                {s.status === 'disabled' && <span class="text-red-600">Revoked</span>}
                {s.status === 'expired' && <span class="text-yellow-600">Expired</span>}
              </span>
            </Cell>
          </a>
        );
      })}
    </div>
  );
}
