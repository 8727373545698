import { globalConfig, emptyContext, useAuth, Auth } from 'client/lib/auth';
import { createHistoryRouter } from 'client/lib/app-route';
import { AppRoute } from 'client/lib/app-route/types';
import { createContext, ComponentChildren } from 'preact';
import { useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { LoadingIndicator } from '@components/loading-indicator';
import { RouteDef } from './types';
import { showError } from '@components/app-error';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { hasLevel } from 'shared/auth';
import { initSentry, setSentryUser } from 'client/lib/sentry';
import { processRouteParams } from './process-route-params';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { redirectWithFlashError } from 'client/lib/flash-errors';
import { Locales } from 'server/types';
import dayjs from 'dayjs';
import { toQueryString } from 'shared/urls';
import { ErrorPage } from '@components/error-page';
import { useBeacon, HelpscoutMiniButton } from '@components/helpscout-beacon';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { DefaultSpinner } from '@components/spinner';

export * from './types';

const v1CoursePattern = /^\/courses\/[0-9]+(\/|$)/;

function isV1URL(url: string) {
  return v1CoursePattern.test(url);
}

/**
 * Simply here to give us a unique comparison to see if an error
 * is an errRedirectAction or not.
 */
const errRedirectAction = {};

/**
 * A special object that the router can catch and use to redirect.
 * Handy when needing to do a redirect from a page load.
 */
export const errRedirect = (href: string) => ({ errRedirectAction, href });

const dayjsLocales: Record<Locales, any> = {
  en: () => import('dayjs/locale/en'),
  es: () => import('dayjs/locale/es'),
};

export const RouteContext = createContext<AppRoute>({
  auth: emptyContext,
  url: '',
  params: {},
  data: undefined,
  def: {
    url: '',
    render: () => null,
  },
});

/*
 * Returns all the route params.
 */
export function useRouteParams() {
  return useContext(RouteContext).params;
}

/*
 * Returns the route data.
 */
export function useRouteData<T>() {
  return useContext(RouteContext).data as T;
}

/*
 * Returns the route definition.
 */
export function useRouteDef() {
  return useContext(RouteContext).def;
}

/**
 * The application router.
 */
export const router = createHistoryRouter<RouteDef>();

/**
 * A helper for defining a route that contains sub-state loading logic.
 */
export function addSubroute<T>(def: RouteDef<T>) {
  return router.add(def);
}

interface RouteInst {
  def: RouteDef<any>;
  params: Record<string, string>;
  key?: string;
  url: string;
  auth: Auth;
  data?: any;
}

/**
 * Wraps state loading in a standard error handling block.
 */
async function loadState(fn: () => Promise<unknown>, routeParams?: Record<string, string>) {
  try {
    await fn();
  } catch (err) {
    // Certain Boom errors contain a domain, which indicates we need to
    // redirect to the specified domain.
    if (err.data?.domain) {
      const url = new URL(location.href);
      url.hostname = err.data.domain;
      location.assign(url);
      return;
    }

    // Certain Boom errors contain a redirect URL in their data, and we ought
    // to use it.
    if (err.data?.redirectTo) {
      router.goto(err.data.redirectTo);
      return;
    }

    // In case of unauthorized errors, redirect them back to homepage with a flashed error message
    if (
      ['Forbidden', 'Unauthorized'].includes(err?.error) ||
      [401, 403].includes(err?.statusCode)
    ) {
      const { courseId } = routeParams || {};

      if (courseId) {
        router.goto(
          `/courses/${courseId}/not-authorized?${toQueryString({
            ...routeParams,
            redirect: encodeURIComponent(location.href.slice(location.origin.length)),
          })}`,
        );
        return;
      }

      redirectWithFlashError('/', {
        title: `${err.statusCode} ${err.error}`,
        error: err,
        message: 'Not authorized to view this page',
      });
      return;
    }
    if (err.errRedirectAction === errRedirectAction) {
      router.goto(err.href);
      return;
    }
    showError(err);
  }
}

/**
 * Render the current page and handle sub-state loading based on route changes.
 */
function CurrentPage(props: { route: RouteInst; initialState: any; children?: ComponentChildren }) {
  const [state, setState] = useState(props.initialState);
  const [isLoading, setIsLoading] = useState(false);
  const route = useRef(props.route);
  const { params, def } = props.route;
  route.current = props.route;
  route.current.data = state;

  useDidUpdateEffect(async () => {
    const loadSubroute = def.loadSubroute;
    if (!loadSubroute) {
      window.scrollTo(0, 0);
      return;
    }
    try {
      setIsLoading(true);
      await loadState(() => loadSubroute(params, setState), params);
    } finally {
      if (params === route.current.params) {
        window.scrollTo(0, 0);
        setIsLoading(false);
      }
    }
  }, [params]);

  return (
    <RouteContext.Provider value={route.current}>
      {isLoading && <LoadingIndicator delay={0} />}
      <def.render route={route.current} data={state} state={state} setState={setState} />
      {props.children}
    </RouteContext.Provider>
  );
}

/**
 * Create page creates a new page component. This is easier than using keys
 * appropriately, as we want to do our initialization based on complex logic.
 */
const createPage = (initialState: any) =>
  function PageInst(props: { route: RouteInst; children?: ComponentChildren }) {
    return (
      <CurrentPage route={props.route} initialState={initialState}>
        {props.children}
      </CurrentPage>
    );
  };

/**
 * Load the current route definition.
 */
function useCurrentRoute(auth: Auth) {
  const [state, setState] = useState<RouteInst | undefined>(undefined);
  const route = useRef(state);
  useEffect(() => {
    router.init((params, def) => {
      params = processRouteParams(params);
      setState({
        def,
        params,
        url: def.url,
        key: def.key?.(params) || '',
        auth,
      });
    });
  }, []);
  route.current = state;
  return route;
}

/**
 * Render the course CSS link tag if appropriate.
 */
function CourseCSS({ courseId }: { courseId: string }) {
  return <link rel="stylesheet" href={`/api/course_css/${courseId}.css`} as="style" />;
}

/**
 * Initialize all of our various trackers, and return the config.
 */
function useLoadConfiguration() {
  const configuration = globalConfig();

  useEffect(() => {
    if (configuration) {
      initSentry(configuration);
    }
  }, [configuration]);

  return configuration;
}

/**
 * Render the tracker scripts.
 */
function Trackers({ route }: { route: RouteInst }) {
  const configuration = globalConfig();
  const def = route.def;
  const enableBeacon = def.authLevel === 'guide' || !!def.showBeacon;

  useBeacon({
    user: configuration.user,
    enable: enableBeacon,
    beaconId: configuration?.helpscoutBeaconId,
  });

  if (!enableBeacon) {
    return null;
  }

  // Load beacon script for non-student pages
  // if it's not already loaded
  return (
    <div id="trackers">
      {!window.Beacon && (
        <script type="text/javascript">{`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`}</script>
      )}
      <HelpscoutMiniButton />
    </div>
  );
}

function isSamePage(a?: RouteInst, b?: RouteInst) {
  return a?.def.render === b?.def.render && a?.key === b?.key;
}

/**
 * We're on a URL that looks like a v1-course route. We'll ask the backend
 * for the updated URL and redirect if it finds one.
 */
function V1Course(props: { url: string }) {
  const data = useTryAsyncData(async () => {
    const { newUrl } = await rpx.courses.getV1Redirect({ v1Url: props.url });
    if (newUrl) {
      // We've found a v1 redirect. We'll do a hard-redirect and then wait
      // for the page to refresh rather than displaying a 404.
      location.assign(newUrl);
      await new Promise(() => {});
    }
    return { isV1: true };
  }, []);

  if (data.isLoading) {
    return <DefaultSpinner />;
  }

  return <ErrorPage title="404 | Not found" />;
}

function courseMigrating(url: string, tenant: { isMigrating?: boolean }) {
  return function CourseMigrating() {
    if (tenant.isMigrating) {
      return (
        <ErrorPage
          title="Upgrade in progress..."
          subtitle="Hold tight! We're moving this course to the newest version of our hosting platform. Please check back later."
        />
      );
    }

    return <V1Course url={url} />;
  };
}

/**
 * Returns the course id for the specified route, if we're on a themed course
 * page.
 */
function themedCourseId(route?: RouteInst) {
  if (route?.params.courseId && (route?.def.isPublic || route.def.authLevel === 'student')) {
    return route.params.courseId;
  }
}

/**
 * Watch for route changes, perform data / state loading, etc.
 */
export function RouterPage({
  prefix,
  children,
}: {
  prefix?: ComponentChildren;
  children?: ComponentChildren;
}) {
  const auth = useAuth();
  const route = useCurrentRoute(auth);
  const loadedRoute = useRef(route.current);
  const configuration = useLoadConfiguration();
  const [{ Page }, rawSetPage] = useState<{ Page?: ReturnType<typeof createPage> }>({});
  const [isLoading, setIsLoading] = useState(true);
  const setPage = useMemo(
    () => (pg: ReturnType<typeof createPage>, route: RouteInst) => {
      loadedRoute.current = route;
      rawSetPage({ Page: pg });
      window.scrollTo(0, 0);
    },
    [],
  );

  // Update Sentry user and potentially start recording
  // the session when auth changes
  useEffect(() => {
    setSentryUser(auth.user);
  }, [auth.user]);

  const shouldGoToLogin = (def?: RouteInst['def']) => {
    return def && !def.isPublic && !auth.user;
  };

  useAsyncEffect(
    async function initializeNewPage() {
      // The current route has completely changed, and we need to initialize and
      // render a brand new page component.
      const currentRoute = route.current;
      if (!currentRoute) {
        return;
      }

      try {
        setIsLoading(true);
        const { def } = currentRoute;
        const { isPublic, authLevel } = def;

        // Detect a v1 course URL, and show a relevant error page
        if (isV1URL(location.pathname)) {
          setPage(courseMigrating(location.href, configuration.tenant), currentRoute);
          return;
        }

        if (shouldGoToLogin(currentRoute?.def)) {
          router.goto(
            `/login?${toQueryString({
              ...route.current?.params,
              redirect: encodeURIComponent(location.href.slice(location.origin.length)),
            })}`,
          );
          return;
        }

        if (authLevel && !hasLevel(auth.user, authLevel)) {
          router.goto('/courses');
          return;
        }

        // Load the appropriate locale for the current route.
        // if the tenant has a non-english locale.
        if (configuration.tenant.locale !== 'en') {
          const useEnglish = authLevel !== 'student' && !isPublic;
          const newLocale = useEnglish ? 'en' : configuration.tenant.locale;

          if (dayjs.locale() !== newLocale) {
            dayjsLocales[newLocale]().then(() => {
              dayjs.locale(newLocale);
            });
          }
        }

        const load = def.load;

        if (!load) {
          setPage(createPage(undefined), currentRoute);
          return;
        }

        await loadState(async () => {
          const initialState = await load(currentRoute);
          if (isSamePage(currentRoute, route.current)) {
            setPage(createPage(initialState), currentRoute);
          }
        }, route.current?.params);
      } catch (err) {
        showError(err);
      } finally {
        if (isSamePage(currentRoute, route.current)) {
          setIsLoading(false);
        }
      }
    },
    [route.current?.def?.render, route.current?.key, auth.user?.id],
  );

  if (isSamePage(loadedRoute.current, route.current)) {
    loadedRoute.current = route.current;
  }

  // When navigating away from a themed course, we want to keep the current
  // stylesheet loaded until the next page renders, so we use loadedRoute as
  // our fallback here. Otherwise, what happens is, the current page (course
  // overview, say) goes from themed to unthemed for a moment before the page
  // rerenders to the new page (e.g. "my courses")
  const themedId = themedCourseId(route.current) || themedCourseId(loadedRoute.current);

  if (shouldGoToLogin(loadedRoute.current?.def) && !isV1URL(location.pathname)) {
    // Prevent an authenticated-only page from rendering if the user is now null
    // this used to happen in some edge cases. This is just a safeguard against
    // it continuing to happen.
    return null;
  }

  return (
    <>
      {themedId && <CourseCSS courseId={themedId} />}
      {Page && loadedRoute.current && configuration && (
        <>
          <RouteContext.Provider value={loadedRoute.current}>{prefix}</RouteContext.Provider>
          <Page route={loadedRoute.current}>{children}</Page>
          <Trackers route={loadedRoute.current} />
        </>
      )}
      {isLoading && <LoadingIndicator delay={0} />}
    </>
  );
}
