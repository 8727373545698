import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { showError } from '@components/app-error';
import { IcoChevronLeft } from '@components/icons';
import { showModalForm } from '@components/modal-form';
import { useState } from 'preact/hooks';
import { Modal, ModalContent, ModalFooter, ModalTitle } from '@components/modal';
import { showToast } from '@components/toaster';
import { TenantCoursesList } from '../admin-courses/course-list';
import { InstantCourse } from './index';
import { rpx } from 'client/lib/rpx-client';

interface Props {
  existingCourses: UUID[];
  onSuccess: (course: InstantCourse) => void;
}

const store = rpx.instantCourses;

export function showAddCourseModal({ existingCourses, onSuccess }: Props) {
  showModalForm(({ resolve }) => {
    const [selectedCourse, setSelectedCourse] = useState<InstantCourse | undefined>(undefined);
    const [isAdding, setIsAdding] = useState(false);

    async function addCourse() {
      if (!selectedCourse) {
        return;
      }

      setIsAdding(true);
      try {
        await store.addCourse({
          courseId: selectedCourse.id,
          title: selectedCourse.title,
          category: selectedCourse.category,
        });
        showToast({
          title: 'Succeeded',
          message: `'${selectedCourse.title}' has been added to Instant Courses!`,
          type: 'ok',
        });
        onSuccess(selectedCourse);
        resolve(true);
      } catch (err) {
        showError(err);
      } finally {
        setIsAdding(false);
      }
    }

    return (
      <Modal size="max-w-5xl" isOpen onCancel={() => resolve(undefined)}>
        {selectedCourse && (
          <>
            <ModalTitle>
              <Button class="inline-flex items-center" onClick={() => setSelectedCourse(undefined)}>
                <IcoChevronLeft class="w-4 h-4 opacity-75 mr-1" />
                Back
              </Button>
            </ModalTitle>
            <ModalContent mode="none">
              Do you want to add <em>{selectedCourse.title}</em> course to the Instant Courses list?
            </ModalContent>
            <ModalFooter>
              <BtnSecondary onClick={resolve}>Cancel</BtnSecondary>
              <BtnPrimary onClick={addCourse} isLoading={isAdding}>
                Yes, add the course
              </BtnPrimary>
            </ModalFooter>
          </>
        )}
        {!selectedCourse && (
          <>
            <ModalTitle>
              Choose a template course to add it to Instant Courses list
              <span class="block text-xs mt-2">
                Make sure you mark the course as template before adding to instant courses.
              </span>
            </ModalTitle>
            <TenantCoursesList
              type="instantCourseSelections"
              onItemSelect={(course) => {
                if (existingCourses.includes(course.id)) {
                  showToast({
                    title: 'Could not add course',
                    message: 'This course is already in instant courses list.',
                    type: 'warn',
                  });
                  return;
                }
                setSelectedCourse({
                  id: course.id,
                  title: course.title,
                  category: 'General',
                  description: '',
                  isGlobal: false,
                  guideId: course.guide.id,
                });
              }}
            />
            <ModalFooter>
              <BtnSecondary onClick={resolve}>Cancel</BtnSecondary>
            </ModalFooter>
          </>
        )}
      </Modal>
    );
  });
}
