import { BtnPreWarning, BtnSecondary, Button } from '@components/buttons';
import { FontPicker, FontPreview } from '@components/font-picker';
import { IcoHome, IcoTrash, IcoX } from '@components/icons';
import { filepicker } from 'client/lib/filepicker';
import { Brand, Data } from './load';
import { ColorPicker } from '@components/color-picker';
import { BrandRow } from 'server/types';
import { autoColor } from 'shared/colors';

type CustomTheme = Pick<BrandRow, 'courseNavBg' | 'courseNavFg'>;

function ThemeColorPicker(props: {
  text: string;
  color?: string;
  hideClear?: boolean;
  onChange(color?: string): void;
}) {
  return (
    <span class="flex justify-between gap-2">
      <ColorPicker name="" class="flex-grow" color={props.color} onPick={props.onChange}>
        <span class="whitespace-nowrap">{props.text}</span>
      </ColorPicker>
      <Button
        class={`flex items-center gap-2 ${props.hideClear ? 'invisible' : ''}`}
        onClick={() => props.onChange()}
      >
        <IcoX />
      </Button>
    </span>
  );
}

function CustomColorPane(props: {
  theme: CustomTheme;
  setBg(c: string): void;
  setFg(c: string): void;
}) {
  const { theme, setBg, setFg } = props;
  const { courseNavBg } = theme;
  const fgcolor = theme.courseNavFg || autoColor(courseNavBg);

  return (
    <div class="flex flex-col text-sm gap-2">
      <ThemeColorPicker
        color={courseNavBg}
        onChange={setBg}
        text="Background …"
        hideClear={!theme.courseNavBg}
      />
      <ThemeColorPicker
        color={fgcolor}
        onChange={setFg}
        text="Text …"
        hideClear={!theme.courseNavFg}
      />
    </div>
  );
}

export function CoursePreview({
  brand,
  setBrand,
  fonts,
  defaultFontId,
  colorSchemes,
  defaultColorSchemeId,
}: Omit<Data, 'brands'> & {
  brand: Brand;
  setBrand: (fn: (b: Brand) => Brand) => void;
}) {
  const fontId = brand.contentFont || defaultFontId;
  const colorSchemeId = brand.colorSchemeId || defaultColorSchemeId;
  const font = fonts.find((x) => x.id === fontId);
  const colorScheme = colorSchemes.find((x) => x.id === colorSchemeId);
  const pickCourseLogo = async () => {
    const result = await filepicker({
      sources: ['filepicker', 'takephoto'],
      requireCrop: true,
      cropRatio: 1,
      accept: 'image/*',
    });
    if (result?.publicUrl) {
      setBrand((b) => ({ ...b, courseImageUrl: result.publicUrl }));
    }
  };

  const navStyle = brand.customColorScheme
    ? {
        backgroundColor: brand.courseNavBg,
        color: brand.courseNavFg || autoColor(brand.courseNavBg),
      }
    : colorScheme?.navStyle;

  return (
    <div class="w-full rounded-sm flex flex-col bg-gray-50 p-4 space-y-4">
      <div class="flex">
        <div class="flex flex-col space-y-6 w-56">
          <div class="whitespace-nowrap">
            <label class="mb-1 block">Typography</label>
            <FontPicker
              fonts={fonts}
              value={fontId}
              onPick={(font) =>
                setBrand((b) => ({
                  ...b,
                  contentFont: font.id,
                }))
              }
            />
          </div>
          <div>
            <label class="mb-1 block">Color scheme</label>
            <select
              class="ruz-input hover:border-indigo-600 cursor-pointer"
              value={
                brand.customColorScheme ? 'custom' : brand.colorSchemeId || defaultColorSchemeId
              }
              onChange={(e: any) => {
                const isCustom = e.target.value === 'custom';
                setBrand((b) => ({
                  ...b,
                  colorSchemeId: isCustom ? b.colorSchemeId : e.target.value,
                  customColorScheme: isCustom,
                }));
              }}
            >
              <option key="custom" value="custom">
                Custom
              </option>
              {colorSchemes.map((scheme) => (
                <option key={scheme.id} value={scheme.id}>
                  {scheme.title}
                </option>
              ))}
            </select>
          </div>
          {brand.customColorScheme && (
            <CustomColorPane
              theme={brand}
              setFg={(c) => setBrand((b) => ({ ...b, courseNavFg: c }))}
              setBg={(c) => setBrand((b) => ({ ...b, courseNavBg: c }))}
            />
          )}
          <div>
            <label class="mb-1 block">Course logo</label>
            {brand.courseImageUrl && (
              <BtnPreWarning
                class="bg-white cursor-pointer w-full space-x-2"
                onClick={pickCourseLogo}
              >
                <IcoTrash />
                <span>Remove logo</span>
              </BtnPreWarning>
            )}
            {!brand.courseImageUrl && (
              <BtnSecondary
                class="bg-white hover:border-indigo-600 cursor-pointer w-full py-3"
                onClick={pickCourseLogo}
              >
                <span>Browse ....</span>
              </BtnSecondary>
            )}
            <p class="opacity-75 mt-4">
              The course logo is a square image which shows up on the cards on the "my courses"
              page. Any courses belonging to this brand will have this logo by default, but this can
              be overriden in the course style pages.
            </p>
          </div>
        </div>
        <div class="grow ml-8 bg-white rounded-md shadow flex">
          <div class="bg-gray-800 text-gray-300 w-24" style={navStyle}>
            <span class="flex flex-col justify-center items-center py-6 px-2 gap-2">
              <IcoHome />
              <span class="text-xs text-center">Sample Icon &amp; Text</span>
            </span>
          </div>
          <div class="p-20 grow">
            <div class="mb-8 pb-8 border-b border-dashed">
              {font && <FontPreview font={font} />}
            </div>
            <Button
              class="ring-1 ring-transparent hover:ring-indigo-600 rounded-lg overflow-hidden shadow inline-block w-56"
              onClick={pickCourseLogo}
            >
              <span class="aspect-square bg-gray-50 flex items-center justify-center">
                {brand.courseImageUrl && <img src={brand.courseImageUrl} class="max-w-full" />}
                {!brand.courseImageUrl && <span class="opacity-50">No image</span>}
              </span>
              <span class="block border-t p-4 text-left text-opacity-75">Example course card</span>
              <span class="block border-t border-dashed text-opacity-75 p-4 text-left">
                Click to change the logo.
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
