import type { AppliedTo, Offer, Upsell } from './types';

const tmpStorageKey = 'tmp:ux:upsells';

/**
 * A dummy id generator just for the proof-of-concept.
 */
const makeId = () => Date.now().toString(16) + '-' + Math.round(Math.random() * 1000).toString(16);

const saveUpsells = (upsells: Upsell[]) =>
  localStorage.setItem(tmpStorageKey, JSON.stringify(upsells));

/* *
 * Temporary: a client-only store to facilitate UI iteration.
 */
export const store = {
  async getMyUpsells(): Promise<Upsell[]> {
    return (JSON.parse(localStorage.getItem(tmpStorageKey) || '[]') as Upsell[]).map((x) => ({
      ...x,
      numApplied: x.appliedTo.length,
      numOffers: x.offers.length,
    }));
  },
  async getUpsell({ id }: { id: string }) {
    const upsells = await store.getMyUpsells();
    const i = upsells.findIndex((x) => x.id === id);
    if (i < 0) {
      throw new Error(`Upsell not found.`);
    }
    const [upsell] = upsells.splice(i, 1);
    saveUpsells([upsell, ...upsells]);
    return upsell;
  },
  async createUpsell(data: Omit<Upsell, 'id'>) {
    const upsell: Upsell = {
      ...data,
      id: makeId(),
    };
    const upsells = await store.getMyUpsells();
    saveUpsells([upsell, ...upsells]);
    return upsell;
  },
  async saveUpsell(upsell: Upsell) {
    const upsells = await store.getMyUpsells();
    saveUpsells(upsells.map((x) => (x.id === upsell.id ? upsell : x)));
    return upsell;
  },
  async deleteUpsell({ id }: { id: string }) {
    const upsells = await store.getMyUpsells();
    saveUpsells(upsells.filter((x) => x.id !== id));
  },
  async createOffer(data: Omit<Offer, 'id'>) {
    const offer: Offer = { ...data, id: data.course.id };
    const upsells = await store.getMyUpsells();
    saveUpsells(
      upsells.map((u) =>
        u.id === data.upsellId
          ? {
              ...u,
              offers: [...u.offers, offer],
            }
          : u,
      ),
    );
    return offer;
  },
  async saveOffer(data: Offer) {
    const upsells = await store.getMyUpsells();
    saveUpsells(
      upsells.map((u) =>
        u.id === data.upsellId
          ? {
              ...u,
              offers: u.offers.map((x) => (x.id === data.id ? data : x)),
            }
          : u,
      ),
    );
    return data;
  },
  async deleteOffer(data: { id: string; upsellId: string }) {
    const upsells = await store.getMyUpsells();
    const upsell = upsells.find((u) => u.id === data.upsellId);
    if (!upsell) {
      return;
    }
    const offers = upsell.offers.filter((x) => x.id !== data.id);
    saveUpsells(upsells.map((u) => (u === upsell ? { ...u, offers } : u)));
    return upsells;
  },
  async saveAppliedTo(data: { upsellId: string; appliedTo: AppliedTo }) {
    const item = data.appliedTo;
    const upsells = await store.getMyUpsells();
    const upsell = upsells.find((u) => u.id === data.upsellId);
    if (!upsell) {
      throw new Error(`Upsell not found.`);
    }
    let appliedTo = (upsell.appliedTo || []).filter((x) => x.course.id !== item.course.id);
    if (item.prices.length) {
      appliedTo = [item, ...appliedTo];
    }
    saveUpsells(upsells.map((u) => (u === upsell ? { ...u, appliedTo } : u)));
  },
  async deleteAppliedTo(data: { upsellId: string; courseId: string }) {
    const upsells = await store.getMyUpsells();
    const upsell = upsells.find((u) => u.id === data.upsellId);
    if (!upsell) {
      return;
    }
    saveUpsells(
      upsells.map((u) =>
        u === upsell
          ? { ...u, appliedTo: u.appliedTo?.filter((x) => x.course.id !== data.courseId) }
          : u,
      ),
    );
    return upsells;
  },
};
