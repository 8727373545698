import { showError } from '@components/app-error';
import { filepicker } from 'client/lib/filepicker';

export async function pickLogo(opts: Parameters<typeof filepicker>[0] = {}) {
  try {
    const result = await filepicker({
      accept: 'image/*',
      sources: ['filepicker'],
      ...opts,
    });
    return result;
  } catch (err) {
    showError(err);
  }
}
