/**
 * The modal used for copying modules and / or lessons to other courses.
 */

import { showError } from '@components/app-error';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { IcoCheck } from '@components/icons';
import { Modal, ModalContent, ModalTitle } from '@components/modal';
import { Pill } from '@components/pill';
import { useRouteParams } from '@components/router';
import { useCurrentTenant } from '@components/router/session-context';
import { Spinner } from '@components/spinner';
import { useState } from 'preact/hooks';
import { Course, ModuleRow } from 'server/types';
import { AsyncSearchableList, Searchable, toSearchable } from './searchable-list';
import { rpx } from 'client/lib/rpx-client';
import { isSchedulable } from 'shared/utils';
import { emptyModuleTitle } from 'shared/terminology';

const store = rpx.modules;

type CopyType = 'module' | 'lesson';
type CopyPane = 'courses' | 'modules' | 'copying' | 'done';
type CourseItem = Pick<Course, 'id' | 'title' | 'accessFormat'> & Searchable;
type ModuleItem = Pick<ModuleRow, 'id' | 'title'> & Searchable;

export interface CopyModalProps {
  type: CopyType;
  item: { id: any; title: string };
  onClose(): void;
  refreshOutline(): void;
}

/**
 * Load the courses, placing the current course at the front of the list.
 */
async function loadCourses(currentCourseId: UUID) {
  const courses = await rpx.courses.getMyCourses({ asGuide: true, asFacilitator: true });
  const currentIndex = courses.findIndex((c) => c.id === currentCourseId);
  courses.unshift(...courses.splice(currentIndex, 1));
  return toSearchable(courses);
}

export function CopyModal({ type, onClose, refreshOutline, item }: CopyModalProps) {
  const tenant = useCurrentTenant();
  const { terminology } = tenant;
  const [title, setTitle] = useState(`Copy of ${item.title}`);
  const [pane, setPane] = useState<CopyPane>('courses');
  const [course, setCourse] = useState<undefined | CourseItem>(undefined);
  const [newUrl, setNewUrl] = useState<string | undefined>(undefined);
  const isEditing = pane === 'courses' || pane === 'modules';
  const currentCourseId = useRouteParams().courseId;
  const isCopyingToSameCourse = course?.id === currentCourseId;
  const typeDesc = type === 'lesson' ? terminology.lesson : terminology.module;

  const makeCopy = async (courseId: UUID, promise: Promise<{ lessonId?: UUID }>) => {
    try {
      const { lessonId } = await promise;
      setNewUrl(`/manage/courses/${courseId}/lessons/${lessonId || ''}`);
      setPane('done');
      if (courseId === currentCourseId) {
        refreshOutline();
      }
    } catch (err) {
      showError(err);
    }
  };

  const onPickCourse = (c: CourseItem) => {
    setCourse(c);
    setPane(type === 'lesson' ? 'modules' : 'copying');
    if (type === 'module') {
      makeCopy(
        c.id,
        store.copyModule({
          title,
          moduleId: item.id,
          toCourseId: c.id,
        }),
      );
    }
  };

  const onPickModule = (module: ModuleItem) => {
    setPane('copying');
    makeCopy(
      course!.id,
      rpx.lessons.copyLesson({
        title,
        lessonId: item.id,
        toModuleId: module.id,
      }),
    );
  };

  return (
    <Modal isOpen onCancel={onClose}>
      <div class="p-2">
        <ModalTitle>
          {pane === 'done' && (
            <div class="flex flex-col items-center justify-center">
              <span class="bg-gradient-to-br border border-green-400 from-green-300 to-green-400 text-white rounded-full p-4 6inline-flex mb-4">
                <IcoCheck class="w-8 h-8" />
              </span>
              <span>Copy complete</span>
            </div>
          )}
          {pane === 'copying' && `Copying ${typeDesc}...`}
          {isEditing && `Copy ${typeDesc}`}
        </ModalTitle>
        <ModalContent mode="none">
          {isEditing && (
            <label class="my-6 flex flex-col">
              <span class="mb-1.5">Title of the {typeDesc}</span>
              <input
                type="text"
                class="ruz-input"
                placeholder={`Title of the ${typeDesc}`}
                value={title}
                onInput={(e: any) => setTitle(e.target.value)}
              />
            </label>
          )}
          {pane === 'courses' && (
            <AsyncSearchableList
              loadItems={() => loadCourses(currentCourseId)}
              onPick={(item) => onPickCourse(item)}
              renderItem={(c) => (
                <span class="flex justify-between">
                  <span>{c.title}</span>
                  {c.id === currentCourseId && (
                    <span class="inline-block ml-2">
                      <Pill color="green">current</Pill>
                    </span>
                  )}
                </span>
              )}
              label={`Copy to ${terminology.course}`}
              placeholder={`Find a ${terminology.course}`}
            />
          )}
          {pane === 'modules' && course && (
            <div>
              <header class="overflow-hidden whitespace-nowrap text-ellipsis mb-6">
                <Button class="underline mr-2" onClick={() => setPane('courses')}>
                  Copy to {terminology.course}
                </Button>
                <em>{course?.title}</em>
              </header>
              <AsyncSearchableList
                loadItems={async () => {
                  const modules = await rpx.courses.getCourseModules({ courseId: course.id });
                  return toSearchable(
                    modules.map((m) => ({ ...m, title: m.title || emptyModuleTitle(tenant) })),
                  );
                }}
                onPick={(item) => onPickModule(item)}
                renderItem={(c) => c.title}
                label={`Copy to ${terminology.module}`}
                placeholder={`Find a ${terminology.module}`}
                autoFocus
              />
            </div>
          )}
          {pane === 'copying' && (
            <div class="p-4 text-center">
              <p class="mb-6 text-gray-500">
                Copying {type} to {terminology.course} <em>{course?.title}</em>.
              </p>
              <Spinner class="w-6 h-6 inline-block border-indigo-500" />
            </div>
          )}
          {pane === 'done' && (
            <div class="mb-4">
              <p class="text-center text-gray-500 mb-8">
                {course && isSchedulable(course.accessFormat) ? (
                  <em>Don't forget to adjust the {type} start date and time!</em>
                ) : (
                  <span>The {type} was succesfully copied.</span>
                )}
              </p>
              <footer class="grid grid-cols-2">
                <BtnSecondary
                  class="mr-2"
                  target={isCopyingToSameCourse ? '' : '_blank'}
                  rel="noopener noreferrer"
                  href={newUrl}
                  onClick={onClose}
                >
                  View the copy
                </BtnSecondary>
                <BtnPrimary class="ml-2" onClick={onClose}>
                  Close
                </BtnPrimary>
              </footer>
            </div>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
}
