import { router } from '@components/router';
import { LoadedProps } from 'client/lib/loaders';
import { AppRoute } from 'client/lib/app-route/types';
import { Offer, Upsell } from './types';
import { store } from './store';
import { OfferPriceForm, PriceType } from './offer-price-form';
import { UpsellsPage } from './upsells-page';
import { UpsellWizard, WizardProgress, WizardSubtitle } from './upsell-wizard';
import { Button } from '@components/buttons';
import { IcoArrowLeft, IcoDotsHorizontal, IcoTrash } from '@components/icons';
import { Picker, UpsellCoursePicker } from './pickers';
import { EditOfferSalesForm } from './offer-edit-sales-form';
import { CourseAndPricePreview } from './course-price-preview';
import { Dropdown } from '@components/dropdown';
import { showConfirmModal } from '@components/modal-form';
import { showError } from '@components/app-error';
import { useCurrentTenant } from 'client/lib/auth';

type Pane = 'pickcourse' | 'pricetype' | 'priceform' | 'salescopy';

type State = {
  page: 'new' | 'edit';
  offer: Partial<Offer>;
  upsell: Upsell;
  pane: Pane;
  priceType: PriceType;
};

async function loadNew(props: AppRoute): Promise<State> {
  const upsell = await store.getUpsell({ id: props.params.id });
  return {
    page: 'new',
    upsell,
    pane: 'pickcourse',
    priceType: 'single',
    offer: {
      id: '',
      content: '',
      upsellId: upsell.id,
      acceptButtonText: '',
      rejectButtonText: '',
    },
  };
}

async function loadExisting(props: AppRoute): Promise<State> {
  const upsell = await store.getUpsell({ id: props.params.id });
  const offer = upsell.offers.find((x) => x.id === props.params.offerId);
  if (!offer) {
    throw new Error(`Upsell not found!`);
  }
  return { page: 'edit', upsell, pane: 'salescopy', priceType: 'single', offer };
}

type Props = LoadedProps<typeof loadNew>;

function UpsellPriceTypePicker(props: Props) {
  return (
    <>
      <CourseAndPricePreview course={props.state.offer.course!} />
      <WizardSubtitle>Select a payment type</WizardSubtitle>
      <Picker<{ id: PriceType; desc: string }>
        hideSearch
        onPick={({ id }) => props.setState((s) => ({ ...s, pane: 'priceform', priceType: id }))}
        load={async () => [
          { id: 'single', desc: 'Single payment' },
          { id: 'plan', desc: 'Payment plan' },
          { id: 'monthly', desc: 'Monthly subscription' },
          { id: 'annually', desc: 'Annual subscription' },
        ]}
        summarize={(c) => c.desc}
      />
    </>
  );
}

function Page(props: Props) {
  const { terminology } = useCurrentTenant();
  const { state, setState } = props;
  const { upsell, offer } = state;
  const paneSequence: Pane[] = ['pickcourse', 'pricetype', 'priceform', 'salescopy'];
  const paneIndex = paneSequence.indexOf(state.pane);
  const title = state.page === 'new' ? 'New offer' : 'Edit offer';
  const cancelURL = `/upsells/${upsell.id}?tab=offers`;
  const firstPaneIndex = state.page === 'new' ? 0 : 1;

  const gotoPane = (value: number | Pane) => {
    const index = Math.max(
      firstPaneIndex,
      typeof value === 'number' ? value : paneSequence.indexOf(value),
    );
    const pane: Pane = paneSequence[index];
    setState((s) => ({
      ...s,
      pane,
      offer: {
        ...s.offer,
        course: index === 0 ? undefined : s.offer.course,
        price: index <= 1 ? undefined : s.offer.price,
      },
    }));
  };

  const showDeleteUpsellOfferModal = async () => {
    try {
      const ok = await showConfirmModal({
        title: 'Delete item',
        body: `This will permanently delete this upsell item. Any customers who purchased this item will continue to have access to the ${terminology.course} / product.`,
        confirmButtonText: 'Permanently Delete Item',
        mode: 'warn',
      });
      if (ok) {
        await store.deleteOffer({ upsellId: upsell.id, id: offer.id! });
        router.goto(cancelURL);
      }
    } catch (err) {
      showError(err);
    }
  };

  return (
    <UpsellsPage title={title}>
      <UpsellWizard
        cancelURL={cancelURL}
        upsell={upsell}
        title={title}
        paneIndex={paneIndex}
        header={
          <>
            {state.page === 'edit' && (
              <Dropdown
                class="absolute top-8 right-8"
                triggerClass="hover:bg-gray-200 size-8 rounded-full inline-flex items-center justify-center transition-all"
                hideDownIcon
                noPadding
                renderMenu={() => (
                  <section class="p-2 flex flex-col">
                    <Button
                      class="flex items-center gap-2 p-2 rounded hover:bg-red-50 hover:text-red-600"
                      onClick={showDeleteUpsellOfferModal}
                    >
                      <IcoTrash />
                      <span>Delete offer</span>
                    </Button>
                  </section>
                )}
              >
                <IcoDotsHorizontal />
              </Dropdown>
            )}

            {state.page === 'new' && (
              <nav class="flex flex-col gap-4">
                <WizardProgress
                  paneIndex={paneIndex}
                  numPanes={paneSequence.length}
                  gotoPane={gotoPane}
                />
              </nav>
            )}

            {firstPaneIndex < paneIndex && (
              <span>
                <Button
                  class="inline-flex gap-2 items-center text-indigo-600 font-semibold"
                  onClick={() => gotoPane(paneIndex - 1)}
                >
                  <IcoArrowLeft class="size-4" />
                  {state.page === 'new' && 'Back'}
                  {state.page === 'edit' &&
                    `Back to ${state.pane === 'salescopy' ? 'pricing' : 'payment type'}`}
                </Button>
              </span>
            )}
          </>
        }
      >
        {state.pane === 'pickcourse' && (
          <UpsellCoursePicker
            onPick={(course) =>
              setState((s) => ({ ...s, pane: 'pricetype', offer: { ...s.offer, course } }))
            }
            filter={(course) => !upsell.offers.find((x) => x.course.id === course.id)}
          />
        )}
        {state.pane === 'pricetype' && <UpsellPriceTypePicker {...props} />}
        {state.pane === 'priceform' && (
          <OfferPriceForm
            course={offer.course!}
            priceType={state.priceType}
            listPrice={offer.listPrice}
            price={offer.price}
            onSave={(data) => {
              setState((s) => ({
                ...s,
                offer: { ...s.offer, price: data.price, listPrice: data.listPrice },
              }));
              gotoPane('salescopy');
            }}
          />
        )}
        {state.pane === 'salescopy' && (
          <>
            <CourseAndPricePreview
              course={offer.course!}
              price={offer.price}
              listPrice={offer.listPrice}
            />
            <EditOfferSalesForm
              state={offer as Required<Offer>}
              setState={(fn) => setState((s) => ({ ...s, offer: fn(s.offer as Required<Offer>) }))}
              upsell={upsell}
              onSave={async (offer) => {
                if (state.page === 'new') {
                  await store.createOffer(offer);
                } else {
                  await store.saveOffer(offer);
                }
                return router.goto(cancelURL);
              }}
            />
          </>
        )}
      </UpsellWizard>
    </UpsellsPage>
  );
}

router.add({ url: 'upsells/:id/new', render: Page, authLevel: 'guide', load: loadNew });
router.add({
  url: 'upsells/:id/offers/:offerId',
  render: Page,
  authLevel: 'guide',
  load: loadExisting,
});
