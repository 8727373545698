import { AsyncForm, FormGroup, InputLabel } from '@components/async-form';
import { useMinidoc } from '@components/minidoc';
import { cardMiddleware, defaultToolbarActions, minidocToolbar, placeholder } from 'minidoc-editor';
import { mediaCard, mediaMiddleware } from '@components/media-card';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { useIntl } from 'shared/intl/use-intl';
import { IcoArrowLeft, IcoEye } from '@components/icons';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { Offer, Upsell } from './types';
import { ManualDom } from '@components/manual-dom';
import { showUpsellPreviewModal } from './upsell-preview';
import { useEffect } from 'preact/hooks';
import { WizardSubtitle } from './upsell-wizard';

type Props = {
  upsell: Upsell;
  state: Offer;
  setState(fn: (o: Offer) => Offer): void;
  onSave(offer: Offer): Promise<unknown>;
};

export function EditOfferSalesForm({ state, setState, upsell, onSave }: Props) {
  const intl = useIntl();
  const editor = useMinidoc({
    doc: state.content,
    middleware: () => [
      placeholder('Promotional text and content...'),
      minidocToolbar([...defaultToolbarActions, toolbarDropdown({ intl, compact: true })]),
      cardMiddleware([mediaCard]),
      mediaMiddleware(),
    ],
    onChange: (content) => setState((s) => ({ ...s, content })),
  });

  useEffect(() => {
    editor.root.focus();
  }, []);

  return (
    <>
      <WizardSubtitle>Edit the sales copy for this offer</WizardSubtitle>
      <AsyncForm
        class="flex flex-col gap-6"
        onSubmit={() =>
          onSave({
            courseId: state.course!.id,
            id: state.id,
            priceId: state.price!.id,
            upsellId: upsell.id,
            content: state.content,
            price: state.price!,
            course: state.course!,
            acceptButtonText: state.acceptButtonText || '',
            rejectButtonText: state.rejectButtonText || '',
            listPrice: state.listPrice,
          })
        }
      >
        <div class="flex flex-col border rounded-md ring-2 ring-transparent focus-within:ring-indigo-400">
          <header class="border-b flex">
            <ManualDom el={editor.toolbar.root} />
          </header>
          <ManualDom
            class="flex-grow mini-padded [&>*]:min-h-40 [&>*]:p-3.5 [&>*]:py-10"
            el={editor.root}
          />
        </div>
        <FormGroup prop="acceptButtonText" class="w-1/2">
          <InputLabel>Accept button text (optional)</InputLabel>
          <input
            placeholder="Add to cart"
            name="acceptButtonText"
            class="ruz-input"
            value={state.acceptButtonText}
            onInput={(e: any) => setState((s) => ({ ...s, acceptButtonText: e.target.value }))}
          />
        </FormGroup>
        <FormGroup prop="rejectButtonText" class="w-1/2">
          <InputLabel>Reject button text (optional)</InputLabel>
          <input
            placeholder="No thanks!"
            name="rejectButtonText"
            class="ruz-input"
            value={state.rejectButtonText}
            onInput={(e: any) => setState((s) => ({ ...s, rejectButtonText: e.target.value }))}
          />
        </FormGroup>

        <footer class="flex items-center justify-between pt-4 sm:m-0 sm:shadow-none sm:px-0 sticky p-4 -mx-4 -bottom-4 inset-x-0 bg-white shadow-top">
          <BtnPrimary class="rounded-full px-4 gap-2">
            Save offer
            <IcoArrowLeft class="rotate-180" />
          </BtnPrimary>
          <BtnSecondary
            class="gap-2 pl-3 pr-4 rounded-full"
            onClick={() =>
              showUpsellPreviewModal({
                ...upsell,
                offers: [state as Required<typeof state>],
              })
            }
          >
            <IcoEye />
            Preview
          </BtnSecondary>
        </footer>
      </AsyncForm>
    </>
  );
}
