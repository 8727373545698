import { stripSVG } from 'shared/media';
import { RPX } from '../../../server/rpx/types';
import { ajax } from '../ajax';
import { PresignedPost } from 'server/types';

/**
 * This creates a proxy that mirrors the shape of the RPX endpoints
 * on the server.
 */
function makeProxy(path: string): any {
  // The function which will call the server
  const fn = async (opts: any) => {
    const parts = path.split('/');
    const isGet = parts[parts.length - 1].startsWith('get');
    const query = isGet && opts ? `?arg=${encodeURIComponent(JSON.stringify(opts))}` : '';
    return ajax(`/rpx${path}${query}`, {
      method: isGet ? 'GET' : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: !isGet ? JSON.stringify(opts) : undefined,
    });
  };

  // The proxy magic which allows us to pretend to have the same
  // heirarchical object structure as our RPX endpoints.
  return new Proxy(fn, {
    get(_target, prop) {
      if (typeof prop === 'string') {
        return makeProxy(`${path}/${prop}`);
      }
    },
  });
}

export type RpxResponse<T extends (opts: any) => Promise<any>> = Awaited<ReturnType<T>>;

export const rpx: RPX = makeProxy('');

export function upload({
  file,
  fileId,
  presignedPost,
  onProgress,
}: {
  file: Blob;
  fileId: UUID;
  presignedPost: PresignedPost;
  onProgress: (percent: number, label: string) => void;
}) {
  const xhr = new XMLHttpRequest();
  const data = new FormData();

  Object.keys(presignedPost.fields).forEach((k) => data.append(k, presignedPost.fields[k]));

  async function performUpload() {
    data.append('Content-Type', file.type);

    if (file.type.toLowerCase().includes('svg')) {
      const svg = await file.text();
      const stripped = stripSVG(svg);
      data.append('file', new File([stripped], file.name, { type: file.type }));
    } else {
      data.append('file', file);
    }

    await new Promise((resolve, reject) => {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr);
          } else {
            reject(xhr);
          }
        }
      };

      xhr.upload.addEventListener('progress', (e) => {
        onProgress(Math.ceil((e.loaded / e.total) * 100), (file as any).name || '...');
      });

      xhr.open('POST', presignedPost.url);
      xhr.send(data);
    });

    return rpx.files.uploadComplete({ id: fileId });
  }

  return {
    promise: performUpload(),

    cancel() {
      xhr.abort();
    },
  };
}
