import { FormGroupTwoCol } from '@components/async-form';
import { UserProfileIconChanger } from '@components/avatars';
import { BioEditor } from '@components/bio-editor';
import { router, useRouteParams } from '@components/router';
import { useAuth, useCurrentUser } from '@components/router/session-context';
import { AppRoute } from 'client/lib/app-route/types';
import { LoadedProps } from 'client/lib/loaders';
import { rpx } from 'client/lib/rpx-client';
import { useState, useEffect } from 'preact/hooks';
import { useIntl } from 'shared/intl/use-intl';
import { URLS } from 'shared/urls';
import { generateDisplayName } from 'client/lib/user-display-name/generate-display-name';
import { CheckoutForm } from '@components/checkout';
import { CourseBanner } from '@components/course-checkout';
import { DefaultSpinner } from '@components/spinner';
import { Case } from '@components/conditional';

async function load({ params }: AppRoute) {
  const [course, profileFields] = await Promise.all([
    rpx.courses.getStudentCourse({ id: params.courseId }),
    rpx.profileFields.getStudentProfileFields({ courseId: params.courseId }),
  ]);

  return {
    course: { ...course, guide: { ...course.guide, email: course.guide.email || '' } },
    profileFields,
  };
}

type PageProps = LoadedProps<typeof load>;

function RedirectToCourse({ course }: { course: PageProps['data']['course'] }) {
  useEffect(() => {
    setTimeout(() => router.goto(URLS.student.course({ course })));
  }, []);

  return <DefaultSpinner />;
}

function Page({ data }: PageProps) {
  const params = useRouteParams();
  const isOldUser = params.isOldUser === 'true';
  const intl = useIntl();
  const { course } = data;
  const currentUser = useCurrentUser()!;
  const [bio, setBio] = useState(currentUser.bio);
  const auth = useAuth();
  const [fields, setFields] = useState(data.profileFields);
  const [name, setName] = useState(() =>
    // If the user's name was generated from the email, we will reauire them to
    // update it on this form. It's still skippable, as the entire form is not
    // enforced (e.g. the user could go to `courses.ruzuku.com` and hop into the
    // course from the my-courses screen), but this is an 80/20 solution.
    currentUser.email.startsWith(currentUser.name + '@') ? '' : currentUser.name,
  );
  const [displayName, setDisplayName] = useState(currentUser.displayName);

  const setField = (id: UUID, value: string) => {
    setFields((xs) => xs?.map((x) => (x.id === id ? { ...x, value } : x)));
  };

  if (course.hidePeople && !fields.length) {
    return <RedirectToCourse course={course} />;
  }

  // Generate a suggested display name for the user from their full name
  const suggestedName = generateDisplayName(name) || name;

  return (
    <div class="min-h-screen flex flex-col gap-10">
      <section class="p-6 lg:p-16 flex flex-col gap-10 max-w-content mx-auto bg-white rounded-md">
        <header class="border-b pb-6 w-readable">
          <h2 class="text-2xl font-medium">
            {isOldUser ? intl('Complete your profile') : intl('Welcome!')}
          </h2>
          <p class="text-base text-gray-700 pb-6">
            <Case
              when={isOldUser}
              fallback={
                course.isProduct
                  ? intl('Complete your profile, and jump into the content.')
                  : intl('Complete your profile, and jump into the course.')
              }
            >
              {course.isProduct
                ? intl(
                    'Welcome back! It looks like you forgot to complete your profile for this product. Please fill out any empty fields, then jump back into the product!',
                  )
                : intl(
                    'Welcome back! It looks like you forgot to complete your profile for this course. Please fill out any empty fields, then jump back into the course!',
                  )}
            </Case>
          </p>
          <CourseBanner course={course} />
        </header>
        <CheckoutForm
          actionText={
            course.isProduct ? intl('Save & continue ⤑') : intl('Save & view the course ⤑')
          }
          onSubmit={async () => {
            await rpx.auth.updateUser({
              id: currentUser.id,
              email: currentUser.email,
              name,
              displayName: displayName || suggestedName,
              timezone: currentUser.timezone,
              bio,
            });
            auth.setUser({
              ...currentUser,
              name,
              displayName: displayName || suggestedName,
              bio,
            });
            if (fields) {
              await rpx.profileFields.saveStudentProfileFields({
                courseId: course.id,
                profileFields: fields,
              });
            }
            router.goto(
              URLS.student.coursePage({
                course,
                page: course.isProduct ? 'content' : '',
              }),
            );
          }}
        >
          {!isOldUser && (
            <>
              {!course.hidePeople && (
                <FormGroupTwoCol prop="profile-picture" labelText={intl('Profile picture')}>
                  <UserProfileIconChanger user={currentUser} size="w-20 h-20 text-2xl" />
                </FormGroupTwoCol>
              )}
              <FormGroupTwoCol
                prop="name"
                labelText={intl('Name')}
                class="w-full mb-4"
                subText={intl('Your full name.')}
              >
                <input
                  type="text"
                  class="ruz-input"
                  name="name"
                  required
                  placeholder={intl('Name')}
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                />
              </FormGroupTwoCol>
              <FormGroupTwoCol
                prop="displayName"
                labelText={intl('Display Name')}
                class="w-full mb-4"
                subText={intl(
                  'This is a short name that will be used in your email notifications.',
                )}
              >
                <input
                  type="text"
                  class="ruz-input"
                  name="displayName"
                  placeholder={suggestedName}
                  value={displayName}
                  onChange={(e: any) => setDisplayName(e.target.value)}
                />
              </FormGroupTwoCol>
              {!course.hidePeople && (
                <FormGroupTwoCol
                  prop="bio"
                  labelText={intl('Short Bio')}
                  subText={intl('Your biography is PUBLIC and will be shared with other users.')}
                >
                  <BioEditor class="max-w-96" value={bio || ''} onChange={setBio} />
                </FormGroupTwoCol>
              )}
            </>
          )}
          {fields.map((f) => (
            <FormGroupTwoCol prop={f.id} labelText={f.label} class="w-full mb-4" key={f.id}>
              <input
                type="text"
                class="ruz-input"
                required
                value={f.value}
                placeholder={f.label}
                onInput={(e: any) => setField(f.id, e.target.value)}
              />
            </FormGroupTwoCol>
          ))}
        </CheckoutForm>
      </section>
    </div>
  );
}

['products/:courseId/profile-fields', 'courses/:courseId/profile-fields'].forEach((url) => {
  router.add({
    load,
    url,
    render: Page,
    authLevel: 'student',
  });
});
