import { useState } from 'preact/hooks';
import type { Course, Offer } from './types';
import * as fmt from 'shared/payments/fmt';
import { AsyncForm } from '@components/async-form';
import { CurrencyInput } from '../pmts/components/currency-input';
import { Toggle } from '@components/toggle';
import { Checkbox } from '@components/checkbox';
import { BtnPrimary } from '@components/buttons';
import { IcoArrowRight } from '@components/icons';
import { WizardSubtitle } from './upsell-wizard';
import { CourseAndPricePreview } from './course-price-preview';
import { autoFocusSelf } from 'client/utils/autofocus';

export type PriceType = 'single' | 'plan' | 'monthly' | 'annually';

type Props = {
  course: Course;
  priceType: PriceType;
  listPrice?: string;
  price?: Offer['price'];
  onSave(price: Pick<Offer, 'price' | 'listPrice'>): void;
};

export function OfferPriceForm(props: Props) {
  const { course, priceType, price, onSave } = props;
  const [currency, setCurrency] = useState(() => price?.currency || 'USD');
  const [amount, setAmount] = useState(() =>
    price ? fmt.price({ ...price, omitCurrency: true }) : '',
  );
  const [rawNumPayments, setNumPayments] = useState(price?.numPayments?.toString() || '');
  const [listPrice, setListPrice] = useState(props.listPrice || '');
  const [allowSelfCancel, setAllowSelfCancel] = useState(!!price?.allowSelfCancel);
  const [providers, setProviders] = useState(() => ({
    allowStripe: price ? !!price.allowStripe : true,
    allowPaypal: price ? !!price.allowPaypal : true,
  }));
  const numPayments = rawNumPayments ? parseInt(rawNumPayments, 10) : undefined;

  const priceInCents = fmt.decimalToCents(amount, currency);
  const paymentType =
    priceInCents === 0
      ? 'free'
      : priceType === 'single'
      ? 'paid'
      : priceType === 'plan'
      ? 'paymentplan'
      : 'subscription';

  return (
    <>
      <CourseAndPricePreview
        course={course}
        listPrice={listPrice}
        price={{
          currency,
          paymentType,
          priceInCents,
          numPayments,
        }}
        showPlaceholder
      />
      <WizardSubtitle>
        {priceType === 'single' && 'Set offer price'}
        {priceType === 'plan' && 'Set payment plan price'}
        {priceType === 'monthly' && 'Set monthly price'}
        {priceType === 'annually' && 'Set annual price'}
      </WizardSubtitle>
      <AsyncForm
        class="flex flex-col gap-6"
        onSubmit={async (data) => {
          const priceInCents = fmt.decimalToCents(amount, data.currency);
          onSave({
            listPrice,
            price: {
              id: `price-fake${Date.now().toString(16)}`,
              name: `Upsell offer ${new Date().toISOString()}`,
              currency: data.currency,
              priceInCents,
              paymentType,
              interval:
                priceType === 'annually' ? 'year' : priceType === 'single' ? undefined : 'month',
              allowSelfCancel,
              allowPaypal: providers.allowPaypal,
              allowStripe: providers.allowStripe,
              numPayments: data.numPayments ? parseInt(data.numPayments, 10) : undefined,
              isEnabled: true,
            },
          });
        }}
      >
        <section class="flex flex-col gap-6">
          <div class="flex flex-col">
            <label for="listPrice" class="cursor-pointer mb-1">
              List price
            </label>
            <div>
              <input
                type="text"
                class="ruz-input strikethrough max-w-28"
                placeholder="e.g. $650"
                ref={autoFocusSelf}
                name="listPrice"
                value={listPrice}
                onInput={(e: any) => setListPrice(e.target.value)}
              />
            </div>
          </div>

          <div class="flex flex-col">
            <label for="amount" class="cursor-pointer mb-1">
              Offer price
            </label>
            <div class="flex items-center gap-2">
              <CurrencyInput
                placeholder="amount"
                type="text"
                id="amount"
                required
                currency={currency}
                onCurrencyChange={setCurrency}
                value={amount}
                onInput={(e: any) => setAmount(e.target.value)}
              />
              {(priceType === 'plan' || priceType === 'monthly') && 'per month'}
              {priceType === 'annually' && 'per year'}
            </div>
          </div>
          {priceType === 'plan' && (
            <>
              <div class="flex flex-col">
                <label for="amount" class="cursor-pointer mb-1">
                  Number of months
                </label>
                <div>
                  <input
                    type="text"
                    class="ruz-input max-w-14"
                    placeholder="#"
                    name="numPayments"
                    value={rawNumPayments}
                    onInput={(e: any) => setNumPayments(e.target.value)}
                    required
                  />
                </div>
              </div>

              <label class="flex gap-3 items-center cursor-pointer">
                <Toggle checked={allowSelfCancel} onClick={() => setAllowSelfCancel((x) => !x)} />
                <span>Students can cancel their payment plan without contacting you</span>
              </label>
            </>
          )}

          {priceType === 'single' && (
            <div class="border rounded-2xl flex flex-col gap-4 p-6">
              <Checkbox
                checked={providers.allowStripe}
                type="checkbox"
                name="provider"
                onClick={() => setProviders((s) => ({ ...s, allowStripe: !s.allowStripe }))}
              >
                Stripe
              </Checkbox>
              <Checkbox
                checked={providers.allowPaypal}
                type="checkbox"
                name="provider"
                onClick={() => setProviders((s) => ({ ...s, allowPaypal: !s.allowPaypal }))}
              >
                PayPal
              </Checkbox>
            </div>
          )}
        </section>

        <footer class="pt-4 sticky bottom-0 w-full bg-white empty:hidden">
          <BtnPrimary class="rounded-full px-4 gap-2">
            Continue
            <IcoArrowRight />
          </BtnPrimary>
        </footer>
      </AsyncForm>
    </>
  );
}
