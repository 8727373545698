import { BtnPrimary } from '@components/buttons';
import { useTimezone } from 'client/lib/hooks';
import { timezoneCity } from 'shared/dateutil';

function splitDate(date?: Date) {
  const format = new Intl.DateTimeFormat('en-US', { timeStyle: 'short', dateStyle: 'full' }).format(
    date,
  );
  return format.split(' at ');
}

/**
 * BtnSchedule displays a schedule as a form submission button.
 */
export function BtnSchedule(props: { date?: Date; prefix: string; isLoading?: boolean }) {
  const timezone = useTimezone();
  const [datePrefix, dateSuffix] = splitDate(props.date);
  return (
    <BtnPrimary isLoading={props.isLoading}>
      <span class="flex gap-4 items-center">
        <span class="flex flex-col gap-1 text-right">
          <span>
            {props.prefix}
            {datePrefix}{' '}
          </span>
          <span>
            at {dateSuffix} <strong>{timezoneCity(timezone)} time</strong>
          </span>
        </span>
        <span>➜</span>
      </span>
    </BtnPrimary>
  );
}
