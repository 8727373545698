import { AsyncForm, FormGroup, Subtext } from '@components/async-form';
import { AutosizeText } from '@components/autosize-text';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { IcoUsers } from '@components/icons';
import { Modal, ModalTitle, ModalMessage } from '@components/modal';
import { useState } from 'preact/hooks';
import { BULK_INSERT_LIMIT } from 'shared/consts';
import { pluralize } from 'shared/formatting';
import { extractEmails } from 'shared/parsing';
import quikpik from 'quikpik';
import { Toggle } from '@components/toggle';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { showProcessingModal } from '@components/processing-modal';

type AddStudentsInBulkResult = RpxResponse<typeof rpx.admin.bulkAddStudentsToCourse>;

interface Props {
  courseId: UUID;
  onCancel(): void;
  onSent(result: AddStudentsInBulkResult): void;
}

function csvPicker(onPick: (content: string) => void) {
  quikpik({
    sources: ['filepicker'],
    accept: 'text/*',
    customProgress: true,
    upload({ files }) {
      const file = files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => onPick(reader.result as string), false);
      reader.readAsText(file);
    },
  });
}

export function BulkEnrollStudentsModal({ courseId, onSent, onCancel }: Props) {
  const [text, setText] = useState('');
  const [sendWelcomeEmails, setShouldSendWelcomeEmails] = useState(false);
  const numEmails = text.split('@').length - 1;

  async function submit() {
    const emails = extractEmails(text);
    const result = await showProcessingModal({
      promise: rpx.admin.bulkAddStudentsToCourse({
        courseId,
        emails,
        sendWelcomeEmails,
      }),
      title: `Adding ${numEmails} ${pluralize('student', numEmails)} to the course`,
      children: (
        <div class="scale-50">
          <div class="flex items-center -rotate-12">
            <span class="flex flex-col items-end mr-3 absolute right-full">
              <span class="bg-indigo-200 inline-block rounded-lg ml-3 h-2 w-16"></span>
              <span class="bg-gray-200 inline-block rounded-lg ml-3 h-2 w-28 mt-2"></span>
            </span>
            <span class="w-52 h-52 flex items-center justify-center text-indigo-300">
              <IcoUsers class="w-32 h-32" />
            </span>
          </div>
        </div>
      ),
    });
    if (result) {
      onSent(result);
    }
  }

  return (
    <Modal isOpen onCancel={onCancel}>
      <AsyncForm onSubmit={submit}>
        <IcoUsers
          stroke-width="1"
          class="w-24 h-24 m-auto mt-4 mb-4 rounded-full text-white bg-indigo-600 p-3 bg-gradient-to-br from-indigo-500 to-indigo-600"
        />
        <ModalTitle centered={true}>Add students in bulk</ModalTitle>
        <Subtext>
          Add up to {BULK_INSERT_LIMIT} email addresses one per line. You can also{' '}
          <Button class="text-indigo-600" onClick={() => csvPicker(setText)}>
            load a CSV file
          </Button>
          .
        </Subtext>
        <Subtext>
          This will add students directly to the course if they have an account. If they don't, an
          invitation will be sent.
        </Subtext>
        <ModalMessage class="space-y-8">
          <div
            class="overflow-y-scroll max-h-60 border border-gray-300 rounded focus-within:ring-indigo-400 focus-within:border-indigo-400 focus-within:ring-1"
            data-private
          >
            <FormGroup prop="emails" class="w-full">
              <AutosizeText
                focusSelf
                name="emails"
                class="min-h-32 border-none"
                value={text}
                onInput={(e: any) => setText(e.target.value)}
                placeholder="Emails, separated by space, comma, or newlines"
              />
            </FormGroup>
          </div>
        </ModalMessage>
        <footer class="mt-4 space-x-3">
          <label class="flex gap-2 cursor-pointer mt-6">
            <Toggle
              checked={sendWelcomeEmails}
              onClick={() => setShouldSendWelcomeEmails(!sendWelcomeEmails)}
            />
            <span>Send welcome to the course emails</span>
          </label>
          {sendWelcomeEmails && (
            <p class="pl-10 text-xs">
              You may visit the course messages page to modify the course welcome email template.
            </p>
          )}
          {!!numEmails && numEmails > BULK_INSERT_LIMIT && (
            <p class="text-red-600 text-xs text-right">
              You can only add up to {BULK_INSERT_LIMIT} students.
            </p>
          )}
          <div class="mt-4 flex justify-end space-x-3">
            <BtnSecondary type="button" onClick={onCancel}>
              Cancel
            </BtnSecondary>
            <BtnPrimary disabled={!numEmails || numEmails > BULK_INSERT_LIMIT}>
              Add {numEmails || ''} {pluralize('student', numEmails)}
            </BtnPrimary>
          </div>
        </footer>
      </AsyncForm>
    </Modal>
  );
}
