import { showError } from '@components/app-error';
import { AsyncForm } from '@components/async-form';
import { BtnSecondary, BtnWarning } from '@components/buttons';
import { Modal, ModalTitle, ModalMessage, ModalFooter, ModalContent } from '@components/modal';
import { showModalForm } from '@components/modal-form';
import { router } from '@components/router';
import { showToast } from '@components/toaster';
import { courseLabel } from 'shared/terminology';
import { useState } from 'preact/hooks';
import { Course } from 'server/types';
import { useCurrentTenant } from '@components/router/session-context';
import { evt } from 'client/lib/app-evt';
import { rpx } from 'client/lib/rpx-client';

interface Props {
  course: Pick<Course, 'id' | 'title' | 'isBundle'>;
  onCancel: () => void;
  onSuccess: () => void;
}

const COURSE_DELETED_EVENT = '$course_deleted_event';

interface CallbackArgs {
  courseId: UUID;
}

function dispatchCourseDeletedEvent(args: CallbackArgs) {
  evt.emit(COURSE_DELETED_EVENT, args);
}

export function onCourseDeleted(callback: (args: CallbackArgs) => void) {
  return evt.on((name, args: CallbackArgs) => {
    if (name === COURSE_DELETED_EVENT) {
      callback(args);
    }
  });
}

export function DeleteCourseModal({ course, onCancel, onSuccess }: Props) {
  const tenant = useCurrentTenant();
  const { id, title } = course;
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmation, setConfirmation] = useState('');
  const terminologyCourse = courseLabel({
    course,
    tenant,
  });

  return (
    <Modal isOpen onCancel={onCancel}>
      <ModalContent>
        <AsyncForm
          onSubmit={async () => {
            setIsDeleting(true);

            try {
              await rpx.courses.deleteCourse({ id });
              showToast({
                type: 'ok',
                title: `${terminologyCourse} #${id} deleted`,
                message: `Deleted "${title}".`,
              });
              dispatchCourseDeletedEvent({ courseId: id });
              onSuccess();
            } catch (err) {
              showError({ ...err, title: `Failed to delete ${terminologyCourse}` });
            } finally {
              setIsDeleting(false);
            }
          }}
        >
          <ModalTitle>Permanently delete {terminologyCourse} and all its content?</ModalTitle>
          <ModalMessage>
            <p class="mb-2">
              Are you sure you want to delete {terminologyCourse} <strong>{title}</strong>?
            </p>
            <p class="mb-2">
              All of your {terminologyCourse} data and attachments will be permanently removed from
              our servers. This action cannot be undone.
            </p>
            <div class="mt-4">
              <label class="font-bold block mb-2">Type DELETE to confirm</label>
              <input
                type="text"
                class="ruz-input"
                value={confirmation}
                onChange={(e: any) => setConfirmation(e.target.value)}
              />
            </div>
          </ModalMessage>

          <ModalFooter>
            <BtnSecondary type="button" onClick={onCancel}>
              Cancel
            </BtnSecondary>
            <BtnWarning disabled={confirmation.toLowerCase() !== 'delete'} isLoading={isDeleting}>
              Delete {terminologyCourse}
            </BtnWarning>
          </ModalFooter>
        </AsyncForm>
      </ModalContent>
    </Modal>
  );
}

export function showDeleteCourseModal(
  course: Pick<Course, 'id' | 'title' | 'isBundle'>,
  onSuccess?: () => void,
) {
  showModalForm(({ resolve }) => (
    <DeleteCourseModal
      course={course}
      onCancel={() => resolve(undefined)}
      onSuccess={() => {
        router.goto('/');
        onSuccess?.();
        setTimeout(() => resolve(true));
      }}
    />
  ));
}
