import { Button } from '@components/buttons';
import { CourseImage } from '@components/course-image';
import { IcoChevronRight } from '@components/icons';
import { Modal } from '@components/modal';
import { showModalForm } from '@components/modal-form';
import { useCurrentTenant } from '@components/router/session-context';
import { SearchBox } from '@components/search-box';
import { DefaultSpinner } from '@components/spinner';
import { useTryAsyncData } from 'client/lib/hooks';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { useState } from 'preact/hooks';

type Course = RpxResponse<typeof rpx.courses.getMyCourses>[0];

interface Props {
  onPick(course: Course): void;
  filter(course: Course): boolean;
}

export function showCoursePickerModal(filter: Props['filter']): Promise<Course | undefined> {
  return showModalForm(({ resolve }) => (
    <Modal isOpen onCancel={() => resolve()}>
      <CoursePicker onPick={resolve} filter={filter} />
    </Modal>
  ));
}

export function CoursePicker({ onPick, filter }: Props) {
  const { terminology, isCore } = useCurrentTenant();
  const [searchTerm, setSearchTerm] = useState('');
  const { isLoading, data } = useTryAsyncData(async () => {
    const courses = await rpx.courses.getMyCourses({
      asGuide: true,
    });
    return courses.map((x) => ({ ...x, search: x.title.toLowerCase() }));
  }, [searchTerm]);

  const lowerTerm = searchTerm.toLowerCase();
  const courses = data?.filter((c) => filter(c) && c.search.includes(lowerTerm)) || [];

  return (
    <div class="flex flex-col gap-4 h-(screen-56)">
      <SearchBox
        placeholder={`Find a ${terminology.course}${isCore ? '' : ' or product'}`}
        value={searchTerm}
        onTermChange={setSearchTerm}
      />
      {isLoading && <DefaultSpinner />}
      {!isLoading && !data?.length && (
        <p class="text-center p-4">
          No {terminology.courses} found. Only {terminology.courses} that are open and have sales
          pages will show up here.
        </p>
      )}
      <section class="flex flex-col gap-2">
        {courses.map((c) => (
          <Button
            key={c.id}
            class="border rounded-md flex justify-between items-center p-2 text-left hover:bg-gray-50 overflow-hidden"
            onClick={() => onPick(c)}
          >
            <span class="flex items-center gap-5">
              <CourseImage image={c.imagePath} />
              <span class="font-medium">{c.title}</span>
            </span>
            <span>
              <IcoChevronRight />
            </span>
          </Button>
        ))}
      </section>
    </div>
  );
}
