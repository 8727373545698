import { AppRoute } from 'client/lib/app-route/types';
import { rpx } from 'client/lib/rpx-client';
import { useMemo } from 'preact/hooks';
import { indexBy } from 'shared/utils';
import { goToRecentlyViewed } from './recently-viewed';

export const store = rpx.superadminPricing;

export const priceStore = rpx.prices;

export const couponStore = rpx.coupons;

export const defaultFolderId = 'fold-coreruzuku';

export type LoadResult = Awaited<ReturnType<typeof store.init>>;

export type Price = LoadResult['prices']['data'][0] & { type: 'price' };

export type Coupon = LoadResult['coupons']['data'][0] & { type: 'coupon' };

export type Folder = {
  type: 'folder';
  id: string;
  name: string;
};

export type Item = Folder | Price | Coupon;

export interface FolderStats {
  numItems: number;
  numSignups: number;
}

export function useFolderStats(state: State) {
  return useMemo(() => {
    const stats: Record<string, FolderStats> = {};
    Object.entries(state.itemToFolder).forEach(([itemId, folderId]) => {
      const item = state.items[itemId];
      if (!item) {
        return;
      }
      const fstat: FolderStats = stats[folderId] || { numSignups: 0, numItems: 0 };
      stats[folderId] = fstat;
      ++fstat.numItems;
      if (item.type === 'price') {
        fstat.numSignups += item.numSignups || 0;
      }
    });
    return stats;
  }, [state.itemToFolder]);
}

export async function load(route: AppRoute) {
  if (!route.params.priceId && !route.params.couponId) {
    goToRecentlyViewed(route);
  }

  const result = await store.init({
    productId: route.params.productId,
  });
  const items: Record<string, Item> = indexBy(
    (x) => x.id,
    [
      ...result.prices.data.map<Price>((x) => ({ ...x, type: 'price' })),
      ...result.coupons.data.map<Coupon>((x) => ({ ...x, type: 'coupon' })),
      ...result.folders.map<Folder>((x) => ({ ...x, type: 'folder' })),
    ],
  );

  const itemToFolder: Record<string, string> = {};

  result.prices.data.forEach((x) => {
    itemToFolder[x.id] = defaultFolderId;
  });

  result.coupons.data.forEach((x) => {
    itemToFolder[x.id] = defaultFolderId;
  });

  result.folderItems.forEach((x) => {
    itemToFolder[x.itemId] = x.folderId;
  });

  const selected: string[] = [];

  return {
    itemToFolder,
    items,
    selected,
    priceCoupons: Array.from(result.priceCoupons),
  };
}

export type State = Awaited<ReturnType<typeof load>>;

export type SetState = (fn: (state: State) => void) => void;
