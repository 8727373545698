/**
 * This file contains logic for displaying a modal form whose submitted value
 * can be awaited.
 */

import { ComponentChildren, createContext, JSX } from 'preact';
import {
  Modal,
  ModalFooter,
  ModalTitle,
  ModalMessage,
  ModalMode,
  ModalContent,
} from '@components/modal';
import { BtnSecondary, BtnPrimary, BtnWarning } from '@components/buttons';
import { evt } from 'client/lib/app-evt';
import { useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { AsyncForm } from '@components/async-form';
import { on } from 'minidoc-editor';
import { FileRec } from 'server/types';
import { AttachmentItem } from '@components/attachments/attachment-item';

type Resolvable = { resolve(data?: any): void };
type ModalProps = undefined | ((props: Resolvable) => JSX.Element | null);

type ConfirmModalProps =
  | undefined
  | {
      title: string;
      body: string | ComponentChildren;
      confirmButtonText?: string;
      cancelButtonText?: string;
      hideCancel?: boolean;
      mode?: ModalMode;
    };

const show = '$modalForm:show';
const done = '$modalForm:done';

export const ModalFormContext = createContext<{ resolve(data: any): void }>({ resolve() {} });

/**
 * Show a form as a modal. This does not submit to the server,
 * but instead resolves to an object that is the result of
 * serializing the form.
 */
export function showModalForm<T>(opts: ModalProps) {
  evt.emit(show, opts);
  return new Promise<T | undefined>((resolve) => {
    const off = evt.on((name, val) => {
      if (name === done) {
        off();
        resolve(val);
      }
    });
  });
}

/**
 * Show a confirm modal dialog.
 */
export async function showConfirmModal(opts: ConfirmModalProps): Promise<boolean> {
  if (!opts) {
    return false;
  }

  const result = await showModalForm(() => (
    <ModalForm
      title={opts.title}
      confirmButtonText={opts.confirmButtonText || 'Yes'}
      cancelButtonText={opts.cancelButtonText}
      hideCancel={opts.hideCancel}
      mode={opts.mode || 'warn'}
    >
      {opts.body}
    </ModalForm>
  ));

  return !!result;
}

/*
 * Show the attachment in the document root.
 */
export async function showAttachmentModal(opts: { attachment: FileRec }) {
  const result = await showModalForm(() => <AttachmentModal attachment={opts.attachment} />);
  return !!result;
}

function AttachmentModal(props: { attachment: FileRec; hideCancel?: boolean }) {
  const { resolve } = useContext(ModalFormContext);
  const hide = () => resolve(undefined);

  return (
    <Modal isOpen size="max-w-auto" onCancel={hide}>
      <div class="flex justify-center min-w-full max-w-full h-full rounded overflow-hidden">
        <AttachmentItem
          class="h-full"
          attachment={props.attachment}
          fullSize
          allowFullScreen={false}
        />
      </div>
    </Modal>
  );
}

export function ModalForm(props: {
  title?: string;
  confirmButtonText?: JSX.Element | string;
  cancelButtonText?: JSX.Element | string;
  mode?: ModalMode;
  size?: string;
  hideCancel?: boolean;
  onSubmit?(val?: any): Promise<unknown>;
  children: ComponentChildren;
}) {
  const { resolve } = useContext(ModalFormContext);
  const BtnApply = props.mode === 'warn' ? BtnWarning : BtnPrimary;
  const hide = () => resolve(undefined);

  return (
    <Modal size={props.size} isOpen onCancel={hide}>
      <AsyncForm
        onSubmit={async (data) => {
          if (data === undefined || !props.onSubmit) {
            return resolve(data);
          } else {
            return resolve(await props.onSubmit(data));
          }
        }}
      >
        <ModalContent mode={props.mode || 'none'}>
          {props.title && <ModalTitle>{props.title}</ModalTitle>}
          <ModalMessage class="whitespace-pre-wrap">{props.children}</ModalMessage>
        </ModalContent>
        <ModalFooter>
          {!props.hideCancel && (
            <BtnSecondary type="button" onClick={hide}>
              {props.cancelButtonText || 'Cancel'}
            </BtnSecondary>
          )}
          <BtnApply>{props.confirmButtonText || 'Apply'}</BtnApply>
        </ModalFooter>
      </AsyncForm>
    </Modal>
  );
}

/**
 * The modal form is a stack of forms. Each form may show another.
 * Forms may get pushed / popped from the stack in any order, so
 * it's not a literal stack. It's stack-like. Each item in the
 * stack has a Render property which is a vanilla Preact render
 * function, and a props associated with it.
 */
interface ModalFormStackItem {
  props: Resolvable;
  Render(props: Resolvable): JSX.Element | null;
}

export function RootModalForm() {
  const [modals, setChild] = useState<ModalFormStackItem[]>([]);
  const ref = useRef(modals);
  ref.current = modals;
  const clear = useMemo(
    () => () => {
      setChild([]);
      ref.current.forEach((x) => x.props.resolve(undefined));
    },
    [],
  );

  // Generally, we don't want modals to remain between page transitions,
  // so we'll clear on popstate. We may want to allow certain modals to
  // ignore this (e.g. go to page foo and show a success modal)
  useEffect(() => on(window, 'popstate', clear));

  useEffect(
    () =>
      evt.on((name, data) => {
        if (name !== show) {
          return;
        }
        if (!data) {
          clear();
        }
        setChild((s) => {
          const item: ModalFormStackItem = {
            Render: data,
            props: {
              async resolve(result: any) {
                setChild((s) => s.filter((x) => x !== item));
                evt.emit(done, result);
              },
            },
          };
          return [...s, item];
        });
      }),
    [],
  );

  if (!modals.length) {
    return null;
  }

  const child = modals[modals.length - 1];

  return (
    <ModalFormContext.Provider value={child.props}>
      <child.Render resolve={child.props.resolve} />
    </ModalFormContext.Provider>
  );
}
