import { IcoClipboard } from '@components/icons';
import { Spinner } from '@components/spinner';
import { ComponentChildren, JSX } from 'preact';
import { useState } from 'preact/hooks';

export type ButtonProps = JSX.HTMLAttributes<
  HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement
> & {
  href?: string;
  baseClass?: string;
  spinnerClass?: string;
  isLoading?: boolean;
};

export function Button({
  children,
  class: className,
  className: baseClass,
  spinnerClass = '',
  href,
  isLoading,
  disabled,
  ...props
}: ButtonProps) {
  const fullClass = `${className || ''} ${baseClass || ''} disabled:cursor-not-allowed`;
  const shouldDisable = disabled || isLoading;

  /*
   * Hiding the children and displaying a spinner when isLoading is true.
   * We're wrapping the children with `invisible` class to make sure
   * the size of the button doesn't change.
   * We don't need to do this for buttons that does not have a `isLoading`
   * state, so we're just rendering `children` when it is undefined.
   */
  const content =
    isLoading === undefined ? (
      children
    ) : (
      <>
        {isLoading ? (
          <span class="relative flex">
            <span class="invisible flex-1">{children}</span>
            <Spinner class={`absolute inset-1/2 -mt-2 -ml-2 ${spinnerClass}`} />
          </span>
        ) : (
          children
        )}
      </>
    );

  if (href && !shouldDisable) {
    const target = /https?:\/\//.test(href) ? '_blank' : props.target;
    const rel = target === '_blank' ? 'noreferrer' : props.rel;
    return (
      <a
        target={target}
        rel={rel}
        {...(props as JSX.HTMLAttributes<HTMLAnchorElement>)}
        href={href}
        class={fullClass}
      >
        {content}
      </a>
    );
  }

  return (
    <button
      type={props.onClick ? 'button' : 'submit'}
      {...(props as JSX.HTMLAttributes<HTMLButtonElement>)}
      class={fullClass}
      disabled={shouldDisable}
    >
      {content}
    </button>
  );
}

/**
 * The primary / submit button.
 */
export function BtnPrimary(props: ButtonProps) {
  return (
    <Button
      type={props.onClick ? 'button' : 'submit'}
      {...props}
      className={`btn-primary ${props.class || ''}`}
    />
  );
}

/**
 * BtnCTA is BtnPrimary with a bit more umph: more padding, bigger font,
 * intended to be used when it is the sole button in its section.
 */
export function BtnCTA(props: ButtonProps) {
  return <Button {...props} className={`btn-primary py-3 px-6 ${props.class || ''}`} />;
}

/**
 * A button which indicates a non-primary and non-dangerous action.
 */
export function BtnSecondary(props: ButtonProps) {
  return (
    <Button
      type={props.type || 'button'}
      spinnerClass="border-indigo-500"
      {...props}
      className={`border-gray-300 dark:border-gray-500 dark:text-gray-200 dark:hover:bg-gray-600 hover:bg-gray-50 btn ${
        props.class || ''
      }`}
    />
  );
}

/**
 * A button which indicates a dangerous action will occur if clicked.
 */
export function BtnWarning(props: ButtonProps) {
  return (
    <Button
      {...props}
      className="text-white bg-red-600 border-transparent hover:bg-red-500 focus:ring-red-500 btn"
    />
  );
}

/**
 * A button which indicates that the associated action is dangerous, but which won't commit the
 * action. If you click this button, the user will first have to approve a warning.
 */
export function BtnPreWarning(props: ButtonProps) {
  return (
    <Button
      {...props}
      className={`text-red-600 border-red-600 hover:bg-red-50 focus:ring-red-500 btn ${
        props.class || ''
      }`}
    />
  );
}

/**
 * A little copy button that shows "Copied" as a tooltip once copied. This has no padding
 * or margin baked in.
 */
export function BtnBasicCopy(props: {
  value: string;
  class?: string;
  copiedText?: string;
  icon?: ComponentChildren;
  children?: ComponentChildren;
}) {
  const [copied, setCopied] = useState(false);

  return (
    <Button
      class={`relative inline-flex items-center gap-1.5 ${props.class || ''}`}
      type="button"
      onClick={() => {
        navigator.clipboard.writeText(props.value);
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      }}
    >
      {props.icon || <IcoClipboard />}
      <span>{props.children || 'Copy'}</span>
      {copied && (
        <span class="absolute -top-8 inset-x-0 an-slide-up text-center">
          <span class="inline-block bg-green-500 text-white p-2 rounded-md whitespace-nowrap shadow-lg drop-shadow-md px-2">
            {props.copiedText || 'copied'}
          </span>
        </span>
      )}
    </Button>
  );
}

/**
 * A little copy button that shows "Copied" as a tooltip once
 * copied.
 */
export function BtnCopy(props: {
  value: string;
  class?: string;
  margin?: string;
  copiedText?: string;
  children?: ComponentChildren;
}) {
  return <BtnBasicCopy {...props} class={`px-2 ${props.class || ''} ${props.margin || 'ml-2'}`} />;
}
