import { Course } from 'server/types';
import { ComponentChildren } from 'preact';
import {
  Command,
  CommandType,
  PaletteState,
  useCommandPalette,
} from '@components/guide-course-menu';
import { IcoBook, IcoChat, IcoFolder, IcoVideoCamera } from '@components/icons';
import { UserProfileIcon } from '@components/avatars';
import { URLS } from 'shared/urls';
import { useIntl } from 'shared/intl/use-intl';
import { rpx } from 'client/lib/rpx-client';
import { genericDiscussionCategoryIds } from 'shared/consts';
import { fullTimeFormat } from 'shared/dateutil';
import { useState } from 'preact/hooks';

// The maximum number of search history records we'll store across
// *all* courses.
const MAX_HIST = 100;
// The maximum number of search history records we'll store for a
// single course.
const MAX_COURSE_HIST = 10;

const storageKey = 'recent-searches';

type RecentSearch = Pick<Command, 'id' | 'type' | 'title' | 'subtitle' | 'href'> & {
  courseId: UUID;
  iconUrl?: string;
};

function loadSearches(): RecentSearch[] {
  try {
    return JSON.parse(localStorage.getItem(storageKey) || '[]');
  } catch {
    return [];
  }
}

const EMPTY_SEARCH_RESULT = {
  discussions: {
    totalHits: 0,
    items: [],
  },
  meetings: {
    totalHits: 0,
    items: [],
  },
  lessons: {
    totalHits: 0,
    items: [],
  },
  modules: {
    totalHits: 0,
    items: [],
  },
  students: {
    totalHits: 0,
    items: [],
  },
};

function saveSearch(item: RecentSearch) {
  const searches = loadSearches().filter((x) => x.id !== item.id);
  searches.unshift(item);
  const forCourse = searches.filter((x) => x.courseId === item.courseId).slice(0, MAX_COURSE_HIST);
  const rest = searches.filter((x) => x.courseId !== item.courseId);
  localStorage.setItem(storageKey, JSON.stringify([...forCourse, ...rest].slice(0, MAX_HIST)));
  return searches;
}

function CmdIcon({ children, color }: { children: ComponentChildren; color: string }) {
  return (
    <span class={`w-8 h-8 p-2 rounded-md ${color} inline-flex items-center justify-center`}>
      {children}
    </span>
  );
}

/**
 * useStudentSearchModal is a hook that adds `ctrl search` to the calling page. It
 * returns a function which shows a modal with a search input.
 */
export function useStudentSearchModal(
  course: Pick<
    Course,
    | 'id'
    | 'title'
    | 'isProduct'
    | 'isBundle'
    | 'accessFormat'
    | 'hidePeople'
    | 'hideDiscussions'
    | 'hasMeetings'
  >,
  {
    listenCtrlKey = true,
    onlyDiscussions = false,
  }: {
    listenCtrlKey?: boolean;
    onlyDiscussions?: boolean;
  },
) {
  const intl = useIntl();
  const [state, setState] = useState<PaletteState>({
    term: '',
    type: onlyDiscussions ? 'discussion' : undefined,
  });

  return useCommandPalette(() => {
    const subTypes: Record<string, string> = {
      lesson: intl('lesson'),
      meeting: intl('meeting'),
      student: intl('student'),
      discussion: intl('discussion'),
      module: intl('module'),
    };
    return {
      course,
      state,
      setState,
      types:
        course.isProduct || course.isBundle
          ? []
          : [
              'module',
              'lesson',
              course.hasMeetings ? 'meeting' : undefined,
              course.hideDiscussions ? undefined : 'discussion',
              course.hidePeople ? undefined : 'student',
            ],
      get cachedSearches() {
        return loadSearches()
          .filter((x) => {
            if (x.courseId !== course.id) {
              return false;
            }
            if (onlyDiscussions && x.type !== 'discussion') {
              return false;
            }
            return true;
          })
          .map((x) => ({
            id: x.id,
            type: 'recent' as CommandType,
            subtype: subTypes[x.type],
            title: x.title,
            subtitle: x.subtitle,
            keywords: x.title.toLowerCase(),
            href: x.href,
            onClick() {
              saveSearch(x);
            },
            icon() {
              if (x.type === 'lesson') {
                return (
                  <CmdIcon color="bg-indigo-100 text-indigo-600">
                    <IcoBook />
                  </CmdIcon>
                );
              }
              if (x.type === 'module') {
                return (
                  <CmdIcon color="bg-blue-100 text-indigo-600">
                    <IcoFolder />
                  </CmdIcon>
                );
              }
              if (x.type === 'discussion') {
                return (
                  <CmdIcon color="bg-green-100 text-green-800">
                    <IcoChat />
                  </CmdIcon>
                );
              }
              if (x.type === 'meeting') {
                return (
                  <CmdIcon color="bg-violet-100 text-violet-600">
                    <IcoVideoCamera />
                  </CmdIcon>
                );
              }
              if (x.type === 'student') {
                return (
                  <UserProfileIcon
                    user={{
                      name: x.title,
                      profilePhotoUrl: x.iconUrl,
                    }}
                    size="w-8 h-8"
                  />
                );
              }
              return null;
            },
          }));
      },
      async search(term, type) {
        let results;

        if (term === '' && !type) {
          results = EMPTY_SEARCH_RESULT;
        } else if (onlyDiscussions) {
          const discussions = await rpx.search.getDiscussionsSearch({
            courseId: course.id,
            term: encodeURIComponent(term),
          });
          results = {
            ...EMPTY_SEARCH_RESULT,
            discussions,
          };
        } else {
          const data = await rpx.search.getCourseSearch({
            courseId: course.id,
            term: encodeURIComponent(term),
            type,
          });
          results = {
            ...EMPTY_SEARCH_RESULT,
            ...data,
          };
        }

        const items = [
          ...results.modules.items.map<Command>((m) => {
            const href = URLS.student.module({
              courseId: course.id,
              moduleId: m.id,
            });
            return {
              id: m.id,
              title: m.title || '',
              type: 'module',
              keywords: '',
              href,
              onClick() {
                saveSearch({
                  id: m.id,
                  type: 'module',
                  title: m.title || '',
                  courseId: course.id,
                  href,
                });
              },
              icon() {
                return (
                  <CmdIcon color="bg-blue-100 text-indigo-600">
                    <IcoFolder />
                  </CmdIcon>
                );
              },
            };
          }),
          ...results.lessons.items.map<Command>((l) => {
            const href = URLS.student.lesson({
              course,
              lesson: {
                id: l.id,
                title: l.title || '',
              },
            });
            return {
              id: l.id,
              title: l.title || '',
              subtitle: l.content,
              type: 'lesson',
              keywords: '',
              href,
              onClick() {
                saveSearch({
                  id: l.id,
                  type: 'lesson',
                  title: l.title || '',
                  subtitle: l.content,
                  courseId: course.id,
                  href,
                });
              },
              icon() {
                return (
                  <CmdIcon color="bg-indigo-200 text-indigo-600">
                    <IcoBook />
                  </CmdIcon>
                );
              },
            };
          }),
          ...results.meetings.items.map<Command>((m) => {
            const href = URLS.student.meeting({
              course,
              meeting: m,
            });
            return {
              id: m.id,
              title: m.title,
              type: 'meeting',
              subtitle: fullTimeFormat.format(m.scheduledAt),
              keywords: '',
              href,
              onClick() {
                saveSearch({
                  id: m.id,
                  type: 'meeting',
                  title: m.title,
                  courseId: course.id,
                  href,
                });
              },
              icon() {
                return (
                  <CmdIcon color="bg-violet-200 text-violet-600">
                    <IcoVideoCamera />
                  </CmdIcon>
                );
              },
            };
          }),
          ...results.discussions.items.map<Command>((d) => {
            const href = URLS.student.discussion({
              course,
              categoryId: d.categoryId || genericDiscussionCategoryIds.all,
              discussionId: d.id,
            });
            const subtitle = intl('Started by {author:string}', { author: d.author });

            return {
              id: d.id,
              title: d.prompt,
              type: 'discussion',
              subtitle,
              keywords: '',
              href,
              onClick() {
                saveSearch({
                  id: d.id,
                  type: 'discussion',
                  title: d.prompt,
                  subtitle,
                  courseId: course.id,
                  href,
                });
              },
              icon() {
                return (
                  <CmdIcon color="bg-green-100 text-green-800">
                    <IcoChat />
                  </CmdIcon>
                );
              },
            };
          }),
          // Do not show members if `hidePeople` is true.
          ...(course.hidePeople ? [] : results.students.items).map<Command>((s) => {
            const href = URLS.student.memberProfile({
              course,
              user: {
                id: s.userId,
                name: s.name || '',
              },
            });
            return {
              id: s.userId,
              title: s.name || '',
              type: 'student',
              keywords: '',
              href,
              onClick() {
                saveSearch({
                  id: s.userId,
                  type: 'student',
                  title: s.name || '',
                  courseId: course.id,
                  href,
                  iconUrl: s.profilePhotoUrl,
                });
              },
              icon() {
                return <UserProfileIcon user={s} size="w-8 h-8" />;
              },
            };
          }),
        ];

        return {
          totalHits: {
            student: results.students.totalHits,
            module: results.modules.totalHits,
            lesson: results.lessons.totalHits,
            meeting: results.meetings.totalHits,
            discussion: results.discussions.totalHits,
          },
          items,
        };
      },
    };
  }, listenCtrlKey);
}
