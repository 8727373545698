import { UserProfileIcon } from '@components/avatars';
import { BtnSecondary } from '@components/buttons';
import { IcoBan, IcoCheckCircle } from '@components/icons';
import { SearchBox } from '@components/search-box';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useRef, useState } from 'preact/hooks';
import { fmtFullDate } from 'shared/dateutil';
import { pluralize } from 'shared/formatting';

export interface Props {
  userHref(u: { userId: UUID }): string;
  couponHref(c: { couponId: string }): string;
  priceHref(p: { priceId: string }): string;
  mode: 'coupon' | 'price';
  productId: string;
  couponId?: string;
  priceId?: string;
  numPendingGifts?: number;
}

type Signups = RpxResponse<typeof rpx.members.loadSignups>;

function DebouncedSearch(props: Parameters<typeof SearchBox>[0]) {
  const [searchTerm, setSearchTerm] = useState(props.value);
  const ref = useRef<any>();

  return (
    <SearchBox
      {...props}
      value={searchTerm}
      onTermChange={(term) => {
        setSearchTerm(term);
        clearTimeout(ref.current);
        if (!term) {
          props.onTermChange?.(term);
        } else {
          ref.current = setTimeout(() => props.onTermChange?.(term), 250);
        }
      }}
    />
  );
}

export function SignupsTab({
  userHref,
  couponHref,
  priceHref,
  mode,
  couponId,
  priceId,
  productId,
  numPendingGifts,
}: Props) {
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [signups, setSignups] = useState<Signups>({
    data: [],
    cursor: '',
  });
  const { isLoading } = useTryAsyncData(async () => {
    const result = await rpx.members.loadSignups({
      searchTerm,
      cursor,
      couponId,
      priceId,
      productId,
    });
    setSignups((s) => ({
      cursor: result.cursor,
      data: cursor ? [...s.data, ...result.data] : result.data,
    }));
  }, [cursor, searchTerm]);

  return (
    <div>
      {!!numPendingGifts && (
        <p class="mt-2 mb-4 italic">
          {numPendingGifts} {pluralize('gift', numPendingGifts)}{' '}
          {numPendingGifts > 1 ? 'are' : 'is'} waiting for acceptance.
        </p>
      )}
      <table class="text-sm table-auto">
        <thead>
          <tr>
            <th>
              <DebouncedSearch
                placeholder="Signups"
                name="searchTerm"
                onTermChange={(term) => {
                  setSearchTerm(term);
                  setCursor(undefined);
                }}
              />
            </th>
            <th>Signup date</th>
            <th>{mode === 'coupon' ? 'Coupon' : 'Price'}</th>
          </tr>
        </thead>
        <tbody>
          {signups.data.map((c) => {
            const statusOk = c.status === 'active' || c.status === 'complete';

            return (
              <tr key={c.id}>
                <td>
                  {c.user && (
                    <a
                      href={userHref({ userId: c.user.id })}
                      class="flex items-center text-zinc-800 hover:text-indigo-600"
                    >
                      <UserProfileIcon user={c.user} size="w-10 h-10" />
                      <span class="ml-3">
                        <span class="block font-bold">{c.user.name}</span>
                        <span>{c.user.email}</span>
                      </span>
                    </a>
                  )}
                  {!c.user && c.giftRecipientEmail && (
                    <span class="italic">Pending gift to {c.giftRecipientEmail}</span>
                  )}
                </td>
                <td>
                  <span class="text-gray-900">{fmtFullDate(c.createdAt)}</span>
                  <span class="flex items-center text-gray-500 mt-1">
                    {statusOk ? (
                      <IcoCheckCircle class="w-4 h-4 text-green-400" />
                    ) : (
                      <IcoBan class="w-4 h-4 text-red-600" />
                    )}
                    <span class="ml-2">Status {c.status}</span>
                  </span>
                </td>
                {mode === 'price' && (
                  <td>
                    {!c.price && 'N/A'}
                    {!!c.price && <a href={priceHref({ priceId: c.price.id })}>{c.price.name}</a>}
                  </td>
                )}
                {mode === 'coupon' && (
                  <td>
                    {!c.coupon && 'N/A'}
                    {!!c.coupon && (
                      <a href={couponHref({ couponId: c.coupon.id })}>{c.coupon.code}</a>
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      {!signups.data.length && (
        <p class="p-8">{searchTerm ? 'No results found.' : 'There are no signups yet.'}</p>
      )}

      <footer class="mt-4">
        {signups.cursor && (
          <BtnSecondary onClick={() => setCursor(signups.cursor)} isLoading={isLoading}>
            Load more
          </BtnSecondary>
        )}
      </footer>
    </div>
  );
}
