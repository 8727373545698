import {
  Menu,
  useCaptionOptions,
  usePlaybackRateOptions,
  useVideoQualityOptions,
} from '@vidstack/react';

import { IcoChevronLeft, IcoChevronRight, IcoPlay, IcoSettings } from '@components/icons';
import {
  IcoClosedCaptions,
  IcoOdometer,
  IcoRadioButton,
  IcoRadioButtonSelected,
} from '@components/icons/video-player';

export function Settings({ hasCaptions }: { hasCaptions: boolean }) {
  return (
    <Menu.Root class="parent">
      <Menu.Button class="vidstack-btn">
        <IcoSettings class="w-6 h-6 transform transition-transform duration-200 ease-out group-data-[open]:rotate-90" />
      </Menu.Button>
      <Menu.Content
        class="animate-out fade-out slide-out-to-bottom-2 data-[open]:animate-in data-[open]:fade-in data-[open]:slide-in-from-bottom-4 flex h-[var(--menu-height)] max-h-[400px] min-w-[260px] flex-col overflow-y-auto overscroll-y-contain rounded-md border border-white/10 bg-black/95 p-2.5 outline-none backdrop-blur-sm transition-[height] duration-300 will-change-[height] data-[resizing]:overflow-hidden"
        placement="top end"
      >
        <PlaybackSubmenu />
        <QualitySubmenu />
        {hasCaptions && <CaptionSubmenu />}
      </Menu.Content>
    </Menu.Root>
  );
}

function PlaybackSubmenu() {
  const options = usePlaybackRateOptions(),
    hint = options.selectedValue === '1' ? 'Normal' : options.selectedValue + 'x';
  return (
    <Menu.Root>
      <SubmenuButton disabled={options.disabled} label="Speed" hint={hint} icon={IcoPlay} />
      <Menu.Content class="hidden w-full flex-col items-start justify-center outline-none data-[keyboard]:mt-[3px] data-[open]:inline-block">
        <Menu.RadioGroup class="w-full flex flex-col" value={options.selectedValue}>
          {options.map(({ label, value, select }) => (
            <Radio value={value} onSelect={select} key={value}>
              {label}
            </Radio>
          ))}
        </Menu.RadioGroup>
      </Menu.Content>
    </Menu.Root>
  );
}

function CaptionSubmenu() {
  const options = useCaptionOptions();
  const hint = options.selectedTrack?.label ?? 'Off';

  return (
    <Menu.Root>
      <SubmenuButton
        label="Captions"
        hint={hint}
        disabled={options.disabled}
        icon={IcoClosedCaptions}
      />
      <Menu.Content class="hidden w-full flex-col items-start justify-center outline-none data-[keyboard]:mt-[3px] data-[open]:inline-block">
        <Menu.RadioGroup class="w-full flex flex-col " value={options.selectedValue}>
          {options.map(({ label, value, select }) => (
            <Radio value={value} onSelect={select} key={value}>
              {label}
            </Radio>
          ))}
        </Menu.RadioGroup>
      </Menu.Content>
    </Menu.Root>
  );
}

function QualitySubmenu() {
  const options = useVideoQualityOptions();
  const selected = options.find(
    (option) => !!option.quality && option.value === options.selectedValue,
  );

  return (
    <Menu.Root>
      <SubmenuButton
        label="Quality"
        hint={selected?.label ?? 'Auto'}
        disabled={options.disabled}
        icon={IcoOdometer}
      />
      <Menu.Content class="hidden w-full flex-col items-start justify-center outline-none data-[keyboard]:mt-[3px] data-[open]:inline-block">
        <Menu.RadioGroup class="w-full flex flex-col" value={options.selectedValue}>
          {options.map(({ label, value, select }) => (
            <Radio value={value} onSelect={select} key={value}>
              {label}
            </Radio>
          ))}
        </Menu.RadioGroup>
      </Menu.Content>
    </Menu.Root>
  );
}

type RadioProps = Menu.RadioProps;

function Radio({ children, ...props }: RadioProps) {
  return (
    <Menu.Radio
      class="ring-media-focus group relative flex w-full cursor-pointer select-none items-center justify-start rounded-sm p-2.5 outline-none data-[hocus]:bg-white/10 data-[focus]:ring-[3px]"
      {...props}
    >
      <IcoRadioButton class="h-4 w-4 text-white group-data-[checked]:hidden" />
      <IcoRadioButtonSelected class="text-media-brand hidden h-4 w-4 group-data-[checked]:block" />
      <span class="ml-2">{children}</span>
    </Menu.Radio>
  );
}

type SubmenuButtonProps = {
  label: string;
  hint: string;
  disabled?: boolean;
  icon: any;
};

function SubmenuButton({ label, hint, icon: Icon, disabled }: SubmenuButtonProps) {
  return (
    <Menu.Button
      class="ring-media-focus parent left-0 z-10 flex w-full cursor-pointer select-none items-center justify-start rounded-sm bg-black p-2.5 outline-none ring-inset data-[open]:sticky data-[open]:-top-2.5 data-[hocus]:bg-white/10 data-[focus]:ring-[3px] aria-disabled:hidden"
      disabled={disabled}
    >
      <IcoChevronLeft class="parent-data-[open]:block -ml-0.5 mr-1.5 hidden h-[18px] w-[18px]" />
      <div class="contents parent-data-[open]:hidden">
        <Icon class="w-5 h-5" />
      </div>
      <span class="ml-1.5 parent-data-[open]:ml-0">{label}</span>
      <span class="ml-auto text-sm text-white/50">{hint}</span>
      <IcoChevronRight class="parent-data-[open]:hidden ml-0.5 h-[18px] w-[18px] text-sm text-white/50" />
    </Menu.Button>
  );
}
