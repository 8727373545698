import { Button } from '@components/buttons';
import { IcoX, IcoSearch } from '@components/icons';
import { autoFocusSelf } from 'client/utils/autofocus';
import { JSX } from 'preact';
import { useRef } from 'preact/hooks';

type Props = JSX.HTMLAttributes<HTMLInputElement> & {
  containerClass?: string;
  inputClass?: string;
  onTermChange?(term: string): void;
  focusOnce?: boolean;
  roundFull?: boolean;
};

export function SearchBox({ prefix, onTermChange, containerClass, roundFull, ...props }: Props) {
  const ref = useRef<HTMLInputElement>();
  return (
    <label class={`relative ${containerClass || 'w-full'} cursor-pointer inline-flex`}>
      {prefix && (
        <span class="bg-gray-50 px-4 inline-flex items-center border rounded-l border-gray-300 border-r-0 text-gray-500">
          {prefix}
        </span>
      )}
      {!prefix && (
        <Button
          tabIndex={-1}
          type="button"
          class="btn absolute inset-y-0 left-0 pl-3 flex items-center cursor-pointer shadow-none text-gray-400 dark:text-white"
          onClick={() => {
            onTermChange?.('');
            if (ref.current) {
              ref.current.value = '';
              ref.current.dispatchEvent(new CustomEvent('input', { bubbles: true }));
            }
          }}
        >
          {props.value ? <IcoX /> : <IcoSearch />}
        </Button>
      )}
      <input
        type="text"
        class={`ruz-input block w-full ${roundFull ? 'rounded-full' : ''} ${
          prefix ? 'rounded-l-none' : 'pl-9'
        } text-sm`}
        ref={(el) => {
          if (el) {
            props.focusOnce && autoFocusSelf(el);
            ref.current = el;
          }
        }}
        onInput={(e: any) => onTermChange?.(e.target.value)}
        {...props}
      />
    </label>
  );
}
