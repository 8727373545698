/**
 * This file contains the UI for editing a course's invitations.
 */

import { RouteProps, router } from '@components/router';
import { EmptyScreen, InvitationsGraphic } from './empty-screen';
import dayjs from 'dayjs';
import { HeadingPrimary } from '@components/headings';
import { IcoCheckCircle, IcoRefresh, IcoTrash, IcoX } from '@components/icons';
import { useMemo, useState } from 'preact/hooks';
import { SearchBox } from '@components/search-box';
import { Checkbox } from '@components/checkbox';
import { BtnBasicCopy, BtnPrimary, Button } from '@components/buttons';
import { showToast } from '@components/toaster';
import { showConfirmModal } from '@components/modal-form';
import { pluralize } from 'shared/formatting';
import { InvitationModal } from './invitation-modal';
import { AutoPager } from '../../components/auto-pager';
import { Filter, FilterBar } from './filter-menu';
import { Data, load } from './data';
import { showProcessingModal } from '@components/processing-modal';
import { GuidePage } from '@components/guide-page';
import { rpx } from 'client/lib/rpx-client';
import { URLS } from 'shared/urls';
import { useCurrentTenant, useCurrentUser } from 'client/lib/auth';
import { EmptyPage } from '@components/empty-page';
import { minutesToTimeString } from 'shared/dateutil';
import { useIntl } from 'shared/intl/use-intl';

const PAGE_SIZE = 100;

const store = rpx.invitations;

function Page(props: RouteProps<Data>) {
  const { course } = props.data;
  const intl = useIntl();
  const user = useCurrentUser();
  const tenant = useCurrentTenant();
  const [invitations, setInvitations] = useState(props.data.invitations);
  const [selected, setSelected] = useState(() => new Set<UUID>());
  const [searchTerm, setRawSearchTerm] = useState(props.route.params.q || '');
  const [filter, setFilter] = useState<Filter>('all');
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [page, setPage] = useState(PAGE_SIZE);

  const setSearchTerm = (term: string) => {
    setRawSearchTerm(term);
    setPage(PAGE_SIZE);
  };

  const searchedInvitations = useMemo(() => {
    const term = searchTerm.toLocaleLowerCase();
    return invitations.filter((x) => x.search.includes(term));
  }, [invitations, searchTerm]);

  const filteredInvitations = useMemo(() => {
    if (filter === 'all') {
      return searchedInvitations;
    }
    return searchedInvitations.filter(
      (x) => (filter === 'pending' && !x.userId) || (filter === 'accepted' && x.userId),
    );
  }, [searchedInvitations, filter]);

  const toggleChecked = (id: UUID) =>
    setSelected((s) => {
      const result = new Set(s);
      result.has(id) ? result.delete(id) : result.add(id);
      return result;
    });

  const removeSelected = async () => {
    const ok = await showConfirmModal({
      title: `Remove ${selected.size} ${pluralize('invitation', selected.size)}?`,
      body: `This will have no impact on existing students.`,
      confirmButtonText: `Remove ${selected.size} ${pluralize('invitation', selected.size)}`,
      mode: 'warn',
    });
    if (!ok) {
      return;
    }
    const result = await showProcessingModal({
      title: 'Deleting invitations...',
      promise: store
        .deleteInvitations({
          courseId: course.id,
          ids: Array.from(selected),
        })
        .then(() => true),
    });
    if (result) {
      setInvitations((xs) => xs.filter((x) => !selected.has(x.id)));
      setSelected(new Set());
      showToast({
        title: `Removed ${selected.size} invitations.`,
        message: '',
        type: 'ok',
      });
    }
  };

  const resendSelected = async () => {
    const ok = await showConfirmModal({
      title: `Re-send ${selected.size} ${pluralize('invitation', selected.size)}?`,
      body: `You have already invited these students. Are you sure you want to re-send the emails?`,
      confirmButtonText: `Resend ${selected.size} ${pluralize('invitation', selected.size)}`,
      mode: 'ok',
    });
    if (!ok) {
      return;
    }
    const result = await showProcessingModal({
      title: 'Resending invitations...',
      promise: store
        .resendInvitations({
          courseId: course.id,
          ids: Array.from(selected),
        })
        .then(() => true),
    });
    if (result) {
      showToast({
        title: `Invitations re-sent`,
        message: `${selected.size} invitations were sent.`,
        type: 'ok',
      });
    }
  };

  const areAllSelected =
    !!filteredInvitations.length &&
    filteredInvitations.every((x) => x.userId || selected.has(x.id));

  const visibleInvitations = filteredInvitations.slice(0, page);
  const hasFilters = filteredInvitations.length !== invitations.length;
  const hasSelection = !!selected.size;
  const hasSelectableInvitations =
    filter !== 'accepted' && filteredInvitations.some((x) => !x.userId);

  if (tenant.isCore && user?.tier === 'free') {
    return (
      <GuidePage course={course} type="invitations">
        <EmptyPage
          Ico={InvitationsGraphic}
          actionText="Upgrade Now"
          description="Invitations are available on paid accounts - upgrade now to start sending invitations to your students."
          title="Invitations"
          href="https://courses.ruzuku.com/account/billing"
        />
      </GuidePage>
    );
  }

  return (
    <GuidePage course={course} type="invitations">
      {showInvitationModal && (
        <InvitationModal
          courseId={course.id}
          onCancel={() => setShowInvitationModal(false)}
          send={async (data) => {
            const result = await store.sendInvitations({
              courseId: course.id,
              emails: data.emails,
              accessDuration: data.accessDuration,
            });
            return result;
          }}
          onSent={(newInvitations) => {
            setInvitations((xs) => [...newInvitations, ...xs]);
            setShowInvitationModal(false);
          }}
        />
      )}
      {!invitations.length && (
        <EmptyScreen course={course} onInvite={() => setShowInvitationModal(true)} />
      )}
      {!!invitations.length && (
        <div class="flex flex-col w-full max-w-7xl mx-auto p-8 gap-6">
          <HeadingPrimary title="Invitations" />
          <div class="flex gap-2 justify-between items-center clear-both ">
            <div data-private>
              <SearchBox
                value={searchTerm}
                onTermChange={setSearchTerm}
                placeholder="Find an invitation"
              />
            </div>
            <BtnPrimary onClick={() => setShowInvitationModal(true)}>Invite students</BtnPrimary>
          </div>

          {hasFilters && (
            <div>
              <Button
                class="inline-flex items-center"
                onClick={() => {
                  setFilter('all');
                  setSearchTerm('');
                }}
              >
                <span class="border rounded bg-gray-100 mr-2 p-0.5 inline-block">
                  <IcoX class="w-3 h-3 opacity-75" />
                </span>
                Clear current filters
              </Button>
            </div>
          )}

          <div class="rounded border">
            <header class="p-4 flex items-center bg-gray-50 sticky rounded-t top-0">
              <Checkbox
                wrapperClass={hasSelectableInvitations ? '' : 'invisible'}
                margin="mr-4"
                checked={areAllSelected}
                onClick={() =>
                  setSelected(() => {
                    if (areAllSelected) {
                      return new Set();
                    }
                    return new Set(filteredInvitations.filter((x) => !x.userId).map((x) => x.id));
                  })
                }
              />
              {hasSelection && (
                <div class="flex grow justify-between items-center">
                  <div>
                    {selected.size} selected
                    <Button
                      class="ml-3 pl-3 border-l inline-flex items-center"
                      onClick={() => setSelected(new Set())}
                    >
                      <span class="inline-flex items-center mr-2 bg-gray-100 rounded p-0.5 border">
                        <IcoX class="w-3 h-3 opacity-75" />
                      </span>
                      Clear selection
                    </Button>
                  </div>
                  <div class="flex items-center space-x-6">
                    <Button onClick={removeSelected} class="text-red-600 inline-flex items-center">
                      <IcoTrash class="w-4 h-4 mr-2" />
                      Remove
                    </Button>
                    <Button onClick={resendSelected} class="inline-flex items-center">
                      <IcoRefresh class="w-4 h-4 mr-2" /> Resend
                    </Button>
                  </div>
                </div>
              )}
              {!hasSelection && (
                <div class="space-x-4">
                  <FilterBar items={searchedInvitations} filter={filter} setFilter={setFilter} />
                </div>
              )}
            </header>

            {visibleInvitations.map((invitation) => (
              <div class="flex items-center justify-between border-t px-4 py-3" key={invitation.id}>
                <div class="flex">
                  <label class="flex items-start">
                    <Checkbox
                      wrapperClass={invitation.userId ? 'invisible' : ''}
                      margin="mr-4 mt-1.5"
                      disabled={!!invitation.userId}
                      checked={selected.has(invitation.id)}
                      onClick={() => toggleChecked(invitation.id)}
                    />
                    <span>
                      <span data-private>
                        {invitation.email}
                        {!!invitation.userId && (
                          <a
                            class="text-indigo-600 ml-2"
                            href={`/manage/courses/${course.id}/students/${invitation.userId}`}
                          >
                            View student
                          </a>
                        )}
                      </span>
                      <span class="text-gray-500 text-sm block">
                        Invited on {dayjs(invitation.createdAt).format('MMM D, YYYY')}
                      </span>
                      {invitation.accessDuration && (
                        <span class="text-gray-500 text-xs">
                          Access lasts {minutesToTimeString(invitation.accessDuration, intl)} days
                          after acceptance
                        </span>
                      )}
                    </span>
                  </label>
                </div>

                <span class="flex gap-4">
                  {!invitation.userId && (
                    <>
                      <BtnBasicCopy
                        class="text-indigo-600"
                        value={URLS.student.invitation({
                          course,
                          invitation,
                          domain: location.origin,
                        })}
                      >
                        Copy invitation link
                      </BtnBasicCopy>
                      <span class="border-l"></span>
                    </>
                  )}
                  {!invitation.userId && <span class="text-gray-500">Pending</span>}
                  {!!invitation.userId && (
                    <span class="text-green-600 flex items-center justify-end">
                      <IcoCheckCircle class="text-green-500 bg-white rounded-full w-4 h-4 mr-2" />
                      Accepted
                    </span>
                  )}
                </span>
              </div>
            ))}
          </div>

          {visibleInvitations.length < filteredInvitations.length && (
            <AutoPager onLoadMore={() => setPage((pg) => pg + PAGE_SIZE)} />
          )}
        </div>
      )}
    </GuidePage>
  );
}

router.add({
  url: `manage/courses/:courseId/invitations`,
  load,
  render: Page,
  authLevel: 'guide',
});
