/**
 * The root upsells page.
 */
import { router } from '@components/router';
import { LoadedProps } from 'client/lib/loaders';
import { store } from './store';
import { UpsellsPage } from './upsells-page';
import { EmptyPage } from '@components/empty-page';
import { IcoCreditCard, IcoPlus } from '@components/icons';
import { BtnPrimary, Button } from '@components/buttons';
import { fmtFullDate } from 'shared/dateutil';
import { CourseImage } from '@components/course-image';
import { pluralize } from 'shared/formatting';
import { Upsell } from './types';
import { useCurrentTenant } from 'client/lib/auth';

async function load() {
  const upsells = await store.getMyUpsells();
  return { upsells };
}

type Props = LoadedProps<typeof load>;

function WarningNote({ upsell }: { upsell: Upsell }) {
  const { terminology } = useCurrentTenant();
  const message = !upsell.isEnabled
    ? 'Signups closed'
    : !upsell.numOffers
    ? 'No offers'
    : !upsell.numApplied
    ? `Not applied to any ${terminology.course} or product`
    : '';
  if (!message) {
    return null;
  }
  return (
    <span>
      <span class="inline-flex gap-1 items-center bg-yellow-50 border border-yellow-200 pl-1 pr-2 text-yellow-700 rounded-md mb-2 text-xs">
        {message}
      </span>
    </span>
  );
}

function Page({ state }: Props) {
  const { terminology } = useCurrentTenant();
  const newPageHref = 'upsells/new';
  return (
    <UpsellsPage
      buttons={
        <header class="pb-2 -mt-2">
          <BtnPrimary class="gap-2 pr-4" href={newPageHref}>
            <IcoPlus />
            Create a new upsell
          </BtnPrimary>
        </header>
      }
    >
      {!state.upsells.length && (
        <EmptyPage
          Ico={IcoCreditCard}
          title="Upsells"
          centeredDescription
          description={
            <>
              Increase your revenue by promoting {terminology.courses}, products, and special deals
              to your students during checkout.
            </>
          }
          actionText="Create a new upsell"
          href={newPageHref}
        />
      )}
      {state.upsells.length > 0 && (
        <section class="flex flex-col gap-4">
          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8 items-start">
            {state.upsells.map((upsell) => (
              <Button
                key={upsell.id}
                class="flex flex-col gap-4 p-6 pb-4 border rounded-2xl text-left hover:bg-gray-50 text-inherit"
                href={`/upsells/${upsell.id}`}
              >
                <header class="flex flex-col">
                  <WarningNote upsell={upsell} />
                  <span class="font-semibold text-xl">{upsell.title}</span>
                  <span class="text-gray-600 text-xs">Created {fmtFullDate(upsell.createdAt)}</span>
                </header>

                {!!upsell.offers.length && (
                  <span class="flex">
                    {upsell.offers.map((x) => (
                      <CourseImage
                        key={x.id}
                        image={x.course.imagePath}
                        maxWidth={64}
                        size={`size-8 rounded-full -mr-2 border-2 border-white shadow-none ${
                          upsell.isEnabled ? '' : 'grayscale'
                        }`}
                      />
                    ))}
                  </span>
                )}

                <span class="flex justify-between items-center border-t pt-4">
                  <span class="text-xs flex gap-1 items-center">
                    <b>{upsell.numOffers}</b> {pluralize('offer', upsell.numOffers)}
                  </span>
                  <span class="text-xs flex gap-1 items-center">
                    <b>{upsell.numApplied}</b> applied
                  </span>
                  <span class="text-xs flex gap-1 items-center">
                    <b>0</b> signups
                  </span>
                </span>
              </Button>
            ))}
          </div>
        </section>
      )}
    </UpsellsPage>
  );
}

router.add({ url: 'upsells', render: Page, authLevel: 'guide', load });
