import { useEffect } from 'preact/hooks';
import { findUncompletedPrerequisites, getCurrentCourse } from './state';
import { addSubroute, RouteProps, router, useRouteParams } from '@components/router';
import {
  Lesson as LessonT,
  FullLesson,
  getCurrentLesson,
  moduleAvailableOn,
  State,
  Module,
} from '@components/module-helpers';
import { AppRoute } from 'client/lib/app-route/types';
import { useCurrentTenant } from '@components/router/session-context';
import { StudentPage } from '@components/student-page';
import dayjs from 'dayjs';
import { LoadingIndicator } from '@components/loading-indicator';
import { HiddenModulesNav, ModulesNav } from './modules-nav';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { Lesson } from './lesson';
import { Case } from '@components/conditional';
import { IcoClock } from '@components/icons';
import { URLS } from 'shared/urls';
import { showError } from '@components/app-error';
import { groupBy, unique } from 'shared/utils';
import { toHumanTime, toLocalDate } from 'shared/dateutil';
import { useIntl } from 'shared/intl/use-intl';
import { emptyLessonTitle } from 'shared/terminology';
import { rpx } from 'client/lib/rpx-client';

const lessonStore = rpx.lessons;

function LockedContent({ prerequisites }: { prerequisites: LessonT[] }) {
  const intl = useIntl();
  const { courseId, courseSlug } = useRouteParams();

  return (
    <div>
      <p>{intl('This lesson is locked until you complete the following lessons:')}</p>
      <div class="flex flex-col space-y-4 my-8">
        {prerequisites.map((lesson, i) => (
          <a
            key={lesson.id}
            class={`${i === 0 ? 'btn-primary' : ''}`}
            href={URLS.student.lesson({
              course: {
                id: courseId,

                title: courseSlug,
              },
              lesson,
            })}
          >
            {lesson.title || intl('Untitled lesson')}
          </a>
        ))}
      </div>
    </div>
  );
}

function ComingSoon({ availableOn }: { availableOn?: Date }) {
  if (!availableOn) {
    return <div>This content is not yet available.</div>;
  }

  return <div>Available {toHumanTime(availableOn)}</div>;
}

function UnavailableForStudentsBox({
  availableOn,
  prerequisites,
  type,
}: {
  availableOn?: Date;
  prerequisites: LessonT[];
  type: 'prerequisite' | 'date';
}) {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const { courseId, courseSlug } = useRouteParams();

  if (type === 'prerequisite' && prerequisites.length > 0) {
    return (
      <div>
        <p>
          {intl(
            'Students will not be able to view this lesson until they complete the following lessons:',
          )}
        </p>
        <ul class="flex flex-col ml-4 mt-2 list-disc">
          {prerequisites.map((lesson) => (
            <li key={lesson.id}>
              <a
                key={lesson.id}
                href={URLS.student.lesson({
                  course: {
                    id: courseId,
                    title: courseSlug,
                  },
                  lesson,
                })}
              >
                {lesson.title || emptyLessonTitle(tenant)}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div>
      {availableOn
        ? intl(`Students will not be able to view this lesson until {date:string}.`, {
            date: dayjs(availableOn).format('MMMM D, YYYY'),
          })
        : intl('Lesson is not yet available for students.')}
    </div>
  );
}

function Page({ state, setState }: RouteProps<State>) {
  const intl = useIntl();
  const { courseId, lessonId } = state;
  const course = getCurrentCourse(state);
  const lesson = getCurrentLesson(state);
  const canManageCourse = state.accessLevel === 'guide' || state.accessLevel === 'facilitator';
  const prerequisites = findUncompletedPrerequisites(state);
  const isAvailable = lesson?.isAvailable && prerequisites.length === 0;
  const showLesson = isAvailable || canManageCourse;

  useEffect(() => {
    if (courseId && lessonId) {
      try {
        lessonStore.saveLastViewedLesson({
          id: lessonId,
          courseId: courseId,
        });
      } catch (err) {
        console.warn('Could not set last viewed lesson', err);
      }
    }
  }, [courseId, lessonId]);

  useAsyncEffect(async () => {
    if (!courseId) {
      return;
    }
    try {
      await rpx.courses.setLastOpenedAt({ courseId });
    } catch (err) {
      console.warn('Could not set last_opened_at', err);
    }
  }, [courseId]);

  return (
    <StudentPage
      course={course}
      currentLink="modules"
      accessLevel={state.accessLevel}
      editLink={{
        url: lesson && `/manage/courses/${course.id}/lessons/${lesson.id}`,
      }}
      isPinnable
      sideNavContent={
        <ModulesNav
          lessons={state.lessons}
          lessonIds={state.orderedLessonIds}
          completedLessons={state.completedLessons}
          course={course}
          currentLessonId={lessonId}
          modules={state.modules}
          canManageCourse={canManageCourse}
        />
      }
      hiddenSideNavContent={
        <HiddenModulesNav course={course} modules={state.modules} currentLessonId={lessonId} />
      }
      documentTitle={lesson?.title}
    >
      {state.loadingLesson && <LoadingIndicator />}
      {lesson && !showLesson && (
        <div class="flex items-center">
          <div class="inline-flex flex-col p-20 max-w-readable text-center space-y-6 -mt-40">
            <header class="flex items-top">
              <span>
                <IcoClock class="w-32 h-32 theme-link opacity-75 inline-flex rounded-full" />
              </span>

              <div class="flex flex-col justify-center text-left ml-8 mt-2">
                <Case
                  when={prerequisites.length === 0}
                  fallback={<LockedContent prerequisites={prerequisites} />}
                >
                  <Case
                    when={state.accessLevel !== 'limited'}
                    fallback={
                      <div>
                        {intl('This module is not available until you purchase the bundle.')}
                      </div>
                    }
                  >
                    <ComingSoon
                      availableOn={moduleAvailableOn({
                        accessFormat: course.accessFormat,
                        module: lesson as FullLesson,
                        membershipDate: state.membershipDate,
                      })}
                    />
                  </Case>
                </Case>

                <header>
                  <h1 class="font-display text-3xl leading-snug tracking-tight text-gray-900 dark:text-white">
                    {lesson.title || intl('Untitled lesson')}
                  </h1>
                </header>
              </div>
            </header>
          </div>
        </div>
      )}
      {lesson && showLesson && (
        <Lesson
          // This makes sure we're rendering a new component
          // for each lessonId.
          key={lesson.id}
          lesson={lesson}
          course={course}
          canManageCourse={canManageCourse}
          state={state}
          setState={setState}
          setLessonComplete={async (lessonId, isCompleted) => {
            await lessonStore.updateLessonCompleteStatus({ id: lessonId, isCompleted });
            setState((s) => ({
              ...s,
              completedLessons: isCompleted
                ? unique([...s.completedLessons, lessonId])
                : s.completedLessons.filter((id) => id !== lessonId),
            }));
          }}
          infoBox={
            !isAvailable && (
              <div class="flex items-center bg-gray-50 dark:bg-gray-800 dark:text-zinc-200 p-4 rounded-md shadow mb-8 font-sans">
                <IcoClock class="w-8 h-8 opacity-75 mr-4" />
                <UnavailableForStudentsBox
                  type={lesson.isAvailable ? 'prerequisite' : 'date'}
                  availableOn={moduleAvailableOn({
                    accessFormat: course.accessFormat,
                    module: lesson as FullLesson,
                    membershipDate: state.membershipDate,
                  })}
                  prerequisites={prerequisites}
                />
              </div>
            )
          }
        />
      )}
    </StudentPage>
  );
}

const loadRoute = async (route: AppRoute) => {
  const { courseId } = route.params;
  const [course, data] = await Promise.all([
    rpx.courses.getStudentCourse({ id: courseId }),
    lessonStore.getFullLessonState({ courseId, lessonId: route.params.lessonId }),
  ]);
  const { lessons, modules } = data;
  const moduleIds = modules.map((s) => s.id);
  const moduleLessons = groupBy((x) => x.moduleId, data.lessons);
  const modulesMap = modules.reduce((acc, s) => {
    acc[s.id] = {
      ...s,
      courseId,
      startDate: toLocalDate(s.startDate),
      lessons: moduleLessons[s.id]?.map((x) => x.id) || [],
    };
    return acc;
  }, {} as Record<UUID, Module>);
  const orderedLessonIds = moduleIds.flatMap((moduleId) => modulesMap[moduleId].lessons);

  const result = {
    courseId,
    course: {
      ...course,
      modules: moduleIds,
    },
    lessonId: data.lesson?.id,
    lastViewedLessonId: data.lastViewedLesson,
    membershipDate: data.membershipDate,
    completedLessons: data.completedLessons,
    orderedLessonIds,
    lessons: lessons.reduce((acc, s) => {
      if (s.id === data.lesson?.id) {
        acc[s.id] = {
          ...data.lesson,
          type: 'full',
        };
      } else {
        acc[s.id] = {
          ...s,
          type: 'partial',
        };
      }
      return acc;
    }, {} as Record<UUID, LessonT>),
    modules: modulesMap,
    accessLevel: data.accessLevel,
  };

  // If the user did not request a lesson, and the course has lessons,
  // we'll redirect to the first lesson in the course, but preserve
  // navigation history (e.g. not break the back button).
  const [firstLessonId] = moduleIds.flatMap((id) => result.modules[id].lessons);
  const lessonId = result.lastViewedLessonId || firstLessonId;

  if (!route.params.lessonId && lessonId) {
    const goToLesson = (lesson: FullLesson) => {
      router.rewrite(
        URLS.student.lesson({
          course,
          lesson: result.lessons[lesson.id],
        }),
      );
      result.lessonId = lesson.id;
      result.lessons[lesson.id] = lesson;
    };

    try {
      const lesson = await lessonStore.getLesson({
        courseId,
        lessonId,
      });
      goToLesson({
        ...lesson,
        type: 'full',
      });
    } catch (err) {
      // This might happen when the `lastViewedLessonId` got deleted by the guide.
      // In this case, we'll redirect to the first lesson in the course.
      if (firstLessonId) {
        const lesson = await lessonStore.getLesson({
          courseId,
          lessonId: firstLessonId,
        });
        goToLesson({
          ...lesson,
          type: 'full',
        });
      } else {
        showError(err);
      }
    }
  }
  const state: State = result;
  return state;
};

/*
 * Fetches the first lesson of a module and rewrites the URL to that lesson.
 */
async function loadModuleRoute(route: AppRoute) {
  const firstLesson = await rpx.modules.getFirstLesson({ id: route.params.moduleId });
  const url = firstLesson
    ? URLS.student.lesson({ course: firstLesson.course, lesson: firstLesson })
    : URLS.student.lessons({ courseId: route.params.courseId });
  router.rewrite(url);
}

const routeDef = addSubroute({
  url: 'courses/:$courseId/lessons/:lessonId',
  load: loadRoute,
  async loadSubroute({ courseId, lessonId }, setState) {
    if (!lessonId) {
      return;
    }
    const lesson = await lessonStore.getLesson({ courseId, lessonId });
    setState((s) => ({
      ...s,
      showCert: false,
      lessonId: lesson.id,
      lessons: {
        ...s.lessons,
        [lesson.id]: {
          ...lesson,
          type: 'full',
        },
      },
    }));
  },
  key: (p) => p.courseId,
  render: Page,
  authLevel: 'student',
});

addSubroute({
  ...routeDef,
  url: 'courses/:courseId/lessons',
});

router.add({
  url: 'courses/:courseId/modules/:moduleId',
  load: loadModuleRoute,
  render: () => {
    return <LoadingIndicator />;
  },
  authLevel: 'student',
});
