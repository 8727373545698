import { showError } from '@components/app-error';
import { debounce } from 'client/utils/debounce';
import { serialAsync } from 'client/utils/serial-async';
import { Inputs, useEffect, useMemo, useRef, useState } from 'preact/hooks';

/**
 * useAsyncData returns data that was retrieved by the async fn argument.
 * It calls this serially with debouncing, any time the inputs change.
 * Possible return states are:
 * { isLoading: true, data: undefined } -- the initial fetch
 * { isLoading: true, data: T } -- any subsequent fetch, here data is the previous value
 * { isLoading: false, data: T } -- here, data is current
 */
export function useAsyncData<T>(fn: () => Promise<T>, inputs: Inputs, debounceMs = 0) {
  const [state, setState] = useState<{ isLoading: boolean; data?: T }>({ isLoading: true });
  const ref = useRef(fn);
  ref.current = fn;
  const serialFn = useMemo(
    () =>
      debounce(
        serialAsync(() => ref.current().then((data) => setState({ isLoading: false, data }))),
        debounceMs,
      ),
    [],
  );
  useEffect(() => {
    setState((s) => ({ ...s, isLoading: true }));
    serialFn();
  }, inputs);
  return state;
}

export function useTryAsyncData<T>(fn: () => Promise<T>, inputs: Inputs, debounceMs = 0) {
  return useAsyncData(
    async () => {
      try {
        return await fn();
      } catch (err) {
        showError(err);
      }
    },
    inputs,
    debounceMs,
  );
}
