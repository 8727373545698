/**
 * This file contiains the new price form for the general payment system.
 */
import { showToast } from '@components/toaster';
import { AsyncForm, FormGroup, FormSection, FormSubSection } from '@components/async-form';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { useState } from 'preact/hooks';
import {
  IcoCalendar,
  IcoChevronDown,
  IcoChevronLeft,
  IcoCreditCard,
  IcoDollar,
  IcoGift,
  IcoRefresh,
} from '@components/icons';
import { formDataToPriceLimits, SignupLimitsFormFields } from './signup-limits-tab';
import { HeadingPrimary } from '@components/headings';
import { Currency, PaymentType, PriceRow } from 'server/types';
import { CurrencyInput } from './currency-input';
import { decimalToCents } from 'shared/payments/fmt';
import { BtnStripeConnect } from './stripe-connect';
import { completeCourseStep } from '@components/course-checklist';
import { Toggle } from '@components/toggle';
import { AccessLimitsFormFields } from './access-limits-tab';
import { useRouteParams } from '@components/router';
import { isNullish } from 'shared/utils';
import type { h } from 'preact';

interface Props {
  courseId?: string;
  cancelHref?: string;
  onCancel?(): void;
  supportsStripe: boolean;
  supportsPaypal: boolean;
  createPrice(
    p: Omit<PriceRow, 'id' | 'productId' | 'createdAt' | 'updatedAt'>,
  ): Promise<{ id: string }>;
}

export async function onSubmit(
  props: Pick<Props, 'createPrice' | 'supportsStripe' | 'supportsPaypal'>,
  formData: any,
) {
  const { createPrice, supportsStripe, supportsPaypal } = props;
  const limits = formDataToPriceLimits(formData);
  const currency: Currency = formData.currency || 'USD';
  const paymentType: PaymentType = formData.paymentType;
  const allowPaypal = supportsPaypal && paymentType === 'paid' && formData.allowPaypal !== false;
  const freeTrialPeriod =
    (!allowPaypal && formData.freeTrialPeriod && parseInt(formData.freeTrialPeriod, 10)) || 0;
  const price = await createPrice({
    paymentType,
    isEnabled: true,
    name: formData.name || nameFromPaymentType(paymentType),
    currency,
    interval: formData.interval,
    allowStripe: supportsStripe && paymentType !== 'free' && formData.allowStripe !== false,
    allowPaypal,
    freeTrialPeriod,
    isGiftable: formData.isGiftable,
    allowSelfCancel: !formData.requireContactToCancel,
    numPayments: formData.numPayments ? parseInt(formData.numPayments) : undefined,
    priceInCents: decimalToCents(formData.priceInCents, currency),
    accessDuration: formData.accessDuration ? parseInt(formData.accessDuration) : undefined,
    expiresOn: limits.expiresOn || undefined,
    availableOn: limits.availableOn || undefined,
    maxPurchases: isNullish(limits.maxPurchases) ? undefined : limits.maxPurchases,
  });
  showToast({
    type: 'ok',
    title: 'Saved',
    message: 'Price point saved.',
  });
  return price;
}

export function PriceFormFields({
  courseId,
  supportsPaypal,
  supportsStripe,
}: Pick<Props, 'courseId' | 'supportsStripe' | 'supportsPaypal'>) {
  const [paymentType, setPaymentType] = useState<PaymentType>('free');

  // The div below is important. We were using Fragment <></> and that actually
  // caused weird reflow issues with Preact. We may try upgrading Preact to
  // see if that is fixed.
  return (
    <div class="space-y-10">
      <ChoosePaymentType
        courseId={courseId}
        paymentType={paymentType}
        setPaymentType={setPaymentType}
        supportsStripe={!!supportsStripe}
        supportsPaypal={!!supportsPaypal}
      />
      <Price paymentType={paymentType} />
      <PricePointName paymentType={paymentType} />
      <div class="border rounded-lg p-8">
        <h2 class="text-xl mb-6">Options</h2>
        <div class="flex flex-col gap-10">
          <Provider
            paymentType={paymentType}
            supportsStripe={supportsStripe}
            supportsPaypal={supportsPaypal}
          />
          <FreeTrialPeriod paymentType={paymentType} />
          {courseId && <RequireContactToCancel paymentType={paymentType} defaultValue={true} />}
          {courseId && <AllowGiftsToggle paymentType={paymentType} defaultValue={true} />}
          {courseId && <AccessLimitsFormFields accessDuration={-1} />}
          <ExpandableLimitsFields />
        </div>
      </div>
    </div>
  );
}

export function NewPriceForm(props: Props) {
  const { courseId, cancelHref, onCancel } = props;
  return (
    <AsyncForm
      class="flex flex-col"
      onSubmit={async (formData) => {
        await onSubmit(props, formData);
        if (courseId) {
          completeCourseStep('pricePoint');
        }
      }}
    >
      {cancelHref && <BackNavigation cancelHref={cancelHref} />}
      <HeadingPrimary
        title={
          <span class="flex items-center">
            <IcoCreditCard class="w-12 h-12 bg-green-400 rounded-full text-white p-2 mr-4" />
            New Price Point
          </span>
        }
      />
      <PriceFormFields {...props} />
      <footer class="flex items-center mt-10">
        <BtnPrimary class="mr-3">Create Price Point</BtnPrimary>
        <BtnSecondary type="button" href={cancelHref} onClick={onCancel}>
          Cancel
        </BtnSecondary>
      </footer>
    </AsyncForm>
  );
}

function BackNavigation({ cancelHref }: Pick<Props, 'cancelHref'>) {
  return (
    <header class="mb-2">
      <a href={cancelHref} class="inline-flex items-center space-x-1.5 text-zinc-400 -ml-1">
        <IcoChevronLeft />
        <span>Back to price points</span>
      </a>
    </header>
  );
}

function Price({ paymentType }: { paymentType: PaymentType }) {
  if (paymentType === 'paid') {
    return (
      <FormSection title="Price">
        <FormSubSection>
          <FormGroup prop="priceInCents">
            <CurrencyInput name="priceInCents" />
          </FormGroup>
        </FormSubSection>
      </FormSection>
    );
  }
  if (paymentType === 'paymentplan') {
    return (
      <FormSection title="Price">
        <FormSubSection>
          <FormGroup prop="priceInCents">
            <FormGroup prop="numPayments">
              <CurrencyInput name="priceInCents" />
              <span class="mx-2">every month for</span>
              <input
                type="number"
                name="numPayments"
                min="1"
                max="50"
                class="inline-ruz-input w-16 appearance-none"
                placeholder="#"
              />
              <span class="mx-2">months</span>
            </FormGroup>
          </FormGroup>
        </FormSubSection>
      </FormSection>
    );
  }
  if (paymentType === 'subscription') {
    return (
      <FormSection title="Configure subscription">
        <FormSubSection>
          <FormGroup prop="priceInCents">
            <CurrencyInput name="priceInCents" />
            <span class="mx-2">every</span>
            <select name="interval" class="inline-ruz-input">
              <option value="month">month</option>
              <option value="year">year</option>
            </select>
          </FormGroup>
        </FormSubSection>
        <div class="text-gray-500 mt-4">Subscriptions are handled through Stripe.</div>
      </FormSection>
    );
  }
  return null;
}

export function AllowGiftsToggle({
  paymentType,
  defaultValue,
}: {
  paymentType: PaymentType;
  defaultValue: boolean;
}) {
  const { courseId } = useRouteParams();

  if (paymentType !== 'paid' || !courseId) {
    return null;
  }

  return (
    <label class="inline-flex items-center space-x-3">
      <Toggle defaultChecked={defaultValue} name="isGiftable" />
      <span>Allow gift purchases</span>
    </label>
  );
}

export function RequireContactToCancel({
  paymentType,
  defaultValue,
}: {
  paymentType: PaymentType;
  defaultValue: boolean;
}) {
  const { courseId } = useRouteParams();

  if (paymentType !== 'paymentplan' || !courseId) {
    return null;
  }

  return (
    <FormSection title="Cancellation policy">
      <FormSubSection>
        <label class="inline-flex items-center space-x-3">
          <Toggle defaultChecked={defaultValue} name="requireContactToCancel" />
          <span>Require students to contact you in order to cancel their payment plan</span>
        </label>
      </FormSubSection>
    </FormSection>
  );
}

export function FreeTrialPeriod(props: { paymentType: PaymentType; value?: number }) {
  const { paymentType } = props;
  const [value, setValue] = useState(props.value || 0);

  if (paymentType !== 'paymentplan' && paymentType !== 'subscription') {
    return null;
  }

  return (
    <FormSection title="Free trial">
      <FormSubSection>
        <FormGroup prop="freeTrialPeriod" class="inline-flex items-center space-x-2">
          <label class="inline-flex items-center space-x-3">
            <Toggle checked={value > 0} onClick={() => setValue(value === 0 ? 7 : 0)} />
            <span>Enable free trial for</span>
          </label>
          <input
            type="number"
            name="freeTrialPeriod"
            class="inline-ruz-input w-16 appearance-none"
            placeholder="#"
            disabled={value === 0}
            // Display placeholder when value is 0
            value={value || ''}
            onBlur={(e: any) => {
              const newValue = parseInt(e.target.value, 10);
              setValue(newValue || 0);
            }}
          />
          <span>days</span>
        </FormGroup>
      </FormSubSection>
    </FormSection>
  );
}

export function Provider({
  paymentType,
  supportsPaypal,
  supportsStripe,
  checkStripe,
  checkPaypal,
}: {
  paymentType: PaymentType;
  supportsStripe: boolean;
  supportsPaypal: boolean;
  checkStripe?: boolean;
  checkPaypal?: boolean;
}) {
  if (paymentType !== 'paid' || !supportsPaypal || !supportsStripe) {
    return null;
  }

  return (
    <FormSection title="Payment processing">
      <FormSubSection>
        <FormGroup prop="providers">
          <div class="flex gap-10">
            <label>
              <Toggle checked={isNullish(checkStripe) || checkStripe} name="allowStripe" />
              <span class="ml-3">Stripe</span>
            </label>
            <label>
              <Toggle checked={isNullish(checkPaypal) || checkPaypal} name="allowPaypal" />
              <span class="ml-3">PayPal</span>
            </label>
          </div>
        </FormGroup>
      </FormSubSection>
    </FormSection>
  );
}

function nameFromPaymentType(paymentType: PaymentType) {
  if (paymentType === 'free') {
    return 'Free';
  }
  if (paymentType === 'paid') {
    return 'Single Payment';
  }
  if (paymentType === 'subscription') {
    return 'Subscription';
  }
  if (paymentType === 'paymentplan') {
    return 'Payment Plan';
  }
}

export function PricePointName({ name, paymentType }: { name?: string; paymentType: PaymentType }) {
  return (
    <FormSection title="Name">
      <FormSubSection>
        <FormGroup prop="name">
          <input
            class="inline-ruz-input"
            type="text"
            name="name"
            value={name}
            maxLength={50}
            placeholder={nameFromPaymentType(paymentType)}
          />
        </FormGroup>
      </FormSubSection>
    </FormSection>
  );
}

function BtnChoice({
  children,
  isSelected,
  ...props
}: { isSelected: boolean } & h.JSX.HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      type="button"
      class={`px-4 py-2 inline-flex items-center space-x-2 border rounded mb-2 mr-3 focus:outline-none hover:bg-indigo-50 hover:border-indigo-200 hover:text-indigo-500  focus:ring-1 focus:ring-indigo-500 ${
        isSelected
          ? 'border-indigo-300 bg-indigo-50 text-indigo-700 focus:bg-indigo-100'
          : 'border-gray-100 bg-gray-50 text-gray-600'
      }`}
    >
      {children}
    </button>
  );
}

function ChoosePaymentType({
  paymentType,
  setPaymentType,
  supportsStripe,
  supportsPaypal,
  courseId,
}: {
  paymentType: PaymentType;
  setPaymentType(t: PaymentType): void;
  supportsStripe: boolean;
  supportsPaypal: boolean;
  courseId?: string;
}) {
  return (
    <FormSection title="Payment type">
      <FormSubSection>
        <FormGroup prop="paymentType" class="flex flex-wrap whitespace-nowrap">
          <input type="hidden" name="paymentType" value={paymentType} />
          <BtnChoice isSelected={paymentType === 'free'} onClick={() => setPaymentType('free')}>
            <IcoGift />
            <span>Free</span>
          </BtnChoice>
          {(supportsStripe || supportsPaypal) && (
            <BtnChoice isSelected={paymentType === 'paid'} onClick={() => setPaymentType('paid')}>
              <IcoDollar />
              <span>Single Payment</span>
            </BtnChoice>
          )}
          {supportsStripe && (
            <>
              <BtnChoice
                isSelected={paymentType === 'paymentplan'}
                onClick={() => setPaymentType('paymentplan')}
              >
                <IcoCalendar />
                <span>Payment Plan</span>
              </BtnChoice>
              <BtnChoice
                isSelected={paymentType === 'subscription'}
                onClick={() => setPaymentType('subscription')}
              >
                <IcoRefresh />
                <span>Subscription</span>
              </BtnChoice>
            </>
          )}
          {!supportsPaypal && !supportsStripe && (
            <p class="inline-block ml-2">
              <BtnStripeConnect class="text-indigo-600" courseId={courseId}>
                Configure Stripe
              </BtnStripeConnect>{' '}
              or <a href="/account/integrations">PayPal</a> if you want to accept payments.
            </p>
          )}
        </FormGroup>
      </FormSubSection>
    </FormSection>
  );
}

export function ExpandableLimitsFields({ price }: { price?: PriceRow }) {
  const hasLimits = price?.maxPurchases || price?.expiresOn || price?.availableOn;
  const [showLimits, setShowLimits] = useState(!!hasLimits);
  if (!showLimits) {
    return (
      <Button
        class="flex items-center mb-12 font-medium"
        type="button"
        onClick={() => setShowLimits(true)}
      >
        <span class="mr-2">Configure signup limits (optional)</span> <IcoChevronDown />
      </Button>
    );
  }
  return <SignupLimitsFormFields itemName="price point" item={price} />;
}
