import { Modal, ModalFooter } from '@components/modal';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { IcoInfo, IcoSend } from '@components/icons';
import { Email } from 'server/types';

const TEXT_REPLACEMENTS = {
  '%recipient.name%': 'Example Student',
  'Example Module': 'Example Module',
  '%recipient.startHour%': 'Start Hour',
  '%recipient.startDay%': 'Start Day',
};

type ReplacementOverrides = Partial<typeof TEXT_REPLACEMENTS>;

type Props = {
  email: Email;
  recipientsText: string;
  isSending: boolean;
  replacementOverrides?: ReplacementOverrides;
  onCancel: () => void;
  onSend: () => void;
};

function highlightDynamicText(text: string, overrides: ReplacementOverrides = {}) {
  let highlightedText = text;
  Object.entries(TEXT_REPLACEMENTS).forEach(([key, value]) => {
    const replacement = overrides[key as keyof ReplacementOverrides] || value;
    highlightedText = highlightedText.replace(key, replacement);
  });
  return highlightedText;
}

export const PreviewModal = ({
  email,
  recipientsText,
  replacementOverrides,
  isSending,
  onCancel,
  onSend,
}: Props) => {
  // Replace the mailgun template values with examples.
  const subject = highlightDynamicText(email.subject, replacementOverrides);
  const body = highlightDynamicText(email.body, replacementOverrides);

  return (
    <Modal isOpen size="sm:max-w-4xl" onCancel={onCancel}>
      <h3 class="text-lg lg:text-4xl mb-2 lg:mb-6">{subject}</h3>
      <div class="minidoc-preview overflow-y-auto max-h-96 mb-6 bg-gray-100 rounded-lg border-2 border-dashed border-gray-300">
        <div class="p-1 lg:p-4" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
      <div class="rounded-md bg-blue-50 p-4">
        <div class="flex">
          <div class="shrink-0">
            <IcoInfo class="h-5 w-5 text-blue-400" />
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-blue-700" data-private>
              You are about to send this email to {recipientsText}
            </p>
          </div>
        </div>
      </div>
      <ModalFooter>
        <BtnSecondary onClick={onCancel}>Cancel</BtnSecondary>
        <BtnPrimary aria-label="Send" isLoading={isSending} onClick={onSend}>
          <IcoSend />
          <span class="ml-2" data-private>
            Send to {recipientsText}
          </span>
        </BtnPrimary>
      </ModalFooter>
    </Modal>
  );
};

export default PreviewModal;
