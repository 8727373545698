import { ModalForm, showModalForm } from '@components/modal-form';
import { autoFocusSelf } from 'client/utils/autofocus';

interface Props {
  content: string;
  isEdit?: boolean;
}

export const showRawHtmlModal = ({ content, isEdit }: Props) =>
  showModalForm<{ content: string }>(() => (
    <ModalForm confirmButtonText={isEdit ? 'Apply Changes' : 'Insert HTML'}>
      <textarea
        placeholder="Enter HTML"
        name="content"
        class="ruz-input w-full h-96 font-mono"
        ref={(el) => autoFocusSelf(el, () => el?.select())}
      >
        {content}
      </textarea>
    </ModalForm>
  ));
