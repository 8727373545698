import { h } from 'minidoc-editor';
import { Align, RenderOpts } from './media-uploader';
import { mediaContextMenu } from 'client/lib/minidoc';

/**
 * The styles which will be applied to the image container when the user
 * selects a different image alignment.
 */
const imageAlign: Record<Align, any> = {
  left: {
    float: 'left',
    marginRight: '2rem',
    zIndex: '5',
  },
  right: {
    float: 'right',
    paddingLeft: '2rem',
    marginLeft: '2rem',
    zIndex: '5',
    boxSizing: 'content-box',
  },
  center: {
    float: 'none',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
};

interface ImageWidthConfig {
  width: string;
  marginLeft?: string;
  dontApplyOnMobile?: boolean;
}

/**
 * The styles which will be applied to the image container when the user
 * selects a different image size.
 */
const imageWidth: Record<number, ImageWidthConfig> = {
  120: {
    width: '120%',
    marginLeft: '-10%',
    // 120% breaks the layout on mobile, so we don't apply it there.
    dontApplyOnMobile: true,
  },
  100: {
    width: '100%',
  },
  50: {
    width: '50%',
  },
  25: {
    width: '25%',
  },
};

/**
 * This is an object which clears all the styles defined by imageWidth or
 * imageAlign.
 */
const unset = Object.values(imageWidth)
  .concat(Object.values(imageAlign))
  .reduce((acc: any, obj) => {
    Object.keys(obj).forEach((k) => {
      acc[k] = null;
    });
    return acc;
  }, {});

// Render the image align or size select menu.
function selectMenu({ options, ...props }: any) {
  const result = h<HTMLSelectElement>(
    'select.mini-context-action.bg-transparent.border-none.rounded-lg',
    props,
    options,
  );

  if (props.value) {
    result.value = props.value.toString();
  }
  return result;
}

const getSize = (opts: RenderOpts) => opts.state.size || '100';
const getPercent = (opts: RenderOpts) => parseInt(getSize(opts));
const getAlign = (opts: RenderOpts) => opts.state.align || 'center';

/**
 * Apply the image size and alignment rules to the image container element.
 */
export function setContainerStyle(opts: RenderOpts) {
  const percent = parseInt(getSize(opts));
  let containerStyles: any = { ...unset };
  if (percent < 100) {
    containerStyles = { ...containerStyles, ...imageAlign[getAlign(opts)] };
  }
  const isMobile = window.innerWidth <= 768;
  const widthConfig = imageWidth[percent as keyof typeof imageWidth];
  if (!isMobile || !widthConfig.dontApplyOnMobile) {
    containerStyles = { ...containerStyles, ...widthConfig };
  }
  Object.assign(opts.container.style, containerStyles);
}

/**
 * Render the image context menu if the editor is ediable.
 */
export function imageContextMenu(opts: RenderOpts) {
  if (opts.readonly) {
    return;
  }

  function applyStyleChanges() {
    sizeMenu.value = getSize(opts);
    alignMenu.value = getAlign(opts);
    opts.stateChanged(opts.state);
    setContainerStyle(opts);
  }

  const sizeMenu = selectMenu({
    value: getSize(opts),
    oninput(e: any) {
      const size = e.target.value;
      opts.state.size = size;
      if (getPercent(opts) >= 100) {
        opts.state.align = 'center';
      }
      applyStyleChanges();
    },
    options: Object.keys(imageWidth)
      .reverse()
      .map((k) => h('option', { value: k }, `Width ${k}%`)),
  });

  const alignMenu = selectMenu({
    value: opts.state.align || 'center',
    oninput(e: any) {
      const align = e.target.value;
      opts.state.align = align;
      if (align !== 'center' && getPercent(opts) >= 100) {
        opts.state.size = '50';
      }
      applyStyleChanges();
    },
    options: Object.keys(imageAlign).map((k) => h('option', { value: k }, `Align ${k}`)),
  });

  return mediaContextMenu({ label: 'Image' }, sizeMenu, alignMenu);
}
