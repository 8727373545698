/**
 * This module contains logic for toggling the editor toolbar to / from
 * a fixed / sticky position when the user scrolls.
 */
import { ComponentChildren } from 'preact';

export function StickyToolbar(props: {
  bgcolor?: string;
  coords?: string;
  pos?: string;
  children?: ComponentChildren;
}) {
  return (
    <div
      class={`editor-toolbar flex items-center ${props.bgcolor || 'bg-gray-50 shadow'} px-4 ${
        props.pos || 'sticky'
      } ${props.coords || 'top-0'} z-30 `}
    >
      {props.children}
    </div>
  );
}
