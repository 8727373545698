import { AsyncForm, FormGroup } from '@components/async-form';
import { Password } from '@components/password';
import { RouteProps, router } from '@components/router';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useAuth, useCurrentUser } from '@components/router/session-context';
import { UserProfileIcon } from '@components/avatars';
import { ErrorPage } from '@components/error-page';
import { URLS } from 'shared/urls';
import { detectTimezone } from 'shared/dateutil';
import { Button } from '@components/buttons';
import { useIntl } from 'shared/intl/use-intl';
import { DefaultSpinner } from '@components/spinner';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { TopBar } from '@components/top-bar';

const store = rpx.auth;

function performRedirect(props: RouteProps<RpxResponse<typeof store.getOneClickUser>>) {
  const { courseId, courseTitle, redirect } = props.route.params;
  router.goto(
    courseId && courseTitle
      ? URLS.student.course({
          course: {
            id: courseId,
            title: courseTitle,
          },
        })
      : redirect || '/',
  );
}

function PasswordResetPage(props: RouteProps<RpxResponse<typeof store.getOneClickUser>>) {
  const intl = useIntl();
  const auth = useAuth();

  const { newUser } = props.route.params;
  // This page is used by Zapier's `enrollStudent` action
  // and demo guide signup flow, too.
  // It means the user has never used Ruzuku in that case
  // so we need to modify some wordings.
  const isNewUser = !!newUser;
  const passwordLabel = isNewUser ? intl('Password') : intl('New password');

  return (
    <FixedPage title={intl('Create a new password')}>
      <FixedContent class="bg-white">
        <TopBar />

        <AsyncForm
          class="max-w-96 m-auto"
          onSubmit={async (data) => {
            await auth.login(
              store.oneClickLogin({
                ...data,
                timezone: isNewUser ? detectTimezone() : undefined,
              }),
            );
            performRedirect(props);
          }}
        >
          <div class="text-gray-500 flex items-center mb-8 border rounded-md p-4 bg-gray-50">
            <UserProfileIcon size="h-12 w-12" user={props.data} />
            <span class="ml-4">
              <strong class="block">{props.data.name}</strong>
              <span>{props.data.email}</span>
            </span>
          </div>

          <h2 class="text-xl text-gray-600 mb-4 text-center">
            {isNewUser ? intl('Welcome!') : intl('Welcome Back!')}
          </h2>
          <p class="text-gray-600 mb-4 text-center">
            {intl('To continue, please create a {passwordLabel:string}.', {
              passwordLabel: passwordLabel.toLowerCase(),
            })}
          </p>

          <FormGroup prop="newPassword" class="w-full mb-4">
            <Password
              name="newPassword"
              placeholder={passwordLabel}
              class="bg-transparent"
              autocomplete="new-password"
            />
          </FormGroup>
          <FormGroup prop="confirmPassword" class="w-full mb-4">
            <Password
              name="confirmPassword"
              placeholder={intl('Confirm {passwordLabel:string}', {
                passwordLabel,
              })}
              class="bg-transparent"
              autocomplete="new-password"
            />
          </FormGroup>
          <input type="hidden" name="resetCode" value={props.route.params.code} />
          <Button class="btn-primary w-full">{intl('Save password & sign in')}</Button>
        </AsyncForm>
      </FixedContent>
    </FixedPage>
  );
}

function AuthenticatedPage(props: RouteProps<RpxResponse<typeof store.getOneClickUser>>) {
  useAsyncEffect(async () => {
    performRedirect(props);
  }, []);

  return (
    <div class="w-screen h-screen flex items-center justify-center">
      <DefaultSpinner />
    </div>
  );
}

router.add({
  url: 'one-click-login',
  isPublic: true,
  load({ params }) {
    return store.getOneClickUser({ resetCode: params.code });
  },
  render(props: RouteProps<RpxResponse<typeof store.getOneClickUser>>) {
    const intl = useIntl();
    const user = useCurrentUser();
    const isError = !user && !props.data?.email;

    if (isError) {
      return <ErrorPage title={intl('Wrong or expired login code.')} />;
    }
    if (user) {
      return <AuthenticatedPage {...props} />;
    }
    return <PasswordResetPage {...props} />;
  },
});
