import { rpx } from 'client/lib/rpx-client';
import { UserProfileIcon } from '@components/avatars';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { showError } from '@components/app-error';
import { IcoChevronLeft } from '@components/icons';
import { truncateId } from 'shared/utils';
import { showModalForm } from '@components/modal-form';
import { useState } from 'preact/hooks';
import { Modal, ModalFooter, ModalMessage, ModalTitle } from '@components/modal';
import { showToast } from '@components/toaster';
import { TenantUsersList } from '../admin-people/user-list';
import { CourseRow, Tenant } from 'server/types';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { Case } from '@components/conditional';
import { TenantsList } from '../superadmin-manage-tenants/tenants-list';
import { AsyncForm, FormGroup } from '@components/async-form';

type Course = Pick<CourseRow, 'id' | 'title' | 'isBundle'> & {
  guide: {
    id: UUID;
  };
};

export function showCourseCopyModal(course: Course) {
  showModalForm(({ resolve }) => {
    const currentUser = useCurrentUser();
    const currentTenant = useCurrentTenant();
    const courseLabel = course.isBundle ? 'bundle' : 'course';

    const [toUser, setToUser] = useState<{ id: UUID; name?: string } | undefined>(undefined);
    const [toTenant, setToTenant] = useState<Pick<Tenant, 'id' | 'name'>>(currentTenant);
    const [isSelectingTenant, setIsSelectingTenant] = useState(false);
    const [isCopying, setIsCopying] = useState(false);

    const copyCourse = async (formData: { title: string }) => {
      if (!toUser) {
        return;
      }
      setIsCopying(true);
      try {
        await rpx.admin.copyCourseAsAdmin({
          courseId: course.id,
          toGuideId: toUser.id,
          tenantId: toTenant.id,
          title: formData.title,
          isBundle: course.isBundle,
          // Do not duplicate subcourses if the course is being copied to the same user
          duplicateSubcourses: course.guide.id !== toUser.id,
        });
        showToast({
          title: 'Copy succeeded',
          message: `The ${courseLabel} has been copied to ${toUser.name} with the title "${formData.title}".`,
          type: 'ok',
        });
        resolve(true);
      } catch (err) {
        if (err.type === 'validation') {
          throw err;
        }
        showError(err);
      } finally {
        setIsCopying(false);
      }
    };

    return (
      <Modal size={toUser ? 'w-3xl' : 'w-5xl'} isOpen onCancel={() => resolve(undefined)}>
        {toUser && (
          <AsyncForm onSubmit={copyCourse}>
            <Button
              class="absolute top-8 left-8 inline-flex items-center"
              onClick={() => setToUser(undefined)}
            >
              <IcoChevronLeft class="w-4 h-4 opacity-75 mr-1" />
            </Button>
            <ModalTitle centered>
              Copying the {courseLabel} into <strong>{toUser.name}</strong>
            </ModalTitle>
            <ModalMessage class="my-8">
              <label class="block mb-2">Enter the title of the new {courseLabel}</label>
              <FormGroup prop="title" class="w-full mb-6">
                <input
                  type="text"
                  placeholder={`${courseLabel} title`}
                  name="title"
                  class="ruz-input"
                  defaultValue={course.title}
                />
              </FormGroup>
            </ModalMessage>
            <ModalFooter>
              <BtnSecondary onClick={resolve}>Cancel</BtnSecondary>
              <BtnPrimary disabled={isCopying}>Copy the {courseLabel}</BtnPrimary>
            </ModalFooter>
          </AsyncForm>
        )}
        {!toUser && (
          <Case
            when={!isSelectingTenant}
            fallback={
              <>
                <ModalTitle>Select a tenant</ModalTitle>
                <TenantsList
                  onItemSelect={(tenant) => {
                    setToTenant(tenant);
                    setIsSelectingTenant(false);
                  }}
                />
              </>
            }
          >
            <div class="flex justify-between">
              <ModalTitle>
                Copy {courseLabel}: choose a guide from {toTenant.name} tenant
              </ModalTitle>
              {currentUser?.level === 'superadmin' && (
                <BtnSecondary onClick={() => setIsSelectingTenant(true)}>
                  Select another tenant
                </BtnSecondary>
              )}
            </div>
            <TenantUsersList
              type="all"
              tenantId={toTenant.id}
              userLevels={['guide', 'admin', 'superadmin']}
              tableHeaders={['New Guide', 'Role / ID']}
              onSelect={setToUser}
              render={({ user, href }) => (
                <a
                  class="table-row text-inherit hover:bg-indigo-50 text-sm"
                  href={`#${href}`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setToUser(user);
                  }}
                >
                  <span class="table-cell p-4 border-t">
                    <span class="flex items-center">
                      <UserProfileIcon user={user} size="w-10 h-10" />
                      <span class="flex flex-col ml-4">
                        <strong>{user.name}</strong>
                        <span>{user.email}</span>
                      </span>
                    </span>
                  </span>
                  <span class="table-cell p-4 border-t">
                    <span class="flex flex-col">
                      <span>{user.level}</span>
                      <span>{truncateId(user.id)}</span>
                    </span>
                  </span>
                </a>
              )}
            />
            <ModalFooter>
              <BtnSecondary onClick={resolve}>Cancel</BtnSecondary>
            </ModalFooter>
          </Case>
        )}
      </Modal>
    );
  });
}
