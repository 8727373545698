import { ComponentChildren } from 'preact';

interface Props {
  color?: PillColor;
  margin?: string;
  animation?: string;
  capitalize?: boolean;
  uppercase?: boolean;
  children?: ComponentChildren;
}

export type PillColor = 'green' | 'gray' | 'red' | 'yellow' | 'blue';

const colorClasses: Record<PillColor, string> = {
  gray: 'bg-gray-100 text-gray-700',
  red: 'bg-red-100 text-red-800',
  yellow: 'bg-yellow-100 text-yellow-700',
  green: 'bg-green-100 text-green-800',
  blue: 'bg-blue-100 text-blue-800',
};

/**
 * A rounded, shaded pill.
 */
export function Pill(props: Props) {
  return (
    <span
      class={`inline-flex items-center justify-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 ${
        props.margin || ''
      } ${props.capitalize ? 'capitalize' : ''} ${props.uppercase ? 'uppercase' : ''} ${
        props.animation || ''
      } ${colorClasses[props.color || 'green']} `}
    >
      {props.children}
    </span>
  );
}
