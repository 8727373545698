import { showError } from '@components/app-error';
import { Button } from '@components/buttons';
import { IcoPlus, IcoX } from '@components/icons';
import { LoadingIndicator } from '@components/loading-indicator';
import { useCurrentTenant } from 'client/lib/auth';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useImageUrl } from 'client/utils/cdn';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { useIntl } from 'shared/intl/use-intl';

const store = rpx.instantCourses;
export type Template = RpxResponse<typeof store.getInstantCourses>[0];

interface Props {
  isOpen: boolean;
  onBlankCourse: (opts: { isProduct: boolean }) => void;
  onTemplateCourse: (template: Template) => void;
  hide: () => void;
}

export function CreateCourseRibbon({ isOpen, onBlankCourse, onTemplateCourse, hide }: Props) {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<Template[]>([]);

  useAsyncEffect(async () => {
    if (!isOpen || templates.length > 0) {
      return;
    }

    setIsLoading(true);
    try {
      const templates = await store.getInstantCourses({
        isGlobal: true,
      });
      setTemplates(templates);
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div class="bg-gray-100 mb-8 mx-4 md:mx-0">
      {isLoading && <LoadingIndicator />}
      <div class="max-w-full w-5xl p-8 mx-auto relative">
        <Button class="absolute right-0 top-0 p-8" onClick={hide}>
          <IcoX class="w-4 h-4" />
        </Button>
        <label>Create a new course{!tenant.isCore && ' or product'}</label>
        <nav class="whitespace-nowrap overflow-x-auto mini-scroll grid md:flex grid-cols-2 gap-4 items-start mt-3">
          <CourseTemplate
            onClick={() => onBlankCourse({ isProduct: false })}
            title={intl('Blank course')}
          >
            <span class="flex flex-col text-green-600">
              <IcoPlus class="w-12 h-12" />
              Course
            </span>
          </CourseTemplate>
          {!tenant.isCore && (
            <CourseTemplate
              onClick={() => onBlankCourse({ isProduct: true })}
              title={intl('Blank product')}
            >
              <span class="flex flex-col text-sky-600">
                <IcoPlus class="w-12 h-12" />
                Product
              </span>
            </CourseTemplate>
          )}
          {templates.map((t) => (
            <CourseTemplate
              key={t.id}
              onClick={() => onTemplateCourse(t)}
              img={t.imagePath}
              title={t.title}
            />
          ))}
        </nav>
      </div>
    </div>
  );
}

function CourseTemplate({
  img,
  title,
  onClick,
  children,
}: {
  img?: string;
  title: string;
  onClick(): void;
  children?: ComponentChildren;
}) {
  const src = useImageUrl(img);

  return (
    <Button
      class="w-28 md:w-36 overflow-hidden inline-flex flex-col mr-4"
      type="button"
      onClick={onClick}
    >
      <span class="flex items-center justify-center h-48 bg-white w-full rounded-md border mb-2 overflow-hidden">
        {src && <img src={`${src}?width=600`} class="object-cover h-full" />}
        {children}
      </span>
      <strong class="text-sm line-clamp-2 whitespace-normal w-full block text-left mt-1 px-1">
        {title}
      </strong>
    </Button>
  );
}
