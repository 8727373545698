/**
 * This module contains the logic for managing asset uploads within the editor.
 */

import { Minidoc } from 'client/lib/minidoc';
import { rpx, upload } from 'client/lib/rpx-client';
import { Changeable } from 'minidoc-editor/dist/types/undo-redo';
import { baseUrl } from 'shared/urls';
import { CardRenderOptions } from 'minidoc-editor';
import { showError } from '@components/app-error';
import { shouldUseWasabiProxy } from 'client/utils/cdn';
import { captureException } from 'client/lib/sentry';

export type Align = 'left' | 'center' | 'right';

/**
 * The shape of the JSON embedded in the media-card.
 */
export interface MediaState {
  /**
   * The URL to the media, proxied through our API layer
   * to allow future security / updates, etc.
   */
  url: string;

  /**
   * The URL to the poster image, if any.
   */
  poster?: string;

  /**
   * The type of media, e.g. image/png, video/mp4
   */
  type: string;

  /**
   * The name of the file.
   */
  name: string;

  /**
   * The aspect ratio. This is a percentage of the height / width,
   * and is used to keep the document from bouncing as images /
   * video load.
   */
  ratio?: number;

  /**
   * This is the width of the asset, used to ensure the ratio is
   * applied to the correct width, rather than to the full document
   * width. (e.g. images which are narrower than the document)
   */
  width?: number;

  /**
   * The caption, if any, displayed beneath the media.
   */
  caption?: string;

  /**
   * If set, this is the hyperlink the image will be wrapped in.
   */
  href?: string;

  /**
   * If set, this is the size the user has specified, overriding width.
   * It is a percent, for example, 120, 100, 50, 25
   */
  size?: string;

  /**
   * If set, this is the alignment (left, center, right).
   */
  align?: Align;

  /**
   * If true, this allows users download the media.
   * This is only supported for PDFs right now.
   */
  downloadable?: boolean;
}

export type RenderOpts = CardRenderOptions<MediaState> & {
  isPublic: boolean;
  cdnWidth?: number;
  tmpUrl?: string;
  onRetry: () => void;
};

/**
 * The handler for an upload progress notification.
 */
type ProgressHandler = (progress: number) => void;

/**
 * This is the data required to track asset upload progress and the asset file
 * across undo / redo operations.
 */
export interface UploadState {
  /**
   * The media state associated with this upload. This allows us
   * to have a stable undo / redo.
   */
  mediaState: MediaState;
  /**
   * The promise that will resolve when the upload completes.
   */
  promise: Promise<any>;
  /**
   * The file being uploaded.
   */
  file: File;
  /**
   * Indicates whether or not the upload is complete.
   */
  isUploading: boolean;
  /**
   * Retry the upload if it failed.
   */
  retry(): Promise<unknown>;
  /**
   * Indicate that the upload was interrupted or failed, and needs retry
   */
  isFailed?: boolean;
  /**
   * Register a function to get notified of upload progress. This returns the
   * unregister / off function.
   */
  onProgress?: (fn: ProgressHandler) => () => void;
}

/**
 * Create an object that tracks editor file uploads. This allows upload
 * status and other info to be stable across undo /redo operations.
 */
class MediaUploader {
  editor: Minidoc;

  uploads = {} as Record<string, UploadState | undefined>;

  constructor(editor: Minidoc) {
    this.editor = editor;
  }

  /**
   * Get the upload state associated with the specified URL.
   */
  getUploadState(url: string): UploadState | undefined {
    return this.uploads[url];
  }

  /**
   * Create the upload state, and begin the upload.
   */
  createUploadState({
    file,
    isPublic,
    downloadable,
    domain,
  }: {
    file: File;
    isPublic: boolean;
    downloadable?: boolean;
    domain?: string;
  }): UploadState {
    const progressHandlers = new Set<ProgressHandler>();
    const tmpUrl = `tmp:${Date.now()}-${file.name}`;
    const mediaState: MediaState = {
      url: tmpUrl,
      name: file.name,
      type: file.type,
      downloadable,
    };
    const retry = async () => {
      const useProxy = await shouldUseWasabiProxy();
      return rpx.files
        .createPresignedPost({
          type: file.type,
          name: file.name,
          size: file.size,
          isPublic,
          // Upload through the proxy server when Wasabi is not accessible.
          shouldUseWasabiProxy: useProxy,
        })
        .then(async ({ fileId, presignedPost, url }) => {
          // Upload to the S3-like store.
          await upload({
            file,
            fileId,
            presignedPost,
            onProgress: (progress) => progressHandlers.forEach((f) => f(progress)),
          }).promise;
          // Replace the temporary URL with the real one.
          mediaState.url = domain ? new URL(url, baseUrl({ domain })).href : url;
          // Allow the upload information to be looked up via the real URL.
          this.uploads[tmpUrl] = uploadState;
          uploadState.isUploading = false;
          uploadState.isFailed = false;
        })
        .catch((err) => {
          // Display a failed upload indicator with retry/cancel options
          uploadState.isFailed = true;
          // Log the error so the session is recorded on Sentry Replay
          captureException(new Error('Failed to upload file'));

          showError({
            title: `Failed to upload ${file.name}`,
            error: 'Please try again later.',
          });

          throw err;
        })
        .then(() => (uploadState.onProgress = undefined))
        .then(() => (this.editor as Changeable).onChange());
    };
    const uploadState: UploadState = {
      file,
      mediaState,
      isFailed: false,
      isUploading: true,
      onProgress(fn) {
        progressHandlers.add(fn);
        return () => progressHandlers.delete(fn);
      },
      promise: retry(),
      retry() {
        uploadState.isUploading = true;
        uploadState.isFailed = false;
        uploadState.promise = retry();
        return uploadState.promise;
      },
    };

    // Allow the upload information to be looked up via the temporary URL.
    this.uploads[tmpUrl] = uploadState;

    return uploadState;
  }
}

/**
 * Get the media upload tracker for the specified editor.
 */
export function mediaUploader(editor: any): MediaUploader {
  if (!editor._uploader) {
    editor._uploader = new MediaUploader(editor);
  }
  return editor._uploader;
}

/**
 * Get the number of pending uploads.
 */
export function uploadCount(editor: any) {
  return Object.values(mediaUploader(editor).uploads).filter((v) => !!v && v.isUploading).length;
}
