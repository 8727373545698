import { Button } from '@components/buttons';
import { IcoCreditCard, IcoTag } from '@components/icons';
import { useState } from 'preact/hooks';
import { PriceRow, PurchaseRow, CouponRow } from 'server/types';
import { PaymentHistory } from './payment-history';
import { PriceSummary } from './price-summary';
import { SubscriptionStatus, planDescription } from './subscription-status';
import { rpx } from 'client/lib/rpx-client';
import * as fmt from 'shared/payments/fmt';
import { UpcomingInvoice } from './upcoming-invoice';
import { useRouteParams } from '@components/router';
import { showConfirmModal } from '@components/modal-form';
import { showError } from '@components/app-error';
import { RadioGroupButton } from '@components/radio-button';
import * as pmtmath from 'shared/payments/math';

export function PurchasePane({
  price,
  coupon,
  purchase,
  fromBundle,
  changePlan,
  allowCancel = true,
  asSeller,
  priceUrl,
  couponUrl,
  guideEmail,
  onPurchaseChange,
}: {
  price?: PriceRow;
  coupon?: CouponRow;
  purchase?: PurchaseRow;
  fromBundle?: string;
  changePlan?(): void;
  asSeller: boolean;
  allowCancel?: boolean;
  couponUrl?: string;
  priceUrl?: string;
  guideEmail?: string;
  onPurchaseChange?: (purchase: Partial<PurchaseRow>) => void;
}) {
  const [historyReset, setHistoryReset] = useState(0);

  if (!price || !purchase) {
    return null;
  }

  const isSubscriptionLike = fmt.isSubscriptionLike(price) && !pmtmath.isFree({ coupon, price });
  const { courseId } = useRouteParams();
  const allowChanges = allowCancel || asSeller;
  const showDisableAccessWhenCanceledToggle =
    !!courseId && asSeller && allowChanges && isSubscriptionLike;
  const isActive = purchase.status === 'active' || purchase.status === 'canceling';

  const toggleAutomaticRemoval = async (newValue: boolean) => {
    if (newValue === purchase.disableAccessWhenCanceled) {
      return;
    }
    // Make sure the user really wants to disable access
    if (isActive && !newValue) {
      const isConfirmed = await showConfirmModal({
        title: 'Are you sure?',
        body: 'Ruzuku will not disable access to the course when this subscription is canceled. You may still manually disable student access anytime.',
      });
      if (!isConfirmed) {
        return false;
      }
    }

    try {
      await rpx.subscriptionStatus.updateShouldDisableAccessWhenCanceled({
        purchaseId: purchase.id,
        shouldDisableAccess: newValue,
      });
      onPurchaseChange?.({ disableAccessWhenCanceled: newValue });
    } catch (err) {
      showError(err);
    }
  };

  return (
    <div class="space-y-8">
      <div class="grid grid-cols-1 divide-y md:divide-y-0 md:divide-x md:grid-cols-2 border rounded">
        <div class="flex flex-col gap-2 p-6">
          <Button class="font-bold flex items-center gap-2" href={priceUrl}>
            <IcoCreditCard />
            <span>{price.name}</span>
          </Button>
          {coupon && (
            <Button class="flex items-center gap-2 font-medium" href={couponUrl}>
              <IcoTag />
              <span class="opacity-75">{coupon.code}</span>
            </Button>
          )}
          <div>
            <PriceSummary price={price} coupon={coupon} />
            {purchase.isGift && <span>(gifted)</span>}
            {!!fromBundle && <span>Purchased from "{fromBundle}" bundle</span>}
          </div>
          {changePlan && allowChanges && (
            <Button onClick={changePlan} class="text-indigo-600 text-left">
              Change plan
            </Button>
          )}
          {isSubscriptionLike && (
            <SubscriptionStatus
              purchase={purchase}
              price={price}
              courseId={courseId}
              allowChanges={allowChanges}
              asSeller={asSeller}
              guideEmail={guideEmail}
            />
          )}
        </div>
        {purchase.status === 'active' && isSubscriptionLike && (
          <div class="p-6">
            <UpcomingInvoice key={historyReset} purchaseId={purchase.id} asSeller={asSeller} />
          </div>
        )}
      </div>
      {!purchase.isGift && !fromBundle && (
        <PaymentHistory
          key={historyReset}
          purchaseStatus={purchase.status}
          paymentType={price.paymentType}
          purchaseId={purchase.id}
          priceId={purchase.priceId}
          asSeller={asSeller}
          onReset={() => setHistoryReset((x) => x + 1)}
        />
      )}
      {showDisableAccessWhenCanceledToggle && (
        <footer class="flex flex-col gap-4">
          <h3 class="font-semibold">
            <span class="capitalize">{planDescription(price.paymentType)}</span> cancellation
            behavior
          </h3>
          <div class="border rounded flex flex-col">
            <RadioGroupButton
              wrapperClass="p-4 rounded-t"
              name="automaticRemoval"
              value="true"
              checked={purchase.disableAccessWhenCanceled}
              onClick={(e) => {
                e.preventDefault();
                toggleAutomaticRemoval(true);
              }}
            >
              <span class="flex flex-col">
                <strong>Automatic removal</strong>
                <span>
                  Ruzuku will automatically revoke access if the{' '}
                  {planDescription(price.paymentType)} fails.
                </span>
              </span>
            </RadioGroupButton>
            <RadioGroupButton
              wrapperClass="p-4 rounded-b"
              name="automaticRemoval"
              value="false"
              checked={!purchase.disableAccessWhenCanceled}
              onClick={(e) => {
                e.preventDefault();
                toggleAutomaticRemoval(false);
              }}
            >
              <span class="flex flex-col">
                <strong>Manual removal</strong>
                <span>Access will not be revoked unless you manually revoke it.</span>
              </span>
            </RadioGroupButton>
          </div>
        </footer>
      )}
    </div>
  );
}
