import { AsyncForm } from '@components/async-form';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { Modal } from '@components/modal';
import { showModalForm } from '@components/modal-form';
import { courseLabel } from 'shared/terminology';
import { Course } from 'server/types';
import { useCurrentTenant } from '@components/router/session-context';
import { rpx } from 'client/lib/rpx-client';

/**
 * showCourseTitleModal displays a modal for renaming the course.
 */
export function showCourseTitleModal(course: Pick<Course, 'id' | 'title' | 'isBundle'>) {
  showModalForm(({ resolve }) => {
    const tenant = useCurrentTenant();
    const hide = () => resolve(undefined);
    const renameCourse = async ({ title }: { title: string }) => {
      await rpx.courses.renameCourse({ id: course.id, title });
      location.reload();
    };
    return (
      <Modal isOpen onCancel={hide}>
        <AsyncForm class="w-full" onSubmit={renameCourse}>
          <h2 class="text-lg text-gray-600 font-bold mb-2">
            Rename{' '}
            {courseLabel({
              course,
              tenant,
            })}
          </h2>
          <input type="text" value={course.title} name="title" class="ruz-input" />
          <footer class="mt-3 space-x-3 text-right">
            <BtnSecondary type="button" onClick={hide}>
              Cancel
            </BtnSecondary>
            <BtnPrimary>Save title</BtnPrimary>
          </footer>
        </AsyncForm>
      </Modal>
    );
  });
}
