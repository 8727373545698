/**
 * The totals module contains the subtotal, coupon, and total section of
 * the checkout screens. It allows a customer to enter a coupon code and
 * look up / apply the coupon during the checkout process.
 */

import { useState } from 'preact/hooks';
import * as fmt from 'shared/payments/fmt';
import * as pmtmath from 'shared/payments/math';
import { CouponRow, PriceRow } from 'server/types';
import { autoFocusSelf } from 'client/utils/autofocus';
import { rpx } from 'client/lib/rpx-client';
import { intl, useIntl } from 'shared/intl/use-intl';
import { WithAvailability } from 'server/payments/availability';
import { Button } from '../buttons';
import { IcoPencil, IcoTrash } from '../icons';
import { CheckoutSection } from './checkout-section';

interface Props {
  price: WithAvailability<PriceRow>;
  coupon?: WithAvailability<CouponRow>;
  onCouponChange(coupon?: WithAvailability<CouponRow>): void;
}

function Subtotal(props: {
  price: pmtmath.BatchPrice;
  secondPrice?: pmtmath.BatchPrice;
  title: string;
  class?: string;
}) {
  const intl = useIntl();
  return (
    <div class={`flex justify-between text-gray-600 pt-4 ${props.class || ''}`}>
      <span>{props.title}</span>
      <span>
        <strong>{fmt.price(props.price)}</strong>
        <span class="font-normal">
          <span class="opacity-75">
            {' '}
            {fmt.priceSuffix({
              item: props.price,
              intl,
            })}
          </span>
          {props.secondPrice && (
            <span class="opacity-75 block">
              {intl('then')} {fmt.price(props.secondPrice)}{' '}
              {fmt.priceSuffix({
                item: props.secondPrice,
                intl,
              })}
            </span>
          )}
        </span>
      </span>
    </div>
  );
}

function Total(props: Props & { freeTrialPeriod?: number }) {
  const intl = useIntl();
  const { initial, final } = pmtmath.batches(props);

  return (
    <div class="flex justify-between text-gray-600 pt-4">
      <strong>{intl('Total')}</strong>
      <span class="text-right">
        {!!props.freeTrialPeriod && (
          <span class="block">
            <strong>{fmt.price({ priceInCents: 0, currency: props.price.currency })}</strong>
            <span class="text-gray-500"> {intl('due today, then')}</span>
          </span>
        )}
        <span class={props.freeTrialPeriod ? '' : 'font-bold'}>{fmt.price(initial || final)}</span>
        <span class="font-normal">
          <span class="opacity-75">
            {' '}
            {initial
              ? fmt.firstBatchSuffix({
                  item: initial,
                  intl,
                })
              : fmt.priceSuffix({
                  item: final,
                  intl,
                })}
          </span>
          {initial && (
            <span class="opacity-75 block text-right">
              {intl('then')} {fmt.price(final)}{' '}
              {fmt.priceSuffix({
                item: final,
                intl,
              })}
            </span>
          )}
        </span>
      </span>
    </div>
  );
}

function DiscountForm({ price, coupon, onCouponChange }: Props) {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const lookupCoupon = async () => {
    if (!code) {
      return;
    }

    setIsProcessing(true);
    try {
      const result = await rpx.payments.getCouponByCode({
        priceId: price.id,
        code,
      });
      if (!result) {
        setError(intl('Coupon "{code}" not found.', { code }));
      } else {
        onCouponChange(result);
        setIsExpanded(false);
      }
    } catch (err) {
      setError(err.message || err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div class="flex justify-between pt-4">
      <span>
        <span class="mr-3">{intl('Discount')}</span>
        <span class="flex sm:inline-flex items-center bg-white text-xs rounded-full overflow-hidden border text-gray-500">
          <span class="px-2">{coupon?.code || intl('None')}</span>
          {coupon?.code && !isExpanded && (
            <Button
              class="border-l hover:bg-gray-100 hidden sm:inline-block px-2"
              onClick={() => setIsExpanded(true)}
            >
              <IcoPencil />
            </Button>
          )}
          {coupon?.code && (
            <Button
              class="border-l hover:bg-gray-100 px-2"
              onClick={() => onCouponChange(undefined)}
            >
              <IcoTrash />
            </Button>
          )}
        </span>
      </span>
      <div class="text-right">
        {isExpanded && (
          <div
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
                lookupCoupon();
              }
            }}
          >
            <div class="flex">
              <input
                name="couponCode"
                class="ruz-input rounded-r-none uppercase"
                type="text"
                placeholder={intl('Enter a coupon code')}
                value={code}
                ref={autoFocusSelf}
                onInput={(e: any) => {
                  setCode(e.target.value);
                  setError('');
                }}
                disabled={isProcessing}
              />
              <Button
                type="button"
                class="bg-gray-50 border border-l-0 rounded-r px-4"
                onClick={lookupCoupon}
                disabled={code.length === 0 || isProcessing}
                isLoading={isProcessing}
              >
                {intl('Apply')}
              </Button>
            </div>
            {error && <p class="text-red-600 pt-4">{error}</p>}
          </div>
        )}
        {!isExpanded && coupon && (
          <span>
            {fmt.discountPrice({ price, coupon })}
            <span class="opacity-75 font-normal">
              {' '}
              {fmt.discountSuffix({ price, coupon, intl })}
            </span>
          </span>
        )}
        {!isExpanded && !coupon && (
          <Button type="button" class="text-indigo-600" onClick={() => setIsExpanded(true)}>
            {intl('Apply a coupon')}
          </Button>
        )}
      </div>
    </div>
  );
}

function FreeTrial({ freeDays }: { freeDays?: number }) {
  if (!freeDays) {
    return null;
  }

  return (
    <div class="flex justify-between text-gray-600 border-t border-dashed pt-4">
      <span>Free Trial</span>
      <strong>
        {intl('{freeDays:number} {freeDays | pluralize day days}', {
          freeDays,
        })}
      </strong>
    </div>
  );
}

function Availability(props: { price: Props['price']; coupon: Props['coupon'] }) {
  const priceAvailability = fmt.availability({ item: props.price, intl });
  const couponAvailability = props.coupon ? fmt.availability({ item: props.coupon, intl }) : '';
  const availability = priceAvailability || couponAvailability;

  if (!availability) {
    return null;
  }

  return (
    <header class="bg-red-50 border border-red-200 p-2 px-4 rounded-md text-red-600 font-medium">
      {availability}
    </header>
  );
}

export function Totals(props: Props) {
  const intl = useIntl();
  const freeTrialPeriod = pmtmath.freeTrialPeriod(props);

  if (props.price.paymentType === 'free') {
    return (
      <CheckoutSection title={intl('Payment details')}>
        <em>{intl(`No payment required`)}</em>
      </CheckoutSection>
    );
  }

  return (
    <CheckoutSection title={intl('Payment details')}>
      <Availability price={props.price} coupon={props.coupon} />
      <FreeTrial freeDays={freeTrialPeriod} />
      {props.coupon && <Subtotal title={intl('Subtotal')} {...props} />}
      {props.price.priceInCents > 0 && <DiscountForm {...props} />}
      <Total {...props} freeTrialPeriod={freeTrialPeriod} />
    </CheckoutSection>
  );
}
