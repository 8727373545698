import { Button } from '@components/buttons';
import { IcoCheck } from '@components/icons';
import { QuizResultsModal } from '@components/quiz/quiz-results';
import { DefaultSpinner } from '@components/spinner';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { User } from 'server/types';
import { timeago } from 'shared/dateutil';
import { indexBy, groupBy } from 'shared/utils';
import { assessmentReviewUrl } from '../guide-course-assessments/urls';
import { ProgressBar } from '@components/progress-bar';

interface Props {
  courseId: UUID;
  student: Pick<User, 'id' | 'name'> & { progress: number };
}

async function loadProgressDetail(courseId: UUID, userId: UUID) {
  const result = await rpx.lessons.getStudentProgress({ courseId, userId });
  const completed = indexBy((x) => x.lessonId, result.completedLessons);
  const assignments = indexBy((x) => x.lessonId, result.completedAssignments);

  const lessons = groupBy(
    (a) => a.moduleId,
    result.lessons.map((a) => ({
      ...a,
      completedAt: completed[a.id]?.completedAt,
      quizScore: completed[a.id]?.quizScore,
      quizSubmittedAt: completed[a.id]?.quizSubmittedAt,
      assignmentSubmittedAt: assignments[a.id]?.submittedAt,
    })),
  );
  const modules = result.modules.map((l) => ({
    ...l,
    lessons: lessons[l.id] || [],
  }));
  return modules;
}

type Progress = Awaited<ReturnType<typeof loadProgressDetail>>;
type Lesson = Progress['0']['lessons']['0'];

function CompletionRow({ lesson, courseId, student }: Props & { lesson: Lesson }) {
  const [displayQuizResult, setDisplayQuizResult] = useState(false);

  let completedNode: ComponentChildren;

  if (lesson.quizSubmittedAt) {
    completedNode = (
      <>
        Completed quiz with{' '}
        <Button class="text-indigo-600 underline" onClick={() => setDisplayQuizResult(true)}>
          {lesson.quizScore}% score
        </Button>{' '}
        {timeago(lesson.quizSubmittedAt)}.
      </>
    );
  } else if (lesson.assignmentSubmittedAt) {
    completedNode = (
      <>
        Submitted{' '}
        <a
          class="text-indigo-600 underline"
          href={assessmentReviewUrl({
            courseId,
            type: 'assignment',
            lessonId: lesson.id,
            userId: student.id,
          })}
        >
          assignments
        </a>{' '}
        {timeago(lesson.assignmentSubmittedAt)}.
      </>
    );
  } else if (lesson.completedAt) {
    completedNode = `Completed ${timeago(lesson.completedAt)}`;
  }

  if (!completedNode) {
    return null;
  }

  return (
    <>
      <span class="mx-2 border-b border-dashed grow"></span>
      <span class="text-gray-500 ml-2 text-sm">{completedNode}</span>
      {displayQuizResult && (
        <QuizResultsModal
          courseId={courseId}
          lesson={lesson}
          user={student}
          hide={() => setDisplayQuizResult(false)}
        />
      )}
    </>
  );
}

export function ProgressTab({ courseId, student }: Props) {
  const loader = useTryAsyncData(() => loadProgressDetail(courseId, student.id), []);

  if (!loader.data) {
    return <DefaultSpinner />;
  }

  return (
    <section class="flex flex-col gap-6 py-4">
      <header class="flex flex-col gap-2">
        <span>
          Progress <b>{Math.round(student.progress)}%</b>
        </span>
        <ProgressBar showPercent={false} progress={student.progress} />
      </header>
      <div class="flex flex-col space-y-10">
        {loader.data.map((l) => (
          <div key={l.id} class="flex flex-col space-y-2">
            <h3 class="text-gray-700 font-semibold uppercase text-sm tracking-wider flex text-left w-full">
              {l.title}
            </h3>
            {l.lessons.map((a) => (
              <div key={a.id} class="flex items-center">
                <span
                  class={`rounded-full w-4 h-4 inline-flex justify-center items-center mr-2 border ${
                    a.completedAt ? 'bg-green-100 border-green-200' : 'bg-gray-100'
                  }`}
                >
                  {!!a.completedAt && <IcoCheck class="text-green-500 w-3 h-3" />}
                </span>
                {a.title}
                <CompletionRow courseId={courseId} student={student} lesson={a} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
}
