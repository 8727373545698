/**
 * This file contains the UI for managing a course's students, and is
 * integrated into the new payments system.
 */

import { urls } from './urls';
import { router, useRouteParams } from '@components/router';
import { ComponentChildren } from 'preact';
import { GuidePage, GuideProductPage } from '@components/guide-page';
import { IcoDownload } from '@components/icons';
import { BtnSecondary, Button } from '@components/buttons';
import { useMemo, useState } from 'preact/hooks';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { last } from 'shared/utils';
import { serialAsync } from 'client/utils/serial-async';
import { showError } from '@components/app-error';
import { FilterBox, FilterOptions } from '@components/table/filter-box';
import { Dropdown, MenuItem } from '@components/dropdown';
import { PageTitle } from '@components/headings';
import { StudentDetailSlideOver } from './student-detail-slideover';
import { store, PageType, FilterOpts, Props, load } from './state';
import { PaymentStats, PaymentsTable } from './payments-table';
import { ProgressStats, ProgressTable } from './progress-table';
import { Case } from '@components/conditional';
import { GiftsTable } from './gifts-table';

function PageLink({
  children,
  page,
  courseId,
  baseUrl,
}: {
  page: PageType;
  baseUrl: string;
  courseId: string;
  children: ComponentChildren;
}) {
  const { pageType } = useRouteParams();
  return (
    <Button
      class={`border-b-2 ${page === pageType ? 'border-indigo-600' : 'border-transparent'}`}
      href={`${baseUrl}${courseId}/${page}${location.search}`}
    >
      {children}
    </Button>
  );
}

function PageContent(props: Props) {
  const { userId, pageType } = props.route.params;
  const { state, setState } = props;
  const { course, opts, students, terminology } = state;
  const hasFullAccess = course.level === 'guide';
  const [loadingCount, setLoadingCount] = useState(0);
  const isLoading = loadingCount > 0;
  const { searchTerm } = state.opts;
  const showProgress = !course.isBundle && !course.isProduct;
  const baseUrl = course.isProduct ? urls.productsBaseUrl : urls.coursesBaseUrl;

  const resetOpts = (fn: (o: FilterOpts) => FilterOpts) => {
    setState((x) => ({
      ...x,
      opts: { ...fn(x.opts), startingAfter: undefined },
    }));
  };

  const loadNextPage = useMemo(() => {
    return serialAsync(async (opts: FilterOpts) => {
      try {
        setLoadingCount((x) => x + 1);
        const batch = await store.getNextStudents(opts);
        if (!opts.startingAfter) {
          setState((x) => ({ ...x, students: batch }));
        } else {
          setState((x) => ({
            ...x,
            students: {
              data: [...x.students.data, ...batch.data],
              hasMore: batch.hasMore,
            },
          }));
        }
      } catch (err) {
        showError(err);
      } finally {
        setLoadingCount((x) => Math.max(0, x - 1));
      }
    });
  }, []);

  useDidUpdateEffect(() => loadNextPage(state.opts), [state.opts]);

  return (
    <section class="flex flex-col gap-2 w-full max-w-7xl mx-auto">
      {userId && (
        <StudentDetailSlideOver
          key={userId}
          course={course}
          userId={userId}
          showProgress={showProgress}
          baseUrl={baseUrl}
          onClose={() => {
            router.goto(
              urls.showStudents({
                ...props.route.params,
                courseId: course.id,
                userId: '',
                baseUrl,
              }),
            );
          }}
          onChange={(student) => {
            setState((s) => ({
              ...s,
              students: {
                ...students,
                data: students.data.map((x) => (x.id === student.id ? { ...x, ...student } : x)),
              },
            }));
          }}
        />
      )}
      <div class="flex flex-col gap-8 flex-grow p-8 w-full max-w-7xl mx-auto">
        <header class="flex flex-col gap-4 relative overflow-hidden">
          <div class="flex gap-6 items-center">
            <PageTitle>{terminology}</PageTitle>
            <nav class="order-last flex w-full gap-8 text-sm font-semibold border-l pl-6">
              {hasFullAccess && (
                <PageLink
                  page={course.isProduct ? 'customers' : 'students'}
                  courseId={course.id}
                  baseUrl={baseUrl}
                >
                  Payments
                </PageLink>
              )}
              {showProgress && (
                <PageLink page="progress" courseId={course.id} baseUrl={baseUrl}>
                  Progress
                </PageLink>
              )}
              {hasFullAccess && (
                <PageLink page="gifts" courseId={course.id} baseUrl={baseUrl}>
                  Gifts
                </PageLink>
              )}
            </nav>
          </div>
          <div class="flex flex-col md:grid grid-cols-5 flex-wrap border-b border-t">
            {pageType === 'students' && <PaymentStats {...props.data} />}
            {pageType === 'progress' && <ProgressStats {...props.data} />}
          </div>
        </header>

        <Case when={pageType !== 'gifts'}>
          <div class="flex flex-col md:flex-row md:justify-between items-center">
            <FilterBox
              searchPlaceholder={`Search ${terminology}`}
              compact={false}
              hasFilters={!!searchTerm || opts.access !== 'all'}
              searchTerm={searchTerm}
              setSearchTerm={(searchTerm) => resetOpts((s) => ({ ...s, searchTerm }))}
              clearFilters={() =>
                setState((s) => ({ ...s, opts: { ...s.opts, searchTerm: '', access: 'all' } }))
              }
            >
              <FilterOptions
                currentValue={opts.access}
                options={[
                  { title: 'All', value: 'all' },
                  { title: 'Granted', value: 'granted' },
                  { title: 'Expired', value: 'expired' },
                  { title: 'Revoked', value: 'revoked' },
                ]}
                title="Access"
                setFilter={(access: typeof opts.access) => resetOpts((x) => ({ ...x, access }))}
              />
            </FilterBox>

            <Dropdown
              class="mt-4 md:mt-0 hidden md:flex"
              hideDownIcon
              triggerClass="text-gray-600"
              renderMenu={() => (
                <div class="flex flex-col p-2 pb-0">
                  <MenuItem
                    download="memberships.csv"
                    href={`/csv/courses/${course.id}/memberships.csv`}
                  >
                    Overview
                  </MenuItem>
                  {hasFullAccess && (
                    <MenuItem
                      download="payments.csv"
                      href={`/csv/courses/${course.id}/payments.csv`}
                    >
                      Payments
                    </MenuItem>
                  )}
                  {showProgress && (
                    <MenuItem
                      download="progress.csv"
                      href={`/csv/courses/${course.id}/progress.csv`}
                    >
                      Progress
                    </MenuItem>
                  )}
                </div>
              )}
            >
              <IcoDownload class="w-4 h-4 mr-1" />
              Download CSV
            </Dropdown>
          </div>
        </Case>

        {(pageType === 'students' || pageType === 'customers') && hasFullAccess && (
          <PaymentsTable {...props} />
        )}
        {pageType === 'progress' && <ProgressTable {...props} />}
        {pageType === 'gifts' && hasFullAccess && <GiftsTable course={course} />}

        {students.hasMore && pageType !== 'gifts' && (
          <footer>
            <BtnSecondary
              isLoading={isLoading}
              onClick={() => {
                setState((x) => ({
                  ...x,
                  opts: { ...x.opts, startingAfter: last(students.data)?.id },
                }));
              }}
            >
              Load more
            </BtnSecondary>
          </footer>
        )}
      </div>
    </section>
  );
}

function Page(props: Props) {
  if (props.route.url.startsWith(urls.productsBaseUrl)) {
    return (
      <GuideProductPage page="customers" product={props.state.course}>
        <PageContent {...props} />
      </GuideProductPage>
    );
  }
  return (
    <GuidePage type="students" course={props.state.course}>
      <PageContent {...props} />
    </GuidePage>
  );
}

[urls.customers, urls.customer, urls.students, urls.student].forEach((url) => {
  router.add({
    url,
    load,
    render: Page,
    authLevel: 'guide',
  });
});
