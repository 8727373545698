import { showError } from '@components/app-error';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { LoadingIndicator } from '@components/loading-indicator';
import { Modal, ModalTitle, ModalMessage, ModalFooter } from '@components/modal';
import { useAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { BuyCreditsBanner } from 'client/pages/guide-instant-courses/buy-credits-banner';
import { useState } from 'preact/hooks';
import { Course } from 'server/types';

interface Props {
  course: Pick<Course, 'id' | 'title'>;
  onCancel: () => void;
}

const store = rpx.instantCourses;

export function PurchaseInstantCourseModal({ course, onCancel }: Props) {
  const [isPurchasing, setIsPurchasing] = useState(false);

  const { data } = useAsyncData(() => store.getInstantCourseCredits(), []);

  async function purchase() {
    setIsPurchasing(true);
    try {
      await store.purchaseInstantCourse({
        courseId: course.id,
      });
      await store.registerToInstantCourse({
        courseId: course.id,
      });
      window.location.reload();
    } catch (err) {
      showError(err);
      // No need to do this in `finally`
      // as we are reloading the page if this block succeeds
      setIsPurchasing(false);
    }
  }

  if (!data) {
    return <LoadingIndicator />;
  }

  const remaining = data.credits - data.usage;

  if (!data.isUnlimited && remaining <= 0) {
    return <BuyCreditsBanner credits={data.credits} usage={data.usage} expanded />;
  }

  return (
    <Modal isOpen onCancel={onCancel}>
      <ModalTitle centered={true}>Purchase Instant Course</ModalTitle>
      <ModalMessage class="space-y-8">
        Do you want to purchase "{course.title}" instant course? You have {remaining} credits
        remaining.
      </ModalMessage>

      <ModalFooter>
        <BtnSecondary class="mr-3" type="button" onClick={onCancel}>
          Cancel
        </BtnSecondary>
        <BtnPrimary isLoading={isPurchasing} onClick={purchase}>
          Purchase Instant Course
        </BtnPrimary>
      </ModalFooter>
    </Modal>
  );
}
