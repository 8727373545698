import { useContext } from 'preact/hooks';
import { ModalFormContext, showModalForm } from '@components/modal-form';
import { CouponRow } from 'server/types';
import { Modal } from '@components/modal';
import { AsyncForm } from '@components/async-form';
import { rpx } from 'client/lib/rpx-client';
import { HeadingPrimary } from '@components/headings';
import { IcoTag } from '@components/icons';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { ExpandableLimitsFields, FreeTrialPeriod } from './new-coupon-form';
import { formDataToPriceLimits } from './signup-limits-tab';

const store = rpx.coupons;

export function showUpdateCouponModal({
  coupon,
  stripeMetadata,
  onSave,
}: {
  coupon: CouponRow;
  stripeMetadata?: Record<string, string>;
  onSave(price: CouponRow): void;
}) {
  return showModalForm(() => {
    const { resolve } = useContext(ModalFormContext);
    const hide = () => resolve(coupon);

    return (
      <Modal isOpen onCancel={hide}>
        <AsyncForm
          class="flex flex-col"
          onSubmit={async (formData) => {
            const freeTrialPeriod = parseInt(formData.freeTrialPeriod) || 0;
            const updatedCoupon = await store.updateCoupon({
              ...formData,
              ...formDataToPriceLimits(formData),
              freeTrialPeriod,
              couponId: coupon.id,
              productId: coupon.productId,
              stripeMetadata: stripeMetadata,
            });
            onSave(updatedCoupon);
            resolve(updatedCoupon);
          }}
        >
          <HeadingPrimary
            title={
              <span class="flex items-center">
                <IcoTag class="w-12 h-12 bg-green-400 rounded-full text-white p-2 mr-4" />
                Edit Coupon {coupon.code}
              </span>
            }
          />
          <div class="space-y-10 mb-10">
            <FreeTrialPeriod value={coupon.freeTrialPeriod} />

            <ExpandableLimitsFields coupon={coupon} />
          </div>

          <footer class="flex sticky bottom-0 bg-white pt-6 flex-row-reverse justify-items-end items-center border-t">
            <BtnPrimary>Save Coupon</BtnPrimary>
            <BtnSecondary class="mr-2" type="button" onClick={hide}>
              Cancel
            </BtnSecondary>
          </footer>
        </AsyncForm>
      </Modal>
    );
  });
}
