import { AsyncForm, FormGroup, Subtext } from '@components/async-form';
import { AutosizeText } from '@components/autosize-text';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { IcoMail } from '@components/icons';
import { Modal, ModalTitle, ModalMessage } from '@components/modal';
import { useState } from 'preact/hooks';
import { BULK_INSERT_LIMIT } from 'shared/consts';
import { pluralize } from 'shared/formatting';
import { extractEmails } from 'shared/parsing';
import { URLS } from 'shared/urls';
import { BaseInvitation, Invitation, normalizeInvitation } from './data';
import { InvitationsGraphic } from './empty-screen';
import { showProcessingModal } from '@components/processing-modal';
import quikpik from 'quikpik';
import { AccessLimitsFormFields } from '../pmts/components/access-limits-tab';

interface Props {
  courseId: UUID;
  send: (data: { emails: string[]; accessDuration?: number }) => Promise<BaseInvitation[]>;
  hideModifyText?: boolean;
  onCancel(): void;
  onSent(invitations: Invitation[]): void;
}

function csvPicker(onPick: (content: string) => void) {
  quikpik({
    sources: ['filepicker'],
    accept: 'text/csv',
    customProgress: true,
    upload({ files }) {
      const file = files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => onPick(reader.result as string), false);
      reader.readAsText(file);
    },
  });
}

export function InvitationModal({ courseId, send, hideModifyText, onSent, onCancel }: Props) {
  const [text, setText] = useState('');
  const numEmails = text.split('@').length - 1;

  return (
    <Modal isOpen dontAutoFocus onCancel={onCancel}>
      <AsyncForm
        onSubmit={async (formData: { accessDuration?: string }) => {
          const emails = extractEmails(text);
          const result = await showProcessingModal({
            promise: send({
              emails,
              accessDuration: formData.accessDuration
                ? parseInt(formData.accessDuration, 10)
                : undefined,
            }),
            title: 'Sending invitations...',
            children: (
              <div class="scale-50">
                <InvitationsGraphic />
              </div>
            ),
          });
          if (result) {
            onSent(result.map(normalizeInvitation));
          }
        }}
      >
        <IcoMail
          stroke-width="1"
          class="w-24 h-24 m-auto mt-4 mb-4 rounded-full text-white bg-indigo-600 p-3 bg-gradient-to-br from-indigo-500 to-indigo-600"
        />
        <ModalTitle centered={true}>Invite new students</ModalTitle>
        <Subtext>
          Invite up to {BULK_INSERT_LIMIT} students one per line. You can also{' '}
          <Button class="text-indigo-600" onClick={() => csvPicker(setText)}>
            load a CSV file
          </Button>
          .
        </Subtext>
        <ModalMessage class="space-y-8">
          <div class="pb-8 border-b" data-private>
            <FormGroup prop="emails" class="w-full">
              <AutosizeText
                focusSelf
                name="emails"
                class="ruz-input p-2 min-h-32"
                value={text}
                onInput={(e: any) => setText(e.target.value)}
                placeholder="Emails, separated by space, comma, or newlines"
              />
            </FormGroup>
            {!hideModifyText && (
              <p class="mt-2">
                You may modify the invitation email text on the{' '}
                <a
                  href={URLS.guide.coursePage({
                    courseId,
                    page: 'messages/invitation',
                  })}
                >
                  messages page
                </a>
                .
              </p>
            )}
          </div>
          <AccessLimitsFormFields accessDuration={-1} />
        </ModalMessage>
        <footer class="mt-8 space-x-3">
          <div class="mt-4 flex justify-end space-x-3">
            <BtnSecondary type="button" onClick={onCancel}>
              Cancel
            </BtnSecondary>
            <BtnPrimary disabled={!numEmails}>
              Send {numEmails || ''} {pluralize('invitation', numEmails)}
            </BtnPrimary>
          </div>
        </footer>
      </AsyncForm>
    </Modal>
  );
}
