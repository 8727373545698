import { Modal, ModalFooter, ModalMessage, ModalTitle } from '@components/modal';
import { BtnSecondary, Button } from '@components/buttons';
import { useState } from 'preact/hooks';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { SearchBox } from '@components/search-box';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useIntl } from 'shared/intl/use-intl';

interface Props {
  timezone: string;
  hide(): void;
  onPick(timezone: string): void;
}

const store = rpx.auth;

type SearchableTimezone = RpxResponse<typeof store.getTimezones>[0] & { search: string };

export function TimezoneModal(props: Props) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [timezones, setTimezones] = useState<SearchableTimezone[]>([]);
  const [filter, setFilter] = useState({
    term: '',
    search: '',
  });
  const filteredTimezones = !filter.search
    ? timezones
    : timezones.filter((t) => t.search.includes(filter.search));

  useAsyncEffect(async () => {
    try {
      const zones = await store.getTimezones();
      setTimezones(
        zones.map((z) => ({
          ...z,
          search: `${z.name.toLowerCase().replace(/[_/\s]/g, '')} ${z.abbrev.toLowerCase()}`,
        })),
      );
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const pickTimezone = (timezone: string) => {
    props.onPick(timezone);
    props.hide();
  };

  return (
    <Modal isOpen={true} onCancel={props.hide}>
      <ModalTitle centered={true}>
        {isLoading ? intl('Loading timezones...') : intl('Choose your timezone')}
      </ModalTitle>
      {!isLoading && (
        <>
          <ModalMessage>
            <SearchBox
              focusOnce={true}
              placeholder="Find a timezone"
              onTermChange={(t) =>
                setFilter({
                  term: t,
                  search: t.toLowerCase().replace(/[\s]/g, ''),
                })
              }
              onKeyDown={(e) => {
                if (e.code === 'Enter' && filteredTimezones.length) {
                  e.preventDefault();
                  pickTimezone(filteredTimezones[0].name);
                }
              }}
            />
          </ModalMessage>
          <ModalMessage>
            <div class="overflow-y-auto max-h-64 mini-scroll my-8">
              {filteredTimezones.map((t) => (
                <Button
                  key={t.name}
                  type="button"
                  class="flex justify-between w-full p-2 hover:bg-indigo-50 hover:text-indigo-800"
                  onClick={() => pickTimezone(t.name)}
                >
                  <span>{t.name}</span>
                  <span>
                    {t.abbrev} {t.utcOffset}
                  </span>
                </Button>
              ))}
            </div>
          </ModalMessage>
        </>
      )}
      <ModalFooter>
        <BtnSecondary class="mr-3" type="button" onClick={props.hide}>
          {intl('Cancel')}
        </BtnSecondary>
      </ModalFooter>
    </Modal>
  );
}
