import { router } from '@components/router';
import { AsyncForm, FormGroupTwoCol, FormSection, FormSubSection } from '@components/async-form';
import { useMemo, useState } from 'preact/hooks';
import { useConfiguration } from '@components/router/session-context';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { showError } from '@components/app-error';
import { rpx } from 'client/lib/rpx-client';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { useUnsavedWarning } from '@components/autosaver';
import { AdminNav, AdminTabHeader, AdminTabWrapper } from '@components/admin-nav';

function Page() {
  const config = useConfiguration();
  const tenant = config.tenant;
  const [state, setState] = useState(config.tenant);
  const initialState = useMemo(() => state, []);
  const hasUnsavedChanges = initialState !== state;
  const warnOnReload = {
    shouldWarn: hasUnsavedChanges,
  };

  useUnsavedWarning(() => warnOnReload.shouldWarn);

  return (
    <FixedPage>
      <FixedContent class="bg-white">
        <AdminNav currentPage="settings" pageTitle="Settings" />
        <AdminTabWrapper>
          <AdminTabHeader title="Settings" />
          <AsyncForm
            class="pb-20 mb-20 w-4xl max-w-full"
            onSubmit={async () => {
              try {
                await rpx.tenants.updateTenant({
                  tenantId: tenant.id,
                  name: state.name,
                  locale: state.locale,
                  googleMeasurementId: state.googleMeasurementId,
                  metaPixelId: state.metaPixelId,
                  // Send undefined instead of an empty string
                  supportUrl: state.supportUrl || undefined,
                });
                warnOnReload.shouldWarn = false;
                location.reload();
              } catch (err) {
                if (err.type === 'validation') {
                  throw err;
                }
                showError(err);
              }
            }}
          >
            <FormSection>
              <FormSubSection class="text-gray-500">
                <FormGroupTwoCol prop="name" labelText="Site Name">
                  <input
                    type="text"
                    name="name"
                    placeholder="Site name"
                    value={state.name}
                    onInput={(e: any) => setState((s) => ({ ...s, name: e.target.value }))}
                    class="ruz-input"
                  />
                </FormGroupTwoCol>
                <FormGroupTwoCol prop="name" labelText="Site Locale">
                  <select
                    class="ruz-input"
                    name="locale"
                    value={state.locale}
                    onInput={(e: any) => setState((s) => ({ ...s, locale: e.target.value }))}
                  >
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                  </select>
                </FormGroupTwoCol>
                <FormGroupTwoCol
                  prop="supportUrl"
                  labelText="Support link"
                  subText="The URL that will be used for the 'Support' link in the user menu of your site. Leave it empty if you'd like to use the default support page."
                >
                  <input
                    type="text"
                    name="supportUrl"
                    placeholder="https://support.ruzuku.com"
                    value={state.supportUrl}
                    onInput={(e: any) => setState((s) => ({ ...s, supportUrl: e.target.value }))}
                    class="ruz-input"
                  />
                </FormGroupTwoCol>
                <FormGroupTwoCol
                  prop="googleMeasurementId"
                  labelText="Google Measurement ID"
                  subText="If you want to use Google Analytics, enter your Google Measurement ID here."
                >
                  <input
                    type="text"
                    name="googleMeasurementId"
                    placeholder="e.g. G-A01F00X23"
                    value={state.googleMeasurementId}
                    onInput={(e: any) =>
                      setState((s) => ({ ...s, googleMeasurementId: e.target.value }))
                    }
                    class="ruz-input"
                  />
                </FormGroupTwoCol>

                <FormGroupTwoCol
                  prop="metaPixelId"
                  labelText="Meta Pixel ID"
                  subText="If you want to use Meta / Facebook Pixel for analytics, enter your Meta Pixel ID here."
                >
                  <input
                    type="text"
                    name="metaPixelId"
                    placeholder="Meta / Facebook pixel ID"
                    value={state.metaPixelId}
                    onInput={(e: any) => setState((s) => ({ ...s, metaPixelId: e.target.value }))}
                    class="ruz-input"
                  />
                </FormGroupTwoCol>
              </FormSubSection>

              {hasUnsavedChanges && (
                <footer class="max-w-5xl mx-auto pt-6 space-x-4 an-slide-up">
                  <BtnPrimary>Save changes</BtnPrimary>
                  <BtnSecondary
                    onClick={() => {
                      warnOnReload.shouldWarn = false;
                      location.reload();
                    }}
                  >
                    Cancel
                  </BtnSecondary>
                </footer>
              )}
            </FormSection>
          </AsyncForm>
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}

router.add({
  authLevel: 'admin',
  url: '/admin/settings',
  render: Page,
});
