import register from 'preact-custom-element';
import { VidstackPlayer, Props as VidstackProps } from '@components/vidstack-player';

export type Props = VidstackProps;
export const tagName = 'vidstack-card';

register(
  VidstackPlayer,
  tagName,
  ['url', 'poster', 'captions', 'downloadUrl', 'type', 'load', 'overlay'],
  {
    shadow: false,
  },
);
